import { Directive, HostBinding, HostListener, Input } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';

import { AppConfigurationService } from '@pm/core/configuration';
import { OperationsService, StoreOperationsWarningDialog } from '@pm/core/operations';

/**
 * Directive used for navigation hyperlinks outside of the Angular app. 
 */
@Directive({
  selector: 'a[pmNavLink]',
  standalone: true
})
export class NavLinkDirective {
  /** Link for navigation. */
  @Input({ required: true }) set pmNavLink(value: string) {
    this.linkPath = this.config.getLinkPath(value);
  }

  /** Set the href attribute. */
  @HostBinding('attr.href') linkPath = '';

  /**
   * Initializes instance of the NavLinkDirective class.
   * @param config Application configuration service
   * @param dialog Material dialog service
   * @param operations Operations service
   */
  constructor(
    private config: AppConfigurationService,
    private dialog: MatDialog,
    private operations: OperationsService
  ) { }

  /**
   * Add click handler which navigates to the page.  If appropriate, show the
   * store operations alert.
   */
  @HostListener('click', ['$event'])
  async onClick(event: Event): Promise<void> {
    event.preventDefault();
    if (this.config.isNavigationEnabled) {
      if (this.operations.isStoreOperations) {
        const alert = await this.operations.loadPreviousUnclosedBusinessDaysInfo();
        if (alert.message && (this.operations.isDailyClose && alert.operationType === 'closing' && alert.type === 'Error') || (this.operations.isDailyOpen && alert.operationType === 'opening')) {
          const dialog = this.dialog.open(StoreOperationsWarningDialog, { panelClass: 'pm-dialog-md' });
          dialog.afterClosed().subscribe(x => x === 'skip' && (location.href = this.linkPath));
        } else {
          location.href = this.linkPath;
        }
      } else {
        location.href = this.linkPath;
      }
    }
  }
}
