import { Directive, HostBinding, HostListener, Input } from '@angular/core';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';

import { MatDialog } from '@angular/material/dialog';

import { AppConfigurationService } from '@pm/core/configuration';
import { OperationsService, StoreOperationsWarningDialog } from '@pm/core/operations';

/**
 * Directive that automatically switchings between angular routes and normal hyperlinks. 
 */
@Directive({
  selector: 'a[pmRoute]',
  standalone: true
})
export class RouteDirective {
  /** Angular route. */
  private route = '';

  /** Set Angular route. */
  @Input({ required: true }) set pmRoute(value: string) {
    this.route = value;
    this.linkPath = `${this.config.clientBasePath}${value}`;
  }

  /** Set the href attribute. */
  @HostBinding('attr.href') linkPath = '';

  /**
   * Initializes instance of the RouteDirective class.
   * @param config Application configuration service
   * @param dialog Material dialog service
   * @param operations Operations service
   * @param router Router service
   */
  constructor(
    private config: AppConfigurationService,
    private dialog: MatDialog,
    private operations: OperationsService,
    private router: Router
  ) { }

  /**
   * Add click handler which navigates to the page.  If appropriate, show the
   * store operations alert.
   */
  @HostListener('click', ['$event'])
  onClick(event: Event): void {
    event.preventDefault();
    if (this.operations.isStoreOperations) {
      firstValueFrom(this.operations.storeOperationsAlert$).then(alert => {
        if (alert.message) {
          const dialog = this.dialog.open(StoreOperationsWarningDialog, { panelClass: 'pm-dialog-md' });
          dialog.afterClosed().subscribe(x => x === 'skip' && this.navigateTo());
        } else {
          this.navigateTo();
        }
      });
    } else {
      this.navigateTo();
    }
  }

  /**
   * Navigate to page using angular routing if available.  Otherwise, just
   * naviagte to the page.
   * @param route Angular route 
   * @param linkPath Link path
   */
  private navigateTo(): void {
    if (this.config.isRoutingAvailable) {
      this.router.navigate([this.route]);
    } else {
      location.href = this.linkPath;
    }
  }
}