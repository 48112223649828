import { Pipe, PipeTransform } from '@angular/core';
import { AppConfigurationService } from '@pm/core/configuration';

@Pipe({
  name: 'eligibility',
  standalone: true
})
export class EligibilityPipe implements PipeTransform {

  /**
   * Initializes an instance of the AssetPipe class.
   * @param config Application configuration service
   */
  constructor(private config: AppConfigurationService) {}

  /**
   * 
   * @param value 
   * @returns 
   */
  transform(value: boolean): string {
    return value ? this.config.getAssetPath('images/active-eligibility.svg') : this.config.getAssetPath('images/eligibility-nonexistent.svg');
  }

}
