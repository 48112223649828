import { IAppContent } from '../interfaces/app-content';

export function AppContentMockData(): IAppContent {
    return {
        AppNameLong: 'Eyefinity Practice Management',
        AppNameShort: 'EPM',
        AppLogoSvgForDarkBackground: 'https://pm.eyefinity.com/images/eyefinity-logo-for-dark-bg.svg',
        AppLogoSvgForLightBackground: 'https://pm.eyefinity.com/images/eyefinity-logo-for-light-bg.svg',
        AppLogoWithPremierSvgForDarkBackground: 'https://pm.eyefinity.com/images/eyefinity-premier-logo-for-dark-bg.svg',
        AppLogoWithPremierSvgForLightBackground: 'https://pm.eyefinity.com/images/eyefinity-premier-logo-for-light-bg.svg',
        PremierLogoSvgForDarkBackground: 'https://pm.eyefinity.com/images/premier-logo-for-dark-bg.svg',
        PremierLogoSvgForLightBackground: 'https://pm.eyefinity.com/images/premier-logo-for-light-bg.svg'
    };
}