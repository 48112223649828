import { IAppConfig } from '../interfaces/app-config';

export function AppConfigIncompleteMockData(): IAppConfig {
    return {
        baseUrl: '/',
        userId: '0',
        userName: '',
        officeNumber: '',
        companyId: '',
        officeId: '',
        timeOut: '',
        dayCloseRole: '',
        isEhrEnabled: '',
        trainingMode: '',
        trainingPendingLive: '',
        trainingCompanyId: '',
        frameDataAvailability: '',
        isPremier: '0'
    };
}