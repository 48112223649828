import { IPatientRecallDetail } from '../interfaces/patient-recall-detail'; 

/**
 * PatientRecallDetail class
 */
export class PatientRecallDetail {
    /** Recall detail ID */
    id: number;
    /** Type of recall */
    recallType: string;
    /** Months until next recall */
    monthsToRecall: number;
    /** Date of next recall */
    nextRecall: string;
    /** Recall type ID */
    recallTypeId: number;
    /** Patient ID */
    patientId: number;
    /** Active status */
    isActive: boolean;
    /** Office number */
    officeNumber: string;

    constructor(data: IPatientRecallDetail) {
        this.id = data.Id;
        this.recallType = data.RecallType;
        this.monthsToRecall = data.MonthsToRecall;
        this.nextRecall = data.NextRecall;
        this.recallTypeId = data.RecallTypeId;
        this.patientId = data.PatientId;
        this.isActive = data.IsActive;
        this.officeNumber = data.OfficeNumber;
    }
}