import { IEnvironmentConfig } from '@pm/core/index';

/**
 * Environment configuration for development.
 */
export const environment: IEnvironmentConfig = {
  development: true,
  links: {
    additionalIntegrations: 'Admin/Office/AdditionalIntegrations',
    dailyClosing: 'Common/DailyClosing',
    dailyOpening: 'Common/DailyOpening',
    tillDailyClosing: 'Common/TillDailyClosing',
  },
  pricing: {
    defaultGlobalDiscountByPercent: true,
    defaultLineDiscountByPercent: true,
    maxAccessoryPrice: 999.99,
    maxGiftCardAmount: 10000.00,
    maxFeeAmount: 999.99,
    maxFramePrice: 9999.99,
    maxQty: 999
  },
  production: false,
  staging: false
};
