import { CatalogItem } from '../classes/catalog-item';
import { IAccessory } from '../interfaces/accessory';

/**
 * Accessory class.
 */
export class Accessory extends CatalogItem {
    /**
     * Initializes instance of the Accessory class.
     * @param accessory Accessory data.
     */
    constructor(accessory: IAccessory) {
        super(accessory);
    }
}
