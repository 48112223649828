import { ISearchResults } from '@pm/core';
import { IFailedFrameOrder } from '../interfaces/failed-frame-order';

export function FailedFrameOrderResultsMockData(): ISearchResults<IFailedFrameOrder> {
    return {
        'Items': [
            {
                'OrderInternalId': 8029708,
                'SubmittedDate': '2023-08-01T16:06:57.21',
                'Frame': {
                    'ItemInternalId': 2058442,
                    'Name': 'M-CORNELIA BLUE TORTOISE 52/16/135',
                    'Sku': '307655216215',
                    'Upc': '886895263993'
                },
                'Lab': {
                    'LabInternalId': '501',
                    'Name': 'VSPONE Sacramento (Non VSP)'
                },
                'Patient': {
                    'PatientInternalId': 18778878,
                    'FirstName': 'Mira',
                    'LastName': 'Saxena',
                    'MiddleInitial': '',
                    'NickName': ''
                }
            },
            {
                'OrderInternalId': 8029707,
                'SubmittedDate': '2023-08-02T19:40:03.92',
                'Frame': {
                    'ItemInternalId': 2805298,
                    'Name': 'LO2621 MARBLE BROWN AZURE 53/14/140',
                    'Sku': '377365314251',
                    'Upc': '886895360500'
                },
                'Lab': null,
                'Patient': {
                    'PatientInternalId': 18778878,
                    'FirstName': 'Mira',
                    'LastName': 'Saxena',
                    'MiddleInitial': '',
                    'NickName': ''
                }
            },
            {
                'OrderInternalId': 8029706,
                'SubmittedDate': '2023-08-01T16:06:59.75',
                'Frame': {
                    'ItemInternalId': 2058442,
                    'Name': 'M-CORNELIA BLUE TORTOISE 52/16/135',
                    'Sku': '307655216215',
                    'Upc': '886895263993'
                },
                'Lab': null,
                'Patient': {
                    'PatientInternalId': 18778878,
                    'FirstName': 'Mira',
                    'LastName': 'Saxena',
                    'MiddleInitial': '',
                    'NickName': ''
                }
            },
            {
                'OrderInternalId': 8029705,
                'SubmittedDate': '2023-08-02T19:40:08.247',
                'Frame': {
                    'ItemInternalId': 2058442,
                    'Name': 'M-CORNELIA BLUE TORTOISE 52/16/135',
                    'Sku': '307655216215',
                    'Upc': '886895263993'
                },
                'Lab': null,
                'Patient': {
                    'PatientInternalId': 18778878,
                    'FirstName': 'Mira',
                    'LastName': 'Saxena',
                    'MiddleInitial': '',
                    'NickName': ''
                }
            },
            {
                'OrderInternalId': 8029702,
                'SubmittedDate': '2023-07-21T15:41:19.087',
                'Frame': {
                    'ItemInternalId': 2058442,
                    'Name': 'M-CORNELIA BLUE TORTOISE 52/16/135',
                    'Sku': '307655216215',
                    'Upc': '886895263993'
                },
                'Lab': {
                    'LabInternalId': '40',
                    'Name': 'VSPONE Sacramento (VSP)'
                },
                'Patient': {
                    'PatientInternalId': 18778878,
                    'FirstName': 'Mira',
                    'LastName': 'Saxena',
                    'MiddleInitial': '',
                    'NickName': ''
                }
            },
            {
                'OrderInternalId': 8029701,
                'SubmittedDate': '2023-08-02T11:38:48.203',
                'Frame': {
                    'ItemInternalId': 2058442,
                    'Name': 'M-CORNELIA BLUE TORTOISE 52/16/135',
                    'Sku': '307655216215',
                    'Upc': '886895263993'
                },
                'Lab': {
                    'LabInternalId': '40',
                    'Name': 'VSPONE Sacramento (VSP)'
                },
                'Patient': {
                    'PatientInternalId': 18778878,
                    'FirstName': 'Mira',
                    'LastName': 'Saxena',
                    'MiddleInitial': '',
                    'NickName': ''
                }
            },
            {
                'OrderInternalId': 8029695,
                'SubmittedDate': '2023-08-02T19:40:10.133',
                'Frame': {
                    'ItemInternalId': 2805298,
                    'Name': 'LO2621 MARBLE BROWN AZURE 53/14/140',
                    'Sku': '377365314251',
                    'Upc': '886895360500'
                },
                'Lab': null,
                'Patient': {
                    'PatientInternalId': 18778878,
                    'FirstName': 'Mira',
                    'LastName': 'Saxena',
                    'MiddleInitial': '',
                    'NickName': ''
                }
            },
            {
                'OrderInternalId': 8029694,
                'SubmittedDate': '2023-08-02T19:40:11.647',
                'Frame': {
                    'ItemInternalId': 2058442,
                    'Name': 'M-CORNELIA BLUE TORTOISE 52/16/135',
                    'Sku': '307655216215',
                    'Upc': '886895263993'
                },
                'Lab': null,
                'Patient': {
                    'PatientInternalId': 18778878,
                    'FirstName': 'Mira',
                    'LastName': 'Saxena',
                    'MiddleInitial': '',
                    'NickName': ''
                }
            },
            {
                'OrderInternalId': 8029568,
                'SubmittedDate': '2023-08-02T19:40:12.833',
                'Frame': {
                    'ItemInternalId': 2058442,
                    'Name': 'M-CORNELIA BLUE TORTOISE 52/16/135',
                    'Sku': '307655216215',
                    'Upc': '886895263993'
                },
                'Lab': {
                    'LabInternalId': '501',
                    'Name': 'VSPONE Sacramento (Non VSP)'
                },
                'Patient': {
                    'PatientInternalId': 18778815,
                    'FirstName': 'POS',
                    'LastName': 'VSP',
                    'MiddleInitial': null,
                    'NickName': ''
                }
            },
            {
                'OrderInternalId': 8029315,
                'SubmittedDate': '2023-08-02T19:40:13.403',
                'Frame': {
                    'ItemInternalId': 1583979,
                    'Name': 'NIKE 7090 MATTE BLACK/MATTE CRYSTAL V 53/17/140',
                    'Sku': '277185317010',
                    'Upc': '886895207560'
                },
                'Lab': {
                    'LabInternalId': '501',
                    'Name': 'VSPONE Sacramento (Non VSP)'
                },
                'Patient': {
                    'PatientInternalId': 18776556,
                    'FirstName': 'Levi',
                    'LastName': 'Ackerman',
                    'MiddleInitial': null,
                    'NickName': ''
                }
            }
        ],
        'TotalAvailable': 10
    };
}