import { Injector } from '@angular/core';
import { createCustomElement } from '@angular/elements';

import { FooterComponent } from '@pm/layout/footer';
import { HeaderComponent } from '@pm/layout/header';
import { NotificationsComponent } from './home/components/notifications/notifications.component';
import { EligibilityReportLauncherComponent } from '@insurance/eligibility/eligibility-report';
import { AlertComponent } from '@pm/core/alerts';
import { PatientInfoComponent } from '@ref-data/patients/patient-info';
/**
 * Create custom elemenmts for use outside of the Angular app.
 * @param injector Injector for custom elements
 */
export function CreateElements(injector: Injector): void {
    const footer = createCustomElement(FooterComponent, { injector });
    customElements.define('ef-footer', footer);

    const header = createCustomElement(HeaderComponent, { injector });
    customElements.define('ef-header', header);

    const notifications = createCustomElement(NotificationsComponent, { injector });
    customElements.define('ef-notifications', notifications);

    const eligibilityReportLauncher = createCustomElement(EligibilityReportLauncherComponent, { injector });
    customElements.define('ef-eligibility-report-launcher', eligibilityReportLauncher);

    const alert = createCustomElement(AlertComponent, { injector });
    customElements.define('ef-alert', alert);

    const patientInfo = createCustomElement(PatientInfoComponent, { injector });
    customElements.define('ef-patient-info', patientInfo);
}