<!-- dialog title -->
<h1 mat-dialog-title>
    <span>{{ title | translate}}</span>
    <button data-ef-button="fee-close" mat-icon-button [attr.aria-label]="'BUTTONS.CLOSE'" mat-dialog-close="close">
        <mat-icon>close</mat-icon>
    </button>
</h1>

<!-- dialog content -->
<div mat-dialog-content [innerHTML]="message"></div>

<!-- dialog actions -->
<div mat-dialog-actions class="justify-content-end">

    <!-- close -->
    <button data-ef-button="alert-close-button" mat-flat-button color="primary"
        mat-dialog-close="close">{{ buttonText |translate}}</button>

</div>