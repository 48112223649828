import { ICatalogItem } from '../interfaces/catalog-item';

export function GiftCardCatalogItemMockData(): ICatalogItem {
    return {
        'ItemID': 720561,
        'ItemName': 'Gift Certificate',
        'ItemNumber': 'GIFTCERTIFICATE',
        'Price': 0.0,
        'AllowZeroPrice': false
    };
}