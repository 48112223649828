import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { AppConfigurationService } from '@pm/core/configuration';
import { FooterQuicklinksComponent } from '../footer-quicklinks/footer-quicklinks.component';

/**
 * Component for the standard footer.
 */
@Component({
  selector: 'pm-footer-standard',
  templateUrl: './footer-standard.component.html',
  styleUrls: ['./footer-standard.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FooterQuicklinksComponent,
    TranslateModule
  ]
})
export class FooterStandardComponent {
  /** Is navigation enabled? */
  public isNavigationEnabled = this.config.isNavigationEnabled;
  /** Is training pending going live? */
  public isTrainingPendingLive = this.config.isTrainingPendingLive;
  /** Is training enabled? */
  public isTrainingEnabled = this.config.isTrainingEnabled;
  /** TrainingCompanyId */
  public trainingCompanyId = this.config.trainingCompanyId;
  /** Application version. */
  public version = this.config.appVersion;
  /** Current year to display with the copyright. */
  public year = new Date().getFullYear();

  /**
   * Initializes instance of the FooterStandardComponent class.
   * @param config Application configuration service
   */
  constructor(
    private config: AppConfigurationService
  ) { }

  /**
   * Toogle training mode.
   * @param event DOM event.
   */
  toggleTraining(event: Event): void {
    event.preventDefault();
    this.config.toggleTraining();
  }
}
