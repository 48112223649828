<div class="report-group" [ngClass]='model?.Class'>
  <div class="report-group-header">
    <h3>{{model?.Label}}</h3>
    <h3>{{model?.Value?.Label}} {{model?.Value?.Value}}</h3>
  </div>
  <div class="group-body">
    <div [ngClass]='line.Class' *ngFor="let line of model?.Lines">
      <div *ngIf="!line?.InnerTag && (line?.IsSubLine === false || line?.IsSubLine === undefined) && line?.Label" class="label oneliner">{{line?.Label}}</div>
      <div *ngIf="!line?.InnerTag && line?.IsSubLine" class="label multiliner">{{line?.Label}}</div>
      <!-- Easy Options and others with their own tags. -->
      <div *ngIf="line?.InnerTag" class="easyOptionsHeader" [innerHTML]="line?.Label"></div>

      <div *ngIf="!line?.InnerTag && !line.IsSubLine" class="content oneliner">
        <div *ngFor="let item of line.Items" [ngClass]="item.Class">
          <span *ngIf="item?.Label && item?.Label !== 'Client Name'" [innerHtml]="item?.Label?.concat('&emsp;')"></span>
          <span *ngIf="!item?.Label" [innerHtml]=""></span>
          <span *ngIf="item?.Label && item?.Label === 'Client Name'" [innerHtml]="item?.Label"></span>
          <span *ngIf="item?.ValueItems?.length === 0 || !item?.ValueItems" [innerHtml]="item.Value"></span> 
          <!-- this.model.lines[i].items[j].Value -->
          <span *ngIf="item.ValueItems && item.ValueItems.length > 0 && item.ValueItems[0]" [ngClass]="item.ValueItems[0].Class"  [innerHtml]="item.Value"></span> 
          <!-- <span *ngIf="item.ValueItems && item.ValueItems.length > 0 && item.ValueItems[0]" [ngClass]="item.ValueItems[0].class"  [innerHtml]="item.ValueItems[0].Value"></span> -->
        </div>
      </div>
      <div *ngIf="!line?.InnerTag && line.IsSubLine" class="content multiliner">
        <div *ngFor="let item of line.Items" [ngClass]="item.Class">
          <span *ngIf="item?.Label" [innerHtml]="item?.Label?.concat('&emsp;')"></span>
          <span *ngIf="!item?.Label" [innerHtml]=""></span>
          <span *ngIf="item?.Value" class="first" [innerHtml]="item?.Value"></span>
          <span *ngIf="!item?.Value && item.ValueItems && item.ValueItems[0]" class="first" [innerHtml]="item.ValueItems[0].Value"></span>
          <span *ngIf="!item?.Value && item.ValueItems && item.ValueItems[1]" class="second" [ngClass]="item.ValueItems[1].Class" [innerHtml]="item.ValueItems[1].Value"></span>
          <span *ngIf="!item?.Value && item.ValueItems && item.ValueItems[2]" class="third" [innerHtml]="item.ValueItems[2].Value"></span>
        </div>
      </div>
      <!-- Easy Options and others with their own tags. -->
      <div *ngIf="line?.InnerTag">
        <div *ngFor="let item of line.Items">
          <div *ngIf="item?.Label" [innerHTML]="item?.Label"></div>
          <ul *ngIf="item?.Values" class="easyOptionsBullet">
            <li *ngFor="let val of item?.Values">
              <div [innerHTML]="val"></div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div *ngIf="model?.FootnoteAttributes" class="modal-footer">
      <span *ngIf="model?.FootnoteAttributes?.Value" [ngClass]="model?.FootnoteAttributes?.Class" [innerHtml]="model?.FootnoteAttributes?.Value"></span>
    </div>
  </div>
</div>
