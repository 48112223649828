<div class="d-flex flex-wrap gap-3" role="toolbar">

    <!-- apps (lg) -->
    <div class="d-none d-md-block d-xl-none" role="toolbar">
        <button data-ef-button="toolbar-apps" mat-button class="dropdown-toggle" [matMenuTriggerFor]="menuApps"
            aria-haspopup="true" aria-expanded="false">
            <mat-icon aria-hidden="true">apps</mat-icon>
            <span class="d-none d-sm-inline-block">{{'NAVIGATION.APPS'|translate}}</span>
        </button>
        <mat-menu #menuApps="matMenu">
            <ng-template #apps matMenuContent>
                <a data-ef-link="apps-launch-ehr" mat-menu-item href="#" (click)="launchEhr($event)"
                    *ngIf="isEhrEnabled">{{'NAVIGATION.EHR'|translate}}</a>
                <button mat-menu-item [matMenuTriggerFor]="menuStoreOperations">
                    {{'NAVIGATION.STORE_OPERATIONS'|translate}}
                </button>
                <button mat-menu-item [matMenuTriggerFor]="menuHelp" class="d-lg-none">
                    {{'NAVIGATION.HELP'|translate}}
                </button>
            </ng-template>
        </mat-menu>
    </div>

    <!-- ehr (xl) -->
    <button data-ef-button="toolbar-ehr" mat-button class="align-items-center d-none d-xl-flex"
        (click)="launchEhr($event)" *ngIf="isEhrEnabled">
        <mat-icon aria-hidden="true">cloud</mat-icon>
        <span>{{'NAVIGATION.EHR'|translate}}</span>
    </button>

    <!-- store operations (xl) -->
    <div class="d-none d-xl-block" role="toolbar">
        <button data-ef-button="toolbar-operations" mat-button class="dropdown-toggle"
            [matMenuTriggerFor]="menuStoreOperations" aria-haspopup="true" aria-expanded="false">
            <mat-icon aria-hidden="true">calendar_month</mat-icon>
            <span>{{'NAVIGATION.STORE_OPERATIONS'|translate}}</span>
        </button>
        <mat-menu #menuStoreOperations="matMenu">
            <ng-template matMenuContent>
                <a data-ef-link="operations-daily-opening" mat-menu-item pmNavLink="Common/DailyOpening"
                    *ngIf="isTillEnabled && hasDayOpenRole">{{'NAVIGATION.DAILY_OPENING'|translate}}</a>
                <a data-ef-link="operations-daily-closing" mat-menu-item
                    [pmNavLink]="isTillEnabled?'Common/TillDailyClosing':'Common/DailyClosing'"
                    *ngIf="hasDayCloseRole">{{'NAVIGATION.DAILY_CLOSING'|translate}}</a>
                <a data-ef-link="operations-change-payment" mat-menu-item pmNavLink="Management/ChangePayments"
                    *ngIf="hasPaymentTypesRole">
                    {{'NAVIGATION.CHANGE_PAYMENT'|translate}}</a>
                <a data-ef-link="operations-credit-card-processing" mat-menu-item
                    pmNavLink="Common/CreditCardProcessingPOS"
                    *ngIf="isPaymentAuthApi">{{'NAVIGATION.CREDIT_CARD_PROCESSING'|translate}}</a>
            </ng-template>
        </mat-menu>
    </div>

    <!-- help (lg, xl) -->
    <div class="d-none d-lg-block" role="toolbar">
        <button data-ef-button="toolbar-help" mat-button class="dropdown-toggle" [matMenuTriggerFor]="menuHelp"
            aria-haspopup="true" aria-expanded="false">
            <mat-icon aria-hidden="true">help</mat-icon>
            <span class="d-none d-sm-inline-block">{{'NAVIGATION.HELP'|translate}}</span>
        </button>
        <mat-menu #menuHelp="matMenu">
            <ng-template #helpLinks matMenuContent>
                <a data-ef-link="help-topics" mat-menu-item href="https://help.eyefinity.com/epm/Default.htm"
                    target="_blank">{{'NAVIGATION.HELP_TOPICS'|translate}}</a>
                <a data-ef-link="walk-through-launcher" mat-menu-item href="#" (click)="walkMeThroughLauncher($event)">
                    {{'NAVIGATION.HELP_HOW_TO'|translate}}</a>
                <a data-ef-link="support-knowledge-base-link" mat-menu-item [href]="supportKnowledgeBaseUrl"
                    target="_blank">
                    {{'NAVIGATION.HELP_KNOWLEDGE_BASE'|translate}}</a>
            </ng-template>
        </mat-menu>
        <ul class="dropdown-menu">
            <li></li>
        </ul>
    </div>

    <!-- user (md, lg, xl) -->
    <div class="d-none d-md-block" role="toolbar">
        <button data-ef-button="toolbar-user" mat-button class="dropdown-toggle" [matMenuTriggerFor]="menuUser"
            aria-haspopup="true" aria-expanded="false">
            <mat-icon aria-hidden="true">person_outline</mat-icon>
            <span id="userFullName" class="text-ellipsis" data-ef="user-full-name" [title]="userMenuTitle">
                {{ userMenuLabel }}
            </span>
        </button>
        <mat-menu #menuUser="matMenu">
            <ng-template #user matMenuContent>
                <a data-ef-link="user-management-view" mat-menu-item pmNavLink="Common/ManagementView"
                    *ngIf="kpiView">{{kpiView}}</a>
                <a data-ef-link="user-change-password" mat-menu-item
                    pmNavLink="Common/ChangePassword">{{'NAVIGATION.CHANGE_PASSWORD'|translate}}</a>
                <a data-ef-link="user-security-question" mat-menu-item pmNavLink="Common/SecurityQuestion">
                    {{'NAVIGATION.CHANGE_SECURITY_QUESTIONS'|translate}}</a>
                <a data-ef-link="user-change-location" mat-menu-item (click)="openChangeLocationModal($event)">
                        {{'NAVIGATION.CHANGE_OFFICE'|translate}}</a>
                <ng-template [ngIf]="!isTrainingPendingLive && (isTrainingEnabled || trainingCompanyId !== '')">
                    <mat-divider class="d-none d-md-block mb-2 mt-2"></mat-divider>
                    <a data-ef-link="user-training-switch" href="#" mat-menu-item class="d-none d-md-flex"
                        (click)="toggleTraining($event)">
                        {{(isTrainingEnabled?'TRAINING.LIVE_SWITCH':'TRAINING.TRAINING_SWITCH')|translate}}</a>
                </ng-template>
            </ng-template>
        </mat-menu>
    </div>

    <!-- logout -->
    <button id="btn_logout" data-ef-button="toolbar-logout" mat-button class="d-none d-md-inline-flex"
        (click)="logout();" [title]="'NAVIGATION.LOGOUT_TOOLTIP'|translate">
        <mat-icon aria-hidden="true">power_settings_new</mat-icon>
        <span>{{'NAVIGATION.LOGOUT'|translate}}</span>
    </button>

    <!-- mobile menu (s, xs) -->
    <div class="d-block d-md-none" role="toolbar">
        <button data-ef-button="toolbar-user" mat-icon-button [matMenuTriggerFor]="menuMobile" class="d-inline-flex"
            aria-haspopup="true" aria-expanded="false">
            <mat-icon aria-hidden="true">apps</mat-icon>
        </button>
        <mat-menu #menuMobile="matMenu" class="mobile-nav-menu">
            <mat-accordion>

                <!-- apps -->
                <mat-expansion-panel>
                    <mat-expansion-panel-header (click)="$event.stopPropagation()">
                        <mat-panel-title>
                            <mat-icon aria-hidden="true">apps</mat-icon>
                            <span>{{'NAVIGATION.APPS'|translate}}</span>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <ng-container [ngTemplateOutlet]="apps"></ng-container>
                </mat-expansion-panel>

                <!-- help -->
                <mat-expansion-panel>
                    <mat-expansion-panel-header (click)="$event.stopPropagation()">
                        <mat-panel-title>
                            <mat-icon aria-hidden="true">help</mat-icon>
                            <span>{{'NAVIGATION.HELP'|translate}}</span>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <ng-container [ngTemplateOutlet]="helpLinks"></ng-container>
                </mat-expansion-panel>

                <!-- user -->
                <mat-expansion-panel>
                    <mat-expansion-panel-header (click)="$event.stopPropagation()">
                        <mat-panel-title>
                            <mat-icon aria-hidden="true">person_outline</mat-icon>
                            <span data-ef="mobile-full-name">{{ userMenuLabel }}</span>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <ng-container [ngTemplateOutlet]="user"></ng-container>
                </mat-expansion-panel>

                <!-- logout -->
                <button data-ef-button="toolbar-logout" mat-menu-item class="mobile-line-before" (click)="logout();">
                    <mat-icon aria-hidden="true">power_settings_new</mat-icon>
                    <span>{{'NAVIGATION.LOGOUT'|translate}}</span>
                </button>

            </mat-accordion>
        </mat-menu>
    </div>
</div>