import { PatientOrder } from './patient-order'; 
import { IPatientOverview } from '../interfaces/patient-overview';
import { PatientNotesSearchResults } from './patient-notes-search-results';
import { PatientAppointment } from './patient-appointment'; 
import { PatientRecallDetail } from './patient-recall-detail'; 
import { PatientRelationships } from './patient-relationship';
import { PatientRx } from './patient-rx';

export class PatientOverview {
    constructor(data: IPatientOverview) {
        this.id = data.Id;
        this.patientUid = data.PatientUid;
        this.fullName = data.FullName;
        this.age = data.Age;
        this.dateOfBirth = data.DateOfBirth;
        this.address = data.Address;
        this.cityStateZip = data.CityStateZip;
        this.primaryPhone = data.PrimaryPhone;
        this.secondaryPhone = data.SecondaryPhone;
        this.insuranceOne = data.InsuranceOne;
        this.insuranceTwo = data.InsuranceTwo;
        this.insuranceThree = data.InsuranceThree;
        this.email = data.Email;
        this.providerName = data.ProviderName;
        this.nickName = data.NickName;
        this.outstandingBalance = data.OutstandingBalance;
        this.patientCredit = data.PatientCredit;
        this.patientInsuranceBalance = data.PatientInsuranceBalance;
        this.lastExamDate = data.LastExamDate;
        this.responsibleParty = data.ResponsibleParty;
        this.isHipaaSignatureOnFile = data.IsHipaaSignatureOnFile;
        this.noteResults = data.NoteResults?.map(note => new PatientNotesSearchResults(note));
        this.patientDependents = data.PatientDependents?.map(dependent => new PatientRelationships(dependent));
        this.patientRecalls = data.PatientRecalls?.map(recall => new PatientRecallDetail(recall));
        this.patientAppointments = data.PatientAppointments?.map(appointment => new PatientAppointment(appointment));
        this.patientRxResults = data.PatientRxResults?.map(rx => new PatientRx(rx));
        this.patientOrders = data.PatientOrders?.map(order => new PatientOrder(order));
        this.totalCancellations = data.TotalCancellations;
        this.totalNoShows = data.TotalNoShows;
        this.homeOffice = data.HomeOffice;
        this.signatureDate = data.SignatureDate;
        this.firstName = data.FirstName;
        this.lastName = data.LastName;
        this.enforceResponsiblePartyForMinor = data.EnforceResponsiblePartyForMinor;
        this.isReview = data.IsReview;
    }
    /** patient id */
    id: number;
    /** patient uuid */
    patientUid: string;
    /** patient full name */
    fullName: string;
    /** Age */
    age: string;
    /** Date of birth */
    dateOfBirth: string;
    /** Address */
    address: string;
    /** City, state, and zip code */
    cityStateZip: string;
    /** Primary phone number */
    primaryPhone: string;
    /** Secondary phone number */
    secondaryPhone: string;
    /** Primary insurance */
    insuranceOne: string;
    /** Secondary insurance */
    insuranceTwo: string;
    /** Tertiary insurance */
    insuranceThree: string;
    /** Email address */
    email: string;
    /** Provider name */
    providerName: string;
    /** Nickname */
    nickName: string;
    /** Outstanding balance */
    outstandingBalance: number;
    /** Patient credit */
    patientCredit: number;
    /** Patient insurance balance */
    patientInsuranceBalance: number;
    /** Date of last exam */
    lastExamDate: string;
    /** Responsible party */
    responsibleParty: string;
    /** HIPAA signature on file status */
    isHipaaSignatureOnFile: boolean;
    /** Note results */
    noteResults;
    /** Patient dependents */
    patientDependents;
    /** Patient recalls */
    patientRecalls;
    /** Patient appointments */
    patientAppointments;
    /** Patient prescription results */
    patientRxResults;
    /** Patient orders */
    patientOrders;
    /** Total cancellations */
    totalCancellations: number;
    /** Total no-shows */
    totalNoShows: number;
    /** Home office location */
    homeOffice: string;
    /** Signature date */
    signatureDate: string;
    /** First name */
    firstName: string;
    /** Last name */
    lastName: string;
    /** Enforce responsible party for minor status */
    enforceResponsiblePartyForMinor: boolean;
    /** Review status */
    isReview: boolean;
  }