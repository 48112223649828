import { ICatalogItem } from '../interfaces/catalog-item';

export function DispensingFeesMockData(): ICatalogItem[] {
    return [
        {
            'ItemID': 1379338,
            'ItemName': 'Sv Dispensing Fee',
            'ItemNumber': '92340',
            'Price': 25.0,
            'AllowZeroPrice': true
        },
        {
            'ItemID': 1379339,
            'ItemName': 'Bf Dispensing Fee',
            'ItemNumber': '92341',
            'Price': 20.0,
            'AllowZeroPrice': true
        },
        {
            'ItemID': 1522151,
            'ItemName': 'Mf Dispensing Fee',
            'ItemNumber': '92342',
            'Price': 25.0,
            'AllowZeroPrice': true
        }
    ];
}