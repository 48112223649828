<header class="mb-3 mb-md-4" [ngClass]="mode">
    <div class="d-flex header-top px-3 px-md-4">
        <a data-ef-link="header-logo-link" pmNavLink="" class="align-items-center d-flex gap-4 py-2 py-md-3">
            <pm-logo data-ef="header-logo" [isOnDarkBackground]="true" [isPremier]="isPremier"></pm-logo>
        </a>
        <pm-toolbar class="align-items-center d-flex flex-row flex-fill justify-content-end"
            *ngIf="isNavigationEnabled"></pm-toolbar>
    </div>
    <ng-container [ngSwitch]="navigation" *ngIf="isNavigationEnabled&&(!isFullscreen||mode!=='calendar')">
        <pm-navigation-claims *ngSwitchCase="'claims'"></pm-navigation-claims>
        <pm-navigation [mode]="mode" *ngSwitchDefault></pm-navigation>
    </ng-container>
</header>