import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpErrorResponse, HttpRequest } from '@angular/common/http';

import { TranslateModule } from '@ngx-translate/core';

import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { MatIconModule } from '@angular/material/icon';

/**
 * System failure alert component.
 */
@Component({
  selector: 'pm-system-failure-alert',
  templateUrl: './system-failure-alert.component.html',
  styleUrls: ['./system-failure-alert.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    TranslateModule
  ]
})
export class SystemFailureAlertComponent {
  /** HTTP error response. */
  error: HttpErrorResponse;

  /**
   * Initializes instance of SystemFailureAlertComponent.
   * @param data HTTP error response
   */
  constructor(@Inject(MAT_SNACK_BAR_DATA) private data: ISystemFailureAlertData) {
    this.error = this.data.error;
  }
}

/**
 * Interface for data injected into the system failure alert component.
 */
export interface ISystemFailureAlertData {
  /** HTTP error respone. */
  error: HttpErrorResponse;
  /** HTTP request. */
  request: HttpRequest<unknown>;
}
