import { IPatientOrder } from '../interfaces/patient-order';

/**
 * PatientOrder class
 */
export class PatientOrder {
    /** Order number */
    orderNum: number;
    /** Patient ID */
    patientId: number;
    /** Order date */
    orderDate: string;
    /** Order type */
    orderType: string;
    /** Order status */
    orderStatus: string;

    constructor(data: IPatientOrder) {
        this.orderNum = data.OrderNum;
        this.patientId = data.PatientId;
        this.orderDate = data.OrderDate;
        this.orderType = data.OrderType;
        this.orderStatus = data.OrderStatus;
    }
}