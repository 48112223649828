import { IPatientNotesSearchResults } from '../interfaces/patient-notes-search-results';

export function MockPatientNotesSearchResults(): IPatientNotesSearchResults[] {
  return [
    {
      PatientId: 1,
      NoteId: 101,
      ResourceId: 201,
      Resource: 'Dr. John Smith',
      Date: '2023-01-01',
      Note: 'Patient is recovering well.',
      NoteType: 'Progress',
      IsUrgent: false,
      IsFollowUp: true,
      OfficeId: 'OFFICE001',
      TypeNote: 'General',
      EntityId: 301
    },
    {
      PatientId: 2,
      NoteId: 102,
      ResourceId: 202,
      Resource: 'Dr. Jane Doe',
      Date: '2023-01-02',
      Note: 'Patient needs to follow up in two weeks.',
      NoteType: 'Follow-up',
      IsUrgent: true,
      IsFollowUp: true,
      OfficeId: 'OFFICE002',
      TypeNote: 'Follow-up',
      EntityId: 302
    },
    {
      PatientId: 3,
      NoteId: 103,
      ResourceId: 203,
      Resource: 'Dr. Emily Johnson',
      Date: '2023-01-03',
      Note: 'Patient has shown improvement in symptoms.',
      NoteType: 'Progress',
      IsUrgent: false,
      IsFollowUp: false,
      OfficeId: 'OFFICE003',
      TypeNote: 'General',
      EntityId: 303
    }
  ];
}