/**
 * Interface for employee offices
 */

export interface IOffice {
    /** Office number **/
    OfficeNum: string;
    /** Office name **/
    OfficeName: string;
    /** Office address **/
    OfficeAddress: string;
  }