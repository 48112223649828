import { IAccessory } from '../interfaces/accessory';

export function AccessoriesMockData(): IAccessory[] {
  return [
    {
      ItemID: 2862416,
      UpcNumber: '',
      ItemNumber: 'A2862416',
      ItemName: 'Acc - Blue Neoprene Optistrap',
      Price: '$100.00',
      ListPrice: '$200.00',
      OnHandQuantity: 0,
    },
    {
      ItemID: 2791530,
      UpcNumber: '',
      ItemNumber: 'A2791530',
      ItemName: 'Acc - EZ Eye Inserter',
      Price: '$19.99',
      ListPrice: '$50.00',
      OnHandQuantity: 0,
    },
    {
      ItemID: 2479261,
      UpcNumber: 'Test123',
      ItemNumber: 'A2479261',
      ItemName: 'Acc- 2.8X LED With LED Handle',
      Price: '$23.00',
      ListPrice: '$75.00',
      OnHandQuantity: 0,
    },
    {
      ItemID: 1608341,
      UpcNumber: '',
      ItemNumber: 'A1608341',
      ItemName: 'Acc- Babiators',
      Price: '$0.00',
      ListPrice: '$0.00',
      OnHandQuantity: 0,
    },
    {
      ItemID: 2939505,
      UpcNumber: '',
      ItemNumber: 'A2939505',
      ItemName: 'Acc- Chemistrie Clip Clear',
      Price: '$20.00',
      ListPrice: '$0.00',
      OnHandQuantity: 0,
    },
    {
      ItemID: 1513899,
      UpcNumber: '',
      ItemNumber: 'A1513899',
      ItemName: 'Acc- Chemistrie Clip Gradient',
      Price: '$20.00',
      ListPrice: '$0.00',
      OnHandQuantity: 0,
    },
    {
      ItemID: 2939506,
      UpcNumber: '',
      ItemNumber: 'A2939505',
      ItemName: 'Acc- Chemistrie Clip Jewel',
      Price: '$20.00',
      ListPrice: '$0.00',
      OnHandQuantity: 0,
    },
    {
      ItemID: 1513898,
      UpcNumber: '',
      ItemNumber: 'A1513898',
      ItemName: 'Acc- Chemistrie Clip Mirror',
      Price: '$20.00',
      ListPrice: '$0.00',
      OnHandQuantity: 0,
    },
    {
      ItemID: 1513897,
      UpcNumber: '',
      ItemNumber: 'A1513897',
      ItemName: 'Acc- Chemistrie Clip Regular',
      Price: '$20.00',
      OnHandQuantity: 0,
    },
    {
      ItemID: 1513901,
      UpcNumber: '',
      ItemNumber: 'A1513901',
      ItemName: 'Acc- Chemistrie Clip Retro Fit',
      Price: '$20.00',
      ListPrice: '$0.00',
      OnHandQuantity: 0,
    },
    {
      ItemID: 1513900,
      UpcNumber: '',
      ItemNumber: 'A1513900',
      ItemName: 'Acc- Chemistrie Clip Stones',
      Price: '$20.00',
      ListPrice: '$0.00',
      OnHandQuantity: 0,
    },
    {
      ItemID: 3030413,
      UpcNumber: '612520437985',
      ItemNumber: 'A3030413',
      ItemName: 'Acc- Contours Colorset Pencil Primer',
      Price: '$0.00',
      ListPrice: '$0.00',
      OnHandQuantity: 0,
    },
    {
      ItemID: 2120407,
      UpcNumber: 'KEY4112',
      ItemNumber: 'A2120407',
      ItemName: 'Acc- Eccentric Circles',
      Price: '$0.00',
      ListPrice: '$0.00',
      OnHandQuantity: 0,
    },
    {
      ItemID: 1633507,
      UpcNumber: '029144047450',
      ItemNumber: 'A1633507',
      ItemName: 'Acc- Eye Mask',
      Price: '$150.00',
      ListPrice: '$0.00',
      OnHandQuantity: 0,
    },
    {
      ItemID: 2888012,
      UpcNumber: null,
      ItemNumber: 'A2888012',
      ItemName: 'Acc- Flash Drive w/photos',
      Price: '$0.00',
      ListPrice: '$0.00',
      OnHandQuantity: 0,
    },
    {
      ItemID: 2773929,
      UpcNumber: '',
      ItemNumber: 'A2773929',
      ItemName: 'Acc- Gel Pack',
      Price: '$25.00',
      ListPrice: '$0.00',
      OnHandQuantity: 0,
    },
    {
      ItemID: 1632478,
      UpcNumber: '',
      ItemNumber: 'A1632478',
      ItemName: 'Accessory Fee',
      Price: '$0.00',
      ListPrice: '$0.00',
      OnHandQuantity: 0,
    },
    {
      ItemID: 726414,
      UpcNumber: 'A00308',
      ItemNumber: 'A00308',
      ItemName: 'Band-  Sport Bands',
      Price: '$25.00',
      ListPrice: '$25.00',
      OnHandQuantity: 0,
    },
    {
      ItemID: 726440,
      UpcNumber: 'A00720',
      ItemNumber: 'A00720',
      ItemName: 'Case- Opv-Polishd.cntc.cse 903999',
      Price: '$36.99',
      ListPrice: '$25.00',
      OnHandQuantity: 0,
    },
    {
      ItemID: 1513824,
      UpcNumber: '',
      ItemNumber: 'A1513824',
      ItemName: 'Case- Vera Bradley Large',
      Price: '$35.00',
      ListPrice: '$25.00',
      OnHandQuantity: 0,
    },
    {
      ItemID: 1155709,
      UpcNumber: '082664107585',
      ItemNumber: '9321',
      ItemName: 'Clean- Clarity Clean It 1 Oz',
      Price: '$12.00',
      ListPrice: '$25.00',
      OnHandQuantity: 0,
    },
    {
      ItemID: 1610893,
      UpcNumber: '',
      ItemNumber: 'A1610893',
      ItemName: 'Clean- Cleaning Kit',
      Price: '$2.00',
      ListPrice: '$25.00',
      OnHandQuantity: 0,
    },
    {
      ItemID: 2141367,
      UpcNumber: '',
      ItemNumber: 'A2141367',
      ItemName: 'Clean- Foaming Cleanser',
      Price: '$12.00',
      ListPrice: '$25.00',
      OnHandQuantity: 0,
    },
    {
      ItemID: 1639040,
      UpcNumber: '',
      ItemNumber: 'A1639040',
      ItemName: 'Clean- Occusoft Lid Scrub',
      Price: '$0.00',
      ListPrice: '$0.00',
      OnHandQuantity: 0,
    },
    {
      ItemID: 1599302,
      UpcNumber: '',
      ItemNumber: 'froggy',
      ItemName: 'Kids Band (Froggy Cords)',
      Price: '$15.00',
      ListPrice: '$30.00',
      OnHandQuantity: 0,
    },
    {
      ItemID: 1614415,
      UpcNumber: '',
      ItemNumber: 'A1614415',
      ItemName: 'Med- Dry Eye Relief',
      Price: '$25.00',
      ListPrice: '$40.00',
      OnHandQuantity: 0,
    },
    {
      ItemID: 1930468,
      UpcNumber: null,
      ItemNumber: 'A1930468',
      ItemName: 'Med- Medication',
      Price: '$65.00',
      ListPrice: '$70.00',
      OnHandQuantity: 0,
    },
    {
      ItemID: 1793772,
      UpcNumber: '',
      ItemNumber: 'A1793772',
      ItemName: 'Misc Contacts',
      Price: '$111.11',
      ListPrice: '$200.00',
      OnHandQuantity: 0,
    },
    {
      ItemID: 1665209,
      UpcNumber: '',
      ItemNumber: 'A1665209',
      ItemName: 'NerdWax',
      Price: '$25.00',
      ListPrice: '$60.00',
      OnHandQuantity: 0,
    },
    {
      ItemID: 1155735,
      UpcNumber: '',
      ItemNumber: '2135',
      ItemName: 'Patch- Patchwork Kids Eye Patches (2)',
      Price: '$15.00',
      ListPrice: '$40.00',
      OnHandQuantity: 0,
    },
    {
      ItemID: 726407,
      UpcNumber: 'A00249',
      ItemNumber: 'A00249',
      ItemName: 'Screwdriver',
      Price: '$22.00',
      ListPrice: '$34.00',
      OnHandQuantity: 0,
    },
    {
      ItemID: 1155752,
      UpcNumber: '',
      ItemNumber: 'A1155752',
      ItemName: 'Sol- Cleaning Solution',
      Price: '$123.00',
      ListPrice: '$150.00',
      OnHandQuantity: 0,
    },
    {
      ItemID: 1614423,
      UpcNumber: '615033012519',
      ItemNumber: 'A1614423',
      ItemName: 'Sup- Vitamin K2',
      Price: '$5.99',
      ListPrice: '$10.00',
      OnHandQuantity: 0,
    },
  ];
}
