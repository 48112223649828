import { IOrderEligibility } from '../interfaces/order-eligibility';
import { PatientInsurance } from './patient-insurance';

/**
 * Eligibility class.
 */
export class Eligibility {
    /** Authorization date. */
    public authDate: Date;
    /** Authorization expiration date. */
    public authExpireDate: Date | null;
    /** Authorization number. */
    public authNumber: string;
    /** Patient insurance. */
    public insurance: PatientInsurance;
    /** Insurance eligibility id. */
    public insuranceEligibilityId: number;
    /** Remaining total exam orders. */
    public availableExamOrders: number;
    /** Remaining total frame orders. */
    public availableFrameOrders: number;
    /** Remaining total lens orders. */
    public availableLensOrders: number;
    /** Remaining total CL orders. */
    public availableCLOrders: number;
    /** Remaining total CL fit orders. */
    public availableCLFitOrders: number;
     /** Is exam eligible? */
    public isExamElig: boolean;
    /** Is frame eligible? */
    public isFrameElig: boolean;
    /** Is lens eligible? */
    public isLensElig: boolean;
    /** Is CL eligible? */
    public isClElig: boolean;

    /**
     * Initializes instance of the Eligibility class.
     * @param eligibility Eligibility data.
     */
    constructor(insurance: PatientInsurance, eligibility: IOrderEligibility) {
        this.authDate = new Date(eligibility.AuthDate);
        this.authExpireDate = eligibility.AuthExpireDate ? new Date(eligibility.AuthExpireDate) : null;
        this.authNumber = eligibility.AuthNumber ?? '';
        this.insurance = insurance;
        this.insuranceEligibilityId = eligibility.EligibilityId;
        this.availableExamOrders = eligibility.RemainingAvailableExamOrders;
        this.availableFrameOrders = eligibility.RemainingAvailableFrameOrders;
        this.availableLensOrders = eligibility.RemainingAvailableLensOrders;
        this.availableCLOrders = eligibility.RemainingAvailableCLOrders;
        this.availableCLFitOrders = eligibility.RemainingAvailableCLFitOrders;
        this.isClElig = eligibility.IsClElig;
        this.isExamElig = eligibility.IsExamElig;
        this.isFrameElig = eligibility.IsFrameElig;
        this.isLensElig = eligibility.IsLensElig;
    }
}
