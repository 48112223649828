import { IPatientReviewVm } from '../interfaces/patient-review-vm';

/**
 * PatientReviewVm class
 */
export class PatientReviewVm {
    /** Patient ID */
    patientId: number;
    /** First name */
    firstName: string;
    /** Last name */
    lastName: string;
    /** Date of birth */
    dob: string;
    /** Date created */
    createdDate: string;
    /** Note details */
    noteDetail: string;
    /** Office number */
    officeNumber: string;
    /** Permission to merge patient */
    hasMergePatientPermission: boolean;

    constructor(patientReview: IPatientReviewVm) {
        this.patientId = patientReview.PatientId;
        this.firstName = patientReview.FirstName;
        this.lastName = patientReview.LastName;
        this.dob = patientReview.Dob;
        this.createdDate = patientReview.CreatedDate;
        this.noteDetail = patientReview.NoteDetail;
        this.officeNumber = patientReview.OfficeNumber;
        this.hasMergePatientPermission = patientReview.HasMergePatientPermission;
    }

    /** Get the full name of the patient */
    get patientName(): string {
        return `${this.firstName} ${this.lastName}`;
    }
}