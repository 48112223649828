import { Sort } from '@angular/material/sort';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
  })
export class PatientAppointmentsService {
    /** API Controller */
    private controller = 'Appointment'

      /** Default sort state for failed patient orders. */
    public defaultPatientsReviewListOrderSort: Sort = { active: 'appt_date', direction: 'desc' };
    /**
    * Initializes instance of the PatientAppointmentsService class.
    * @param http HTTP client
    */
    constructor(private http: HttpClient) { }

  /**
   * Confirm Appointment to the API Controller.
   * @param apptId appointment id.
   * @returns Boolean indicating success.
   */
    async postAppointmentConfirmation(apptId: number, userId: number): Promise<boolean> {
        return new Promise<boolean>(resolve => {
            const confirmAppointmentEnum = 2;
            const url = `${this.controller}/UpdateAppointmentStatus?appointmentStatus=${encodeURIComponent(confirmAppointmentEnum)}&userId=${encodeURIComponent(userId)}&appointmentId=${encodeURIComponent(apptId)}`;
            this.http.post<boolean>(url, apptId)
            .subscribe({
                next: success => resolve(success),
                error: () => resolve(false)
            });
        });
    }
}