<h1 mat-dialog-title>
    <span>{{'DIALOGS.REVIEW_PATIENTS'|translate}}</span>
    <button mat-icon-button [attr.aria-label]="'BUTTONS.CLOSE'" mat-dialog-close="close">
        <mat-icon>close</mat-icon>
    </button>
</h1>
<div id="patientsReviewDialog" mat-dialog-content>
    <table id="patientReviewTable"></table>
    <br />
    <div class="fieldMessages"></div>
</div>
<div mat-dialog-actions class="justify-content-end">
    <button mat-flat-button cdkFocusInitial color="primary"
        mat-dialog-close="close">{{'BUTTONS.CLOSE'|translate}}</button>
</div>