import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { AppConfigurationService } from '@pm/core/configuration';

import { ContentService } from '@pm/core/content';
import { BLANK_IMAGE } from '@pm/layout';

/**
 * Component for the Eyefinity/Encompass logo.
 */
@Component({
  selector: 'pm-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule
  ]
})
export class LogoComponent implements OnInit {
  /** Application name for image title. */
  public appName = '';
  /** Image source for logo. */
  public imageSrc = BLANK_IMAGE;

  /** Is the logo on a dark background? */
  @Input() isOnDarkBackground = false;

  /** Use the premier logo? */
  @Input() isPremier = false;

  /** Event fired after image is loaded. */
  @Output() loaded = new EventEmitter();

  /**
   * Initializes an instance of the LogoComponent class.
   * @param config Application configuration service
   * @param content External content service
   * @param translate Translation service
   */
  constructor(private content: ContentService, private config: AppConfigurationService) { }

  /**
   * OnInit lifecycle hook.
   */
  ngOnInit(): void {
    this.setImageSrc();
  }

  /**
   * Set image source.
   */
  async setImageSrc(): Promise<void> {
    const content = await this.content.appContent;
    if (content) {
      this.appName = content.appNameLong;
      if (this.isPremier) {
        this.imageSrc = this.isOnDarkBackground ? content.appLogoWithPremierSvgForDarkBackground : content.appLogoWithPremierSvgForLightBackground;
      } else {
        this.imageSrc = this.isOnDarkBackground ? content.appLogoSvgForDarkBackground : content.appLogoSvgForLightBackground;
      }
    }
  }

  async onImgError() : Promise<void>  {
    const content = await this.content.appContent;
    if(content) {
      if (this.isPremier) {
        this.imageSrc = this.isOnDarkBackground ? this.config.getAssetPath('images/eyefinity-premier-logo-for-dark-bg.svg') : this.config.getAssetPath('images/eyefinity-premier-logo-for-light-bg.svg');
      } else {
        this.imageSrc = this.isOnDarkBackground ? this.config.getAssetPath('images/eyefinity-logo-for-dark-bg.svg') : this.config.getAssetPath('images/eyefinity-logo-for-light-bg.svg');
      }
    }
  }
}
