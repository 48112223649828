import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'pm-alert',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    TranslateModule
  ],
  templateUrl: './alert.dialog.html',
  styleUrl: './alert.dialog.scss'
})
export class AlertDialog {

  /** Confirm message. */
  public message: string;
  /** Dialog title. */
  public title: string;
  /** Dialog Button text. */
  public buttonText: string;

  /**
   * Initialize instance of the ConfirmDialog class.
   * @param data Alert dialog configuraion
   */
  constructor(translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) private data: IAlertDialogData) {

    this.message = translate.instant(data.message,{data: data.messageParams});
    this.title = data.title ?? 'DIALOGS.ALERT';
    this.buttonText = data.buttonText ?? 'BUTTONS.CLOSE';
  }
}

/**
 * Confirm dialog configuraion.
 */
export interface IAlertDialogData {
  message: string;
  title?: string;
  buttonText?: string;
  messageParams?: string;
}