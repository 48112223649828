import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfigurationService } from './app-configuration.service';

/**
 * Application feature service.
 */
@Injectable({
  providedIn: 'root'
})
export class AppFeatureService {
  /** Is analytics and insights enabled?  */
  readonly isAnalyticsAndInsightsEnabled = localStorage.getItem('IsAnalyticsAndInsightsEnabled') === 'true';
  /** Is button click required for patient search? */
  readonly isBtnClickRequiredForPatientSearch = localStorage.getItem('IsBtnClickRequiredForPatientSearch') === 'true';
  /** Is CHW enabled? */
  readonly isChwEnabled = localStorage.getItem('IsChwEnabled') === 'true';
  /** Is ECR on prem? */
  readonly isEcrOnPrem = localStorage.getItem('IsEcrOnPrem') === 'true';
  /** Is hide billing info in front office enabled? */
  readonly isHideBillingInfoInFrontOfficeReportingEnabled = localStorage.getItem('IsHideBillingInfoInFrontOfficeReportingEnabled') === 'true';
  /** Is payment authorization API enabled? */
  readonly isPaymentAuthApi = window.localStorage.getItem('IsPaymentAuthApi') === 'true';
  /** Is schedule appointment for the office enabled? */
  readonly isScheduleApptforotherOfficesEnabled = localStorage.getItem('IsScheduleApptforotherOfficesEnabled') === 'true';
  /** Is show EPM feature configuration in AL Admin enabled? */
  readonly isShowFeatureConfigurationInALAdminEnabled = localStorage.getItem('IsShowEPMFeatureConfigurationInALAdminEnabled') === 'true';
  /** Is till enabled? */
  readonly isTillEnabled = localStorage.getItem('IsTillEnabled') === 'true';
  /** Is VSP Premier dashboard enabled? */
  readonly isVSPPremierDashboardEnabled = localStorage.getItem('IsVSPPremierDashboardEnabled') === 'true';

  /** Cache for feature values. */
  private cache: IFeatureCache = {};
  private pageUrl = window.location.pathname;

  /**
   * Initializes instance of the AppFeatureService class.
   * @param config Application configuration service
   * @param http HTTP client
   */
  constructor(
    private config: AppConfigurationService,
    private http: HttpClient
  ) { }

  /**
   * Get isEraEnabled feature value.
   */
  async isEraEnabled(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      if (this.cache.isEraEnabled === undefined) {
        const url = `Era/GetIsEraEnabledForCompany?companyId=${this.config.companyExternalId}`;
        this.http.get<boolean>(url).subscribe({
          next: enabled => {
            resolve(this.cache.isEraEnabled = enabled);
          },
          error: () => resolve(false)
        });
      } else {
        resolve(this.cache.isEraEnabled);
      }
    });
  }

  /**
   * Get isPatientStatementsEnabled feature value.
   */
  async isPatientStatementsEnabled(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      if (this.cache.isPatientStatementsEnabled === undefined) {
        const url = `PatientStatements/GetIsPatientStatementsEnabledForCompany?companyId=${this.config.companyExternalId}`;
        this.http.get<boolean>(url).subscribe({
          next: enabled => {
            if (enabled) {
              if (this.pageUrl.toLowerCase().indexOf('/billing/billingclaim/patientletter') > -1) {
                //Redirect to the new Patient Statement.               
                this.config.navigateTo('Billing/PatientStatements/PatientStatement');
              }
            }
            resolve(this.cache.isPatientStatementsEnabled = enabled);
          },
          error: () => resolve(false)
        });
      } else {
        resolve(this.cache.isPatientStatementsEnabled);
      }
    });
  }

  /** 
   * Get isClearinghouseClaimStatusIntegratedEnabled feature value.
   */
  async isClearinghouseClaimStatusIntegratedEnabled(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      if (this.cache.isClearinghouseClaimStatusIntegratedEnabled === undefined) {
        const url = `PatientStatements/GetIsClearinghouseClaimStatusIntegratedEnabled?companyId=${this.config.companyExternalId}`;
        this.http.get<boolean>(url).subscribe({
          next: enabled => {
            resolve(this.cache.isClearinghouseClaimStatusIntegratedEnabled = enabled);
          },
          error: () => resolve(false)
        })
      } else {
        resolve(this.cache.isClearinghouseClaimStatusIntegratedEnabled);
      }
    })
  }

  /**
   * Get isReviewPatientsCreatedOnlineEnabled feature value.
   */
  async isReviewPatientsCreatedOnlineEnabled(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      if (this.cache.isReviewPatientsCreatedOnlineEnabled === undefined) {
        const localStorageValue = localStorage.getItem('reviewPatientsCreatedOnlineFeature');
        if (localStorageValue) {
          const value = JSON.parse(localStorageValue) as boolean;
          resolve(this.cache.isReviewPatientsCreatedOnlineEnabled = value);
        } else {
          const url = 'Patient/GetReviewNeededPatientConfig';
          this.http.get<IReviewNeededPatientConfig>(url).subscribe({
            next: config => {
              const value = config.reviewPatientsCreatedOnlineFeature;
              resolve(this.cache.isReviewPatientsCreatedOnlineEnabled = value);
            },
            error: () => resolve(false)
          });
        }
      } else {
        resolve(this.cache.isReviewPatientsCreatedOnlineEnabled);
      }
    });
  }
}

/**
 * Interface for feature cache.
 */
interface IFeatureCache {
  /** Is ERA enabled? */
  isEraEnabled?: boolean;
  /** Is patient statements enabled? */
  isPatientStatementsEnabled?: boolean;
  /** Is review patients created online enabled? */
  isReviewPatientsCreatedOnlineEnabled?: boolean;
  /** Is Clearinghouse Claim Status Enabled */
  isClearinghouseClaimStatusIntegratedEnabled?: boolean;
}

/**
 * Interface for review needed patient config.
 */
interface IReviewNeededPatientConfig {
  /** Patient count. */
  patientCount: number;
  /** Review patients created online feature flag. */
  reviewPatientsCreatedOnlineFeature: boolean;
}