import { RelationshipType } from '../enums/relationship-type';
import { IOrderPatientInsurance } from '../interfaces/order-patient-insurance';
import { Eligibility } from './eligibility';

/**
 * Patient insurance class.
 */
export class PatientInsurance {
    /** Insurance carrier name. */
    public carrierName: string;
    /** Insurance carrier display name. */
    public displayName: string;
    /** Array of insurance eligibilities. */
    public eligibilities: Eligibility[];
    /** Group name. */
    public groupName: string | null;
    /** Group number. */
    public groupNumber: string | null;
    /** Is manual plan? */
    public isManualPlan: boolean
    /** Is VSP insurance? */
    public isVsp: boolean
    /** Patient insurance id. */
    public patientInsuranceId: number;
    /** Insurance plan id. */
    public planId: number;
    /** Plan name. */
    public planName: string;
    /** Relationship to the insured. */
    public relationshipToScubscriber: RelationshipType;
    /** Subscriber name. */
    public subscriber: string;
    /** Is Medicaid insurance? */
    public isMedicaidInsurance: boolean;

    /**
     * Initializes instance of the PatientInsurance class.
     * @param insurance Patient insurance data.
     */
    constructor(insurance: IOrderPatientInsurance) {
        this.carrierName = insurance.Plan.Carrier.CarrierName;
        this.planName = insurance.Plan.PlanName ?? '';
        this.displayName = `${this.carrierName} / ${this.planName}`;
        this.eligibilities = insurance.Eligibilities.map(eligibility => new Eligibility(this, eligibility));
        this.groupName = insurance.Subscriber.GroupName;
        this.groupNumber = insurance.Subscriber.GroupNum;
        this.isManualPlan = insurance.Plan.IsManualPlan;
        this.isVsp = insurance.Plan.Carrier.IsVsp;
        this.patientInsuranceId = insurance.PatientInsuranceId;
        this.planId = insurance.Plan.PlanId;
        this.relationshipToScubscriber = insurance.Subscriber.RelationToSubscriberId;
        this.subscriber = insurance.Subscriber.DisplayName;
        this.isMedicaidInsurance = insurance.Plan.IsMedicaidInsurance;
    }
}
