import { PatientPinnedNoteType } from '../enums/patient-pinned-note-type';
import { IPatientPinnedNote } from '../interfaces/patient-pinned-note';

/**
 * Patient pinned note.
 */
export class PatientPinnedNote {
    /** Pinned note id. */
    public id: number;
    /** Patient internal id. */
    public patientInternalId: number;
    /** Patient pinned note type. */
    public type: PatientPinnedNoteType;
    /** Note text. */
    public note: string;
    /** Date created. */
    public dateCreated?: string;
    /** Date modified. */
    public dateModified?: string;

    /**
     * Initializes instance of PatientPinnedNote class.
     * @param pinnedNote Patient pinned noted details
     */
    constructor(pinnedNote: IPatientPinnedNote) {
        this.id = pinnedNote.Id;
        this.patientInternalId = pinnedNote.PatientId;
        this.type = pinnedNote.Type;
        this.note = pinnedNote.Note ?? '';
        this.dateCreated = pinnedNote.DateCreated;
        this.dateModified = pinnedNote.DateModified;
    }

    /**
     * Convert pinned note for save request.
     * @returns Patient pinned note
     */
    convert(): IPatientPinnedNote {
        return {
            Id: this.id,
            PatientId: this.patientInternalId,
            Type: this.type,
            Note: this.note,
            DateCreated: this.dateCreated,
            DateModified: this.dateModified
        };
    }

    /**
     * Create new patient pinned note.
     * @param patientInternalId Patient internal id
     * @returns Patient pinned note
     */
    static create(patientInternalId: number): PatientPinnedNote {
        return new PatientPinnedNote({
            Id: 0,
            PatientId: patientInternalId,
            Type: PatientPinnedNoteType.DEMO,
            Note: ''
        });
    }
}
