<!-- dialog title -->
<h1 mat-dialog-title>
    <span>{{ 'DIALOGS.QUICK_LIST' | translate}}</span>
    <button data-ef-button="fee-close" mat-icon-button [attr.aria-label]="'BUTTONS.CLOSE'" mat-dialog-close="close">
        <mat-icon>close</mat-icon>
    </button>
</h1>

<!-- dialog content -->
<div mat-dialog-content>
    <div class="quick-list-content">
        <div class="d-flex justify-content-between pb-3" *ngIf="isLoaded">

            <!-- print buton -->
            <button data-ef-button="print-quick-list" mat-flat-button color="primary" (click)="printReport()">
                <mat-icon>print</mat-icon>
                <span>{{'BUTTONS.PRINT_QUICK_LIST'|translate}}</span>
            </button>

            <!-- view appointments only checkbox -->
            <mat-checkbox data-ef-checkbox="quick-list-appointments-only" [(ngModel)]="viewAppointmentsOnly"
                (change)="filterPatients()">
                {{'LABELS.VIEW_APPOINTMENTS_ONLY' | translate}}
            </mat-checkbox>

        </div>

        <!-- patients list -->
        <div class="pm-panel-table" [hidden]="!isLoaded">
            <table data-ef="quick-list-table" mat-table class="pm-table-default" [dataSource]="dataSource" matSort>

                <!-- Patient Name -->
                <ng-container matColumnDef="fullName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'TABLES.COLUMNS.PATIENT' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let item; let i=index">
                        <a [attr.data-ef-link]="'quick-list-patient-'+i" [href]="item.overviewLink|link">{{
                            item.fullName }}</a>
                    </td>
                </ng-container>

                <!-- Phone -->
                <ng-container matColumnDef="phone">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="d-none d-lg-table-cell">
                        {{ 'TABLES.COLUMNS.PHONE' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let item" class="d-none d-lg-table-cell">{{ item.phone }}</td>
                </ng-container>

                <!-- Appt. Time -->
                <ng-container matColumnDef="appointmentTime">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="d-none d-sm-table-cell">
                        {{ 'TABLES.COLUMNS.APPT_TIME' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let item" class="d-none d-sm-table-cell">{{ item.appointmentTime }}</td>
                </ng-container>

                <!-- Provider -->
                <ng-container matColumnDef="providerName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="d-none d-md-table-cell">
                        {{ 'TABLES.COLUMNS.PROVIDER' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let item" class="d-none d-md-table-cell">{{ item.providerName }}</td>
                </ng-container>

                <!-- Service -->
                <ng-container matColumnDef="serviceName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="d-none d-md-table-cell">
                        {{ 'TABLES.COLUMNS.SERVICE' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let item" class="d-none d-md-table-cell">{{ item.serviceName }}</td>
                </ng-container>

                <!-- Location / Status -->
                <ng-container matColumnDef="selectedLocationOrStatus">
                    <th mat-header-cell *matHeaderCellDef>{{ 'TABLES.COLUMNS.LOCATION_STATUS' | translate }}</th>
                    <td mat-cell *matCellDef="let item">
                        <mat-form-field class="cell-select w-100">
                            <mat-select [(ngModel)]="item.selectedLocationOrStatus" [disabled]="!item.isScheduled"
                                (selectionChange)="saveLocation(item)">
                                <mat-option [value]="location.locationId" *ngFor="let location of locations">
                                    {{ location.description }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </td>
                </ng-container>

                <!-- no data -->
                <tr class="mat-row no-data" role="row" *matNoDataRow>
                    <td class="p-3 text-center" role="cell" [attr.colspan]="displayedColumns.length">
                        {{ 'MESSAGES.QUICK_LIST_NO_DATA' | translate }}
                    </td>
                </tr>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>

        <!-- Paginator -->
        <mat-paginator [hidePageSize]="true" [pageSize]="10" [showFirstLastButtons]="true"
            [attr.aria-label]="'TABLES.PAGINATOR_LABEL'|translate" [hidden]="!isLoaded">
        </mat-paginator>

        <!-- template for loading spinner -->
        <div class="overlay" *ngIf="!isLoaded">
            <mat-spinner></mat-spinner>
        </div>

    </div>
</div>

<!-- dialog actions -->
<div mat-dialog-actions class="justify-content-end">

    <!-- close -->
    <button data-ef-button="quick-list-close" mat-stroked-button color="alt" mat-dialog-close="close"
        cdkFocusInitial>{{'BUTTONS.CLOSE'|translate}}</button>

</div>

<!-- quick list report -->
<iframe id="quickListReport" name="quickListReport" title="test" class="d-none"></iframe>
<pm-window windowName="quickListReport" (opened)="setupReportWindow($event)" *ngIf="showReport">
    <pm-quick-list-report [locations]="locations" [patients]="dataSource.data"></pm-quick-list-report>
    <pm-rendered (done)="print()"></pm-rendered>
</pm-window>