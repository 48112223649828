import { AfterViewInit, Component, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

/**
 * Use this copmponent to determine when elements are rendered to  the screen.. 
 */
@Component({
  selector: 'pm-rendered',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './rendered.component.html',
  styleUrls: ['./rendered.component.scss']
})
export class RenderedComponent implements AfterViewInit {
  /** Event emitter used to notify parent that rendering is complete. */
  @Output() done = new EventEmitter();

  /**
   * AfterViewInit lifecycle hook.
   */
  ngAfterViewInit(): void {
    this.done.emit();
  }
}