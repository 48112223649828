import { Component, OnInit, Input } from '@angular/core';
import { IValueAttributes } from '../model/eligibility-report-value-info.interface';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'pm-app-report-footer',
  templateUrl: './report-footer.component.html',
  standalone: true,
  imports: [
    CommonModule
  ]
})
export class EligibilityReportFooterComponent implements OnInit {
  @Input()
  /** Model we will be using in the template */
  public model: IValueAttributes[] = [];

  /**
   * Decoding the html of the input from the verbalization response so it can be displayed 
   */
  ngOnInit() : void {
    for (let i = 0; i < this.model.length; i ++) {
      this.model[i].Value = this.decodeHtml(this.model[i].Value as string);
    }
  }

  /**
   * @param html 
   * @returns string of sanitized html that can be used in the template
   */
  decodeHtml(html: string): string {
    const txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
  }
}
