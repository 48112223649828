import { IAddress } from '../interfaces/address';

/**
 * Address class.
 */
export class Address {
    /** Address id. */
    public addressId: number;
    /** Address line 1. */
    public address1: string;
    /** Address line 2. */
    public address2?: string | null;
    /** City */
    public city: string;
    /** Country. */
    public country: string | null;
    /** Country id. */
    public countryId: number;
    /** Is primary address. */
    public isPrimary: boolean;
    /** State. */
    public state: string;
    /** Zip code. */
    public zipCode: string;

    /**
     * Initializes instance of the Address class.
     * @param address Address data.
     */
    constructor(address: IAddress) {
        this.addressId = address.AddressId;
        this.address1 = address.Address1;
        this.address2 = address.Address2;
        this.city = address.City;
        this.country = address.Country;
        this.countryId = address.CountryId;
        this.isPrimary = address.IsPrimary;
        this.state = address.State;
        this.zipCode = address.ZipCode;
    }
}
