import { Injectable } from '@angular/core';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { ContentService } from '../services/content.service';

/**
 * Class for custom title strategy.
 */
@Injectable()
export class CustomTitleStrategy extends TitleStrategy {
    /**
     * Initializes instance of the CustomTitleStrategy class.
     * @param content External content service
     * @param translate Translation service
     */
    constructor(
        private content: ContentService,
        private translate: TranslateService 
    ) {
        super();
    }

    /**
     * Override for the default updateTitle method which translates the title and appends the application name.
     * @param routerState Router state
     */
    override async updateTitle(routerState: RouterStateSnapshot): Promise<void> {
        const title = this.buildTitle(routerState);
        const content = await this.content.appContent;
        if (title) {
            document.title = `${content?.appNameShort} - ${this.translate.instant(title)}`;
        } else {
            document.title = content?.appNameLong ?? '';
        }
    }
}