import { IPhone } from '../../interfaces/patient-pm/phone';

export class Phone {
    public phoneId: number;
    public phoneNumber: string;
    public extension: string;
    public phoneType: number;
    public isPrimary: boolean;

    constructor(phone: IPhone) {
        this.phoneId = phone.PhoneId;
        this.phoneNumber = phone.PhoneNumber;
        this.extension = phone.Extension;
        this.phoneType = phone.PhoneType;
        this.isPrimary = phone.IsPrimary;
    }
}