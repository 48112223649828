import { CatalogItemType } from './enums/catalog-item-type';

/**
 * Mapping for catalog item labels.
 */
export const CATALOG_ITEM_LABELS = new Map<CatalogItemType, string>([
    [CatalogItemType.EYEGLASS_FRAME, 'FRAME'],
    [CatalogItemType.ACCESSORY, 'ACCESSORY'],
    [CatalogItemType.CONTACT_LENS_STOCK, 'CONTACT_LENS_STOCK'],
    [CatalogItemType.EXAM, 'EXAM'],
    [CatalogItemType.PLAN, 'PLAN'],
    [CatalogItemType.EYEGLASS_LENS, 'EYEGLASS_LENS'],
    [CatalogItemType.LENS_BASE_TYPE, 'LENS_BASE_TYPE'],
    [CatalogItemType.MATERIAL_ADDON, 'MATERIAL_ADDON'],
    [CatalogItemType.COLOR_COAT_ADDON, 'COLOR_COAT_ADDON'],
    [CatalogItemType.STYLE_ADDON, 'STYLE_ADDON'],
    [CatalogItemType.TINT, 'TINT'],
    [CatalogItemType.EDGING, 'EDGING'],
    [CatalogItemType.COATING, 'COATING'],
    [CatalogItemType.MISC_EXTRA, 'MISC_EXTRA'],
    [CatalogItemType.CONTACT_LENS, 'CONTACT_LENS'],
    [CatalogItemType.GIFT_CARD, 'GIFT_CARD'],
    [CatalogItemType.SPECIAL_OPTION, 'SPECIAL_OPTION'],
    [CatalogItemType.REPAIR, 'REPAIR'],
    [CatalogItemType.SHIPPING, 'SHIPPING'],
    [CatalogItemType.MISC_FEE, 'MISC_FEE']
]);