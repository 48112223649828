<div class="header">
    <div class="header-row">
        <div class="header-row-column" >
            <img id="logo" *ngIf="!!header?.logo" [src]="header?.logo" class="logo {{this.initialLogoValue}}">
            <h1 id="title" *ngIf="!!header?.title">{{headerTitle}}</h1>
        </div>
        <div class="header-row-column right noprint">
            <a href="javascript:window.print()">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="vspicon-vsp_print bi bi-printer" viewBox="0 0 16 16">
                    <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1"/>
                    <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1"/>
                </svg>
                <!-- <i class="vspicon-vsp_print"></i> -->
                <span>Print</span>
            </a>
        </div>
    </div>
    <div class="header-row margin-top-25">
        <div class="header-row-column">
            <h3 id="patientEligibility-package-1-reportHeader-header">
                {{header?.Line1A?.Label}}{{header?.Line1A?.Value}}</h3>
        </div>
        <div class="header-row-column right">
                <span>{{header?.Line1B?.Label}}</span>
                <span>{{header?.Line1B?.Value}}</span>
        </div>
    </div>
    <div class="header-row margin-top-5">
        <div class="header-row-column field-pair">
            <span class="bold">{{header?.Line2A?.Label}}</span>
            <span>{{header?.Line2A?.Value}}</span>
        </div>
        <div class="header-row-column right">
                <span>{{header?.Line2B?.Label}}</span>
                <span>{{header?.Line2B?.Value}}</span>
        </div>
    </div>
</div>