import { ADDRESSES_MOCK_DATA } from '@pm/core/address';
import { PHONES_MOCK_DATA } from '@pm/core/phone';
import { IPatientDetail } from '../interfaces/patient-detail';

export function PatientsMockData(): IPatientDetail[] {
    return [
        {
            PatientInternalId: 12345,
            PatientUid: 'F9D8F148-6821-443F-B79B-921F1733D861',
            DateOfBirth: '8/19/1955',
            FirstName: 'Peter',
            LastExamDate: '2023-08-14T15:32:38',
            LastName: 'Gallagher',
            MiddleInitial: 'K',
            NickName: 'Pete',
            Sex: 'M',
            Addresses: ADDRESSES_MOCK_DATA,
            Phones: PHONES_MOCK_DATA
        },
        {
            PatientInternalId: 23456,
            PatientUid: '36340D4F-9A28-49E1-B41E-856F97B6F8AA',
            DateOfBirth: '04/05/1908',
            FirstName: 'Bette',
            LastName: 'Davis',
            Sex: 'F',
            Addresses: [ADDRESSES_MOCK_DATA[0]],
            Phones: [PHONES_MOCK_DATA[2]]
        },
        {
            PatientInternalId: 34567,
            PatientUid: '444E2751-1AAF-4CF0-8165-FF09A5C3BE27',
            DateOfBirth: '07/19/1976',
            FirstName: 'Benedict',
            LastName: 'Cumberbatch',
            MiddleInitial: 'T',
            Sex: 'M'
        }
    ];
}