import { CommonModule } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import { AppConfigurationService } from '@pm/core/configuration';
import { IReportHeader } from '../model/eligibility-report-header.interface';
import { HttpClientModule } from '@angular/common/http';

@Component({
  selector: 'pm-app-report-header',
  templateUrl: './report-header.component.html',
  standalone: true,
  imports: [
    CommonModule,
    HttpClientModule
  ]
})
export class EligibilityReportHeaderComponent implements OnInit {
  @Input()
  /** IReportHeader model for use in the template */
  public header!: IReportHeader | undefined;
  /** The value for our logo on the template */
  public initialLogoValue = '';
  /** The title for our header template */
  public headerTitle = '';
  /** The path for our logo */
  public assetPath = '';

  /**
   * @param appConfigService App Configuration Service
   */
  constructor(private appConfigService: AppConfigurationService) { 
    this.assetPath = 'images/logos/eligibility-report';
  }

  ngOnInit() : void {
    this.setLogoPath();
  }

  /**
   * Setting the correct title for our header and the value that we want for our logo
  */
  private setLogoPath() : void {
    let index = '';
    if (!!this.header && !!this.header.logo) {
      this.initialLogoValue = this.header.logo;
      if (this.initialLogoValue === 'Logo_Cigna') {
        index = 'Logo_Cigna.png';
      } else if (this.initialLogoValue === 'Logo_MetLife') {
        index = 'Logo_MetLife.png';
      } else if (this.initialLogoValue === 'Logo_VSP') {
        index = 'Logo_VSP.jpg';
      }
      this.header.logo = this.appConfigService.getAssetPath(`${this.assetPath}/${index}`);
    }
    if (!!this.header && !!this.header.title) {
      this.headerTitle = this.header.title;
    }
  }
}
