import { IPatientNotesSearchResults } from '../interfaces/patient-notes-search-results';

/**
 * PatientNotesSearchResults class
 */
export class PatientNotesSearchResults {
    /** Patient ID */
    patientId: number;
    /** Note ID */
    noteId: number;
    /** Resource ID */
    resourceId: number;
    /** Resource name */
    resource: string;
    /** Date of the note */
    date: string;
    /** Note content */
    note: string;
    /** Type of the note */
    noteType: string;
    /** Urgent status */
    isUrgent: boolean;
    /** Follow-up status */
    isFollowUp: boolean;
    /** Office ID */
    officeId: string;
    /** Type of note */
    typeNote: string;
    /** Entity ID */
    entityId: number;

    constructor(data: IPatientNotesSearchResults) {
        this.patientId = data.PatientId;
        this.noteId = data.NoteId;
        this.resourceId = data.ResourceId;
        this.resource = data.Resource;
        this.date = data.Date;
        this.note = data.Note;
        this.noteType = data.NoteType;
        this.isUrgent = data.IsUrgent;
        this.isFollowUp = data.IsFollowUp;
        this.officeId = data.OfficeId;
        this.typeNote = data.TypeNote;
        this.entityId = data.EntityId;
    }
}