import { IPatientDocumentIntegration } from '../interfaces/patient-document-integration';

export function PatientDocumentIntegrationMockData() : IPatientDocumentIntegration[] {
    return [
        {
            PatientDocumentIntegrationInternalId: 0,
            PatientDocumentInternalId: 0,
            PatientInternalId: 1,
        
            CompanyExternalId: '936',
            OfficeExternalId: '936',
        
            LastName: 'Connor',
            FirstName: 'Jhon',
            DateOfBirth: new Date(1994,4,5),
            Phone: '555-567-7890',
        
            AppointmentDate: new Date(2024,6,6),
            ExamDate: new Date(2024,4,26),
        
            Domain: 'Domain',
            Source: 'Source',
            CurrentStatus: 'NEEDSREVIEW',
            CurrentStatusReason: 'SOME REASON'
        },
        {
            PatientDocumentIntegrationInternalId: 0,
            PatientDocumentInternalId: 0,
            PatientInternalId: 2,
        
            CompanyExternalId: '936',
            OfficeExternalId: '936',
        
            LastName: 'Doe',
            FirstName: 'Jhon',
            DateOfBirth: new Date(1990,4,25),
            Phone: '555-567-1234',
        
            AppointmentDate: new Date(2024,6,7),
            ExamDate: new Date(2024,1,20),
        
            Domain: 'Domain',
            Source: 'Source',
            CurrentStatus: 'NEEDSREVIEW',
            CurrentStatusReason: 'SOME REASON'
        },
        {
            PatientDocumentIntegrationInternalId: 0,
            PatientDocumentInternalId: 0,
            PatientInternalId: 3,
        
            CompanyExternalId: '936',
            OfficeExternalId: '936',
        
            LastName: 'Doe',
            FirstName: 'Marie',
            DateOfBirth: new Date(1982,7,25),
            Phone: '555-567-4322',
        
            AppointmentDate: new Date(2024,6,8),
            ExamDate: new Date(2024,1,16),
        
            Domain: 'Domain',
            Source: 'Source',
            CurrentStatus: 'NEEDSREVIEW',
            CurrentStatusReason: 'SOME REASON'
        }
    ]
 }