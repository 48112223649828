import { IPatientOverview } from '../interfaces/patient-overview'; 

// Function to return mock data for IPatientOverview
export function PatientOverviewMockData(): IPatientOverview[] {
  return [
    {
      Id: 1,
      PatientUid: 'UID001',
      FullName: 'John Doe',
      Age: '30',
      DateOfBirth: '1993-01-01',
      Address: '123 Main St',
      CityStateZip: 'Anytown, USA 12345',
      PrimaryPhone: '555-1234',
      SecondaryPhone: '555-5678',
      InsuranceOne: 'Insurance A',
      InsuranceTwo: 'Insurance B',
      InsuranceThree: 'Insurance C',
      Email: 'john.doe@example.com',
      ProviderName: 'Dr. Smith',
      NickName: 'Johnny',
      OutstandingBalance: 100.0,
      PatientCredit: 50.0,
      PatientInsuranceBalance: 200.0,
      LastExamDate: '2023-01-01',
      ResponsibleParty: 'Jane Doe',
      IsHipaaSignatureOnFile: true,
      NoteResults: [],
      PatientDependents: [],
      PatientRecalls: [],
      PatientAppointments: [],
      PatientRxResults: [],
      PatientOrders: [],
      TotalCancellations: 2,
      TotalNoShows: 1,
      HomeOffice: 'Main Office',
      SignatureDate: '2023-01-01',
      FirstName: '',
      LastName: '',
      EnforceResponsiblePartyForMinor: false,
      IsReview: false
    },
    {
      Id: 2,
      PatientUid: 'UID002',
      FullName: 'Jane Smith',
      Age: '25',
      DateOfBirth: '1998-02-02',
      Address: '456 Elm St',
      CityStateZip: 'Othertown, USA 67890',
      PrimaryPhone: '555-8765',
      SecondaryPhone: '555-4321',
      InsuranceOne: 'Insurance D',
      InsuranceTwo: 'Insurance E',
      InsuranceThree: 'Insurance F',
      Email: 'jane.smith@example.com',
      ProviderName: 'Dr. Johnson',
      NickName: 'Janie',
      OutstandingBalance: 150.0,
      PatientCredit: 75.0,
      PatientInsuranceBalance: 250.0,
      LastExamDate: '2023-02-02',
      ResponsibleParty: 'John Smith',
      IsHipaaSignatureOnFile: true,
      NoteResults: [],
      PatientDependents: [],
      PatientRecalls: [],
      PatientAppointments: [],
      PatientRxResults: [],
      PatientOrders: [],
      TotalCancellations: 1,
      TotalNoShows: 0,
      HomeOffice: 'Branch Office',
      SignatureDate: '2023-02-02',
      FirstName: '',
      LastName: '',
      EnforceResponsiblePartyForMinor: false,
      IsReview: false
    },
  ];
}