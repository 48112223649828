<ng-template [ngIf]="patients" [ngIfElse]="spinner">
    <ng-template [ngIf]="patients.length>0" [ngIfElse]="empty">
        <form class="d-flex quick-list-search" (click)="$event.stopPropagation()"
            (keydown.enter)="$event.preventDefault()">
            <mat-form-field class="flex-fill">
                <input matInput [placeholder]="'PLACEHOLDERS.QUICK_LIST'|translate" autocomplete="off"
                    (input)="search($event)" />
                <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
        </form>
        <a [attr.data-ef-link]="'quicklist-'+i" mat-menu-item [href]="patient.overviewLink|link"
            class="menu-item-ellipsis" *ngFor="let patient of patients;let i=index">
            <strong>{{patient.lastName}}, {{patient.firstName}}</strong><span *ngIf="patient.phone">,
                {{patient.phone}}</span></a>
        <a data-ef-link="quicklist-view-all" mat-menu-item href="#" class=" d-none d-md-flex text-link"
            (click)="viewAll($event)">
            {{'BUTTONS.VIEW_ALL'|translate}} &gt;</a>
    </ng-template>
    <ng-template #empty>
        <div class="p-3">{{'MESSAGES.QUICK_LIST_EMPTY'|translate }}</div>
    </ng-template>
</ng-template>
<ng-template #spinner>
    <div class="d-flex justify-content-center p-3">
        <mat-spinner [diameter]="30"></mat-spinner>
    </div>
</ng-template>