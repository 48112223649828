import { Injectable, SecurityContext } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';

import { IAppConfig } from '../interfaces/app-config';
import { IAppWindow } from '../interfaces/app-window';
import { IWalkMePlayerAPI } from '../interfaces/walk-me-player-api';

/** Extened window DOM document. */
declare const window: IAppWindow;

/** WalkMe player API. */
// eslint-disable-next-line @typescript-eslint/naming-convention
declare const WalkMePlayerAPI: IWalkMePlayerAPI;

/**
 * Application configuration service.  Use this service instead of "window.config".
 */
@Injectable({
  providedIn: 'root'
})
export class AppConfigurationService {
  /** Anti-forgery token. */
  readonly antiForgeryToken = window.efAppInfo?.antiForgeryToken;
  /** Application version. */
  readonly appVersion = window.efAppInfo?.appVersion;
  /** Base path. */
  readonly clientBasePath = window.efAppInfo?.basePath;
  /** User daily roles. */
  readonly dailyRoles = window.efAppInfo?.dailyRoles;  // TODO: Move to user roles service?
  /** KPI/Commissions view. */
  readonly kpiView = window.efAppInfo?.kpiView ?? null;
  /** Is routing available? */
  readonly isRoutingAvailable: boolean = document.querySelector('pm-root') !== null;
  /** Url for support knowledge base. */
  readonly supportKnowledgeBaseUrl = window.efAppInfo?.supportKnowledgeBaseUrl;

  /** Application configuration from "window.config". */
  private config: IAppConfig;
  /** Default timeout (minutes). */
  private defaultTimeout = 122;
  /** Distribution path. */
  private distPath = window.efAppInfo?.distPath ?? window.efAppInfo?.basePath;

  /**
   * Initializes instance of the AppConfigurationService class.
   * @param http HTTP client
   * @param sanitizer DOM sanitizer service
   */
  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer
  ) {
    this.config = window?.config;
  }

  /** Base URL. */
  get baseUrl(): string {
    return this.config?.baseUrl || '/';
  }

  /** Company ID. */
  get companyExternalId(): string {
    return this.config?.companyId || '';
  }

  /** Frame data availability. */
  get frameDataAvailability(): string | undefined {
    return this.config?.frameDataAvailability;
  }

  /** Is the current page part of claims mangement? */
  get isClaimsManagement(): boolean {
    return this.urlPath.toLowerCase().includes('/billing');
  }

  /** Is EHR enabled? */
  get isEhrEnabled(): boolean {
    return this.config.isEhrEnabled?.toLowerCase() === 'true';
  }

  /** Is navigation enabled? */
  get isNavigationEnabled(): boolean {
    return !(this.urlPath.toLowerCase().includes('/common/changepassword')
      && /[?&]redirect=1\b/i.test(this.urlQuery));
  }

  /** Is premier partner? */
  get isPremier(): boolean {
    return this.config.isPremier === '1';
  }

  /** Is training mode enabled? */
  get isTrainingEnabled(): boolean {
    return this.config.trainingMode?.toLowerCase() === 'true';
  }

  /** Is go live pending? */
  get isTrainingPendingLive(): boolean {
    return this.config.trainingPendingLive?.toLowerCase() === 'true';
  }

  /** Offic ID. */
  get officeInternalId(): number {
    const officeInternalId = Number(this.config.officeId || '0');
    return Number.isNaN(officeInternalId) ? 0 : officeInternalId;
  }

  /** Office number. */
  get officeExternalId(): string | undefined {
    return this.config?.officeNumber;
  }

  /** Training company id. */
  get trainingCompanyId(): string {
    return this.config.trainingCompanyId || '';
  }

  /** Timeout (minutes). */
  get timeout(): number {
    const timeout = Number(this.config.timeOut || this.defaultTimeout);
    return (Number.isNaN(timeout) || timeout <= 0) ? this.defaultTimeout : timeout;
  }

  /** URL location pathname. */
  public get urlPath(): string {
    return location.pathname;
  }

  /** URL location query. */
  public get urlQuery(): string {
    return location.search;
  }

  /** User ID. */
  get userId(): number {
    const userId = Number(this.config?.userId || '0');
    return Number.isNaN(userId) ? 0 : userId;
  }

  /** Username. */
  get username(): string | undefined {
    return this.config.userName;
  }

  /**
   * Get full path of an asset.
   * @param relativePath
   * @returns {string}
   */
  getAssetPath(relativePath: string): string {
    return this.sanitizer.sanitize(SecurityContext.URL, `${this.distPath}assets/${relativePath}?v=${this.appVersion}`) as string;
  }

  /**
   * Get full path of a link.
   * @param relativePath
   * @returns {string}
   */
  getLinkPath(relativePath: string): string {
    return this.sanitizer.sanitize(SecurityContext.URL, `${this.baseUrl}${relativePath}`) as string;
  }

  /**
   * Navigate to non-Angular pages.  DO NOT use for Angular routes.
   * @param path relative path
   */
  navigateTo(relativePath: string): void {
    window.location.href = this.getLinkPath(relativePath);
  }

  /**
   * Toggle training mode.
   */
  toggleTraining(): void {
    if (this.isTrainingEnabled && this.isTrainingPendingLive) {
      return;
    }

    this.stashAccountContext();
    window.hideUnsavedChangesWarning = true;
    this.http.post('Login/SwitchAccounts', this.isTrainingEnabled).subscribe(() => location.reload());
  }

  /**
   * Walk Me Through launcher.
   */
  walkMeThroughLauncher(): void {
    WalkMePlayerAPI.toggleMenu();
  }

  /** 
   * Stash account context.
   */
  private stashAccountContext(): void {
    const context = location.href;
    const accountContext = sessionStorage.getItem('Account');
    let cmp: { [key: string]: string } = {};

    if (!accountContext) {
      cmp[this.companyExternalId] = context;
    } else {
      cmp = JSON.parse(accountContext) as { [key: string]: string };
      cmp[this.companyExternalId] = context;
    }

    sessionStorage.setItem('Account', JSON.stringify(cmp));
  }
}
