import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { TranslateModule } from '@ngx-translate/core';

import { LinkPipe } from '@pm/core';
import { AppConfigurationService, AppFeatureService } from '@pm/core/configuration';
import { IntegrationsService } from '@pm/core/integrations';
import { DialogHelper, NavLinkDirective } from '@pm/layout';
import { SecurityService } from '@pm/core/security';
import { MatDialog } from '@angular/material/dialog';
import { ChangeLocationDialog } from '../../dialogs/change-location/change-location.dialog';

/**
 * Component for the toolbar section of the header.
 */
@Component({
  selector: 'pm-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    LinkPipe,
    MatButtonModule,
    MatDividerModule,
    MatExpansionModule,
    MatIconModule,
    MatMenuModule,
    MatToolbarModule,
    NavLinkDirective,
    TranslateModule
  ]
})

/**
 * Toolbar component
 */
export class ToolbarComponent {

  /** Has day close role? */
  public hasDayCloseRole = this.config.dailyRoles.hasDayCloseRole;
  /** Has day open role? */
  public hasDayOpenRole = this.config.dailyRoles.hasDayOpenRole;
  /** Has payment types role? */
  public hasPaymentTypesRole = this.config.dailyRoles.hasPaymentTypesRole;
  /** Is EHR enabled? */
  public isEhrEnabled = this.config.isEhrEnabled;
  /** Is payment authentication API enabled? */
  public isPaymentAuthApi = this.feature.isPaymentAuthApi;
  /** Is till enabled? */
  public isTillEnabled = this.feature.isTillEnabled;
  /** Is training enabled? */
  public isTrainingEnabled = this.config.isTrainingEnabled;
  /** Is go live pending? */
  public isTrainingPendingLive = this.config.isTrainingPendingLive;
  /** KPI/Commissions view. */
  readonly kpiView = this.config.kpiView;
  /** Office external id. */
  public officeExternalId = this.config.officeExternalId;
  /** URL for support knowledge base. */
  public supportKnowledgeBaseUrl = this.config.supportKnowledgeBaseUrl;
  /** Training company id. */
  public trainingCompanyId = this.config.trainingCompanyId;
  /** Username. */
  public username = this.config.username;
  /** Helper class for dialogs. */
  private dialogHelper = new DialogHelper();

  /**
   * Initializes instance of the HeaderToolbarComponent class.
   * @param config Application configuration service
   * @param integrations Integrations service
   * @param feature Application feature service
   * @param security Security service
   */
  constructor(
    private config: AppConfigurationService,
    private integrations: IntegrationsService,
    private feature: AppFeatureService,
    private security: SecurityService,
    private dialog: MatDialog
  ) { }

  /** Label for the user menu. */
  get userMenuLabel(): string {
    return `${this.username} in ${this.officeExternalId}`;
  }

  /**
   * Launch EHR.
   * @param event Dom event.
   */
  launchEhr(event: Event): void {
    event.preventDefault();
    this.integrations.launchEhr();
  }

  /**
   * Logout.
   */
  logout(): void {
    this.security.logout();
  }

  /**
   * Toggle training mode.
   * @param event DOM event.
   */
  toggleTraining(event: Event): void {
    event.preventDefault();
    this.config.toggleTraining();
  }

  /**
   * Walk Me Through launcher.
   * @param event DOM event
   */
  walkMeThroughLauncher(event: Event): void {
    event.preventDefault();
    this.config.walkMeThroughLauncher();
  }

  /**
   * Open change location modal.
   * 
   * @param event  DOM event
   */
  openChangeLocationModal(event: Event) : void {
    event.preventDefault();
    const dialogRef = this.dialog.open(ChangeLocationDialog, this.dialogHelper.large(null, false));

    dialogRef.afterClosed().subscribe(result => {
        console.log(`Dialog result: ${result}`);
    });
  }
}
