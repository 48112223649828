import { EligibilityReport } from '../model/eligibility-report';
import { IEligibilityResponse, IProductPackage } from '@pm/core/insurances';
import { IVerbalization } from '@pm/core/insurances';
import { Injectable } from '@angular/core';
import {
  IEligibilityReportGroup,
  IEligibilityReportGroupLineItem
} from '../model/eligibility-report-group.interface';
import { PatientEligibilityReportConstants, IPlanDetailOrder } from '../constants/patient-eligibility-report.constants';
import { EligibilityReports } from '../model/eligibility-reports';
import { IReportHeader } from '../model/eligibility-report-header.interface';
import { IReportHeaderLine } from '../model/eligibility-report-header-line.interface';

@Injectable({
  providedIn: 'root'
})
export class ReportMapper {
  public mapVerbalizationsFromProductPackageToReport(verbalizationFromCHW: IVerbalization, reportForProductPackage: EligibilityReport): void {
    if (this.verbalizationItemHasFooterContent(verbalizationFromCHW, reportForProductPackage.prcReport)) {
      this.mapFooter(verbalizationFromCHW, reportForProductPackage);
    } else if (this.verbalizationItemHasHeaderContent(verbalizationFromCHW)) {
      this.mapHeader(verbalizationFromCHW, reportForProductPackage);
    } else if (this.verbalizationItemHasBenefitContent(verbalizationFromCHW)) {
      this.mapBenefit(verbalizationFromCHW, reportForProductPackage);
    } else if (this.verbalizationItemHasGroupLabelAndGroupName(verbalizationFromCHW)) {
      this.mapGroup(verbalizationFromCHW, reportForProductPackage);
    } 
  }

  /**
   * When the verbalization item is for the footer, this method will set the footer information for the report based on
   * what that verbalization item provides.
   *
   * @param verbalizationFromCHW IVerbalization - This is the verbalization item that is currently being iterated over.
   * @param reportContent IVerbalization - This is the current reportModel that is being constructed by iterating over
   * the many verbalizations.
   *
   */
  public mapFooter(verbalizationFromCHW: IVerbalization, reportForProductPackage: EligibilityReport): void {
    let footerValue = '';
    if (verbalizationFromCHW.Attributes) {
      footerValue = verbalizationFromCHW.Attributes[0].Value;
    }

    reportForProductPackage.footerAttribute = reportForProductPackage.footerAttribute || [];
    if (footerValue) {
      let valueContentAttribute;
      if ( footerValue.toLowerCase().indexOf('easyoptions') >= 0 ) {
        valueContentAttribute = {
          Class: 'easy-options',
          Value: footerValue,
        };
      } else {
        valueContentAttribute = {
          Class: '',
          Value: footerValue,
        };
      }
      reportForProductPackage.footerAttribute.push( valueContentAttribute );
    }
  }

  /**
   * When the verbalization item is for the header, this method will set the header information for the report based on
   * what that verbalization item provides.
   *
   * @param verbalizationFromCHW IVerbalization - This is the verbalization item that is currently being iterated over.
   * @param reportContent IVerbalization - This is the current reportModel that is being constructed by iterating over
   * the many verbalizations.
   *
   */
  public mapHeader(verbalizationFromCHW: IVerbalization, reportForProductPackage: EligibilityReport): void {
    reportForProductPackage.header = reportForProductPackage.header || undefined;
    if (!verbalizationFromCHW.Attributes) {
      reportForProductPackage.header.logo = verbalizationFromCHW.Name;
    } else if (verbalizationFromCHW.Name === 'report_Header') {
      reportForProductPackage.header.title = verbalizationFromCHW.Attributes[0].Value;
    } else if (!reportForProductPackage.prcReport) {
      if (verbalizationFromCHW.Name && reportForProductPackage.header) {
        if (verbalizationFromCHW.Name == 'Line1A') {
          reportForProductPackage.header.Line1A.Label = verbalizationFromCHW.Label ? verbalizationFromCHW.Label + ' ' : '';
          reportForProductPackage.header.Line1A.Value = verbalizationFromCHW.Attributes[0].Value;
        } else if (verbalizationFromCHW.Name == 'Line1B') {
          reportForProductPackage.header.Line1B.Label = verbalizationFromCHW.Label ? verbalizationFromCHW.Label + ' ' : '';
          reportForProductPackage.header.Line1B.Value = verbalizationFromCHW.Attributes[0].Value;
        } else if (verbalizationFromCHW.Name == 'Line2A') {
          reportForProductPackage.header.Line2A.Label = verbalizationFromCHW.Label ? verbalizationFromCHW.Label + ' ' : '';
          reportForProductPackage.header.Line2A.Value = verbalizationFromCHW.Attributes[0].Value;
        } else if (verbalizationFromCHW.Name == 'Line2B') {
          reportForProductPackage.header.Line2B.Label = verbalizationFromCHW.Label ? verbalizationFromCHW.Label + ' ' : '';
          reportForProductPackage.header.Line2B.Value = verbalizationFromCHW.Attributes[0].Value;
        } 
      }
    }
  }

  /**
   * When the verbalization item is for a benefit or memberGroup, this method will interrogate the verbalization item to
   * determine how to map the content of the verbalization to either the patient coverage (PRC) or patient identification
   * (non PRC) group, a line on that group, and a line item for the line of that group.
   *
   * @param verbalizationFromCHW IVerbalization - This is the verbalization item that is currently being iterated over.
   * @param reportContent IVerbalization - This is the current reportModel that is being constructed by iterating over
   * the many verbalizations.
   *
   */
  public mapBenefit(verbalizationFromCHW: IVerbalization, reportForProductPackage: EligibilityReport): void {
    reportForProductPackage.groups = reportForProductPackage.groups || [];
    const groupLabel = reportForProductPackage.prcReport ? verbalizationFromCHW.GroupLabel : 'Patient Identification';
    let group = reportForProductPackage.groups.find(g => g.Label === groupLabel);

    if (!group) {
      if (groupLabel) group = {Label: groupLabel, Lines: [], Class: ''};
      if (group) reportForProductPackage.groups.push(group);
    }
    if (reportForProductPackage.prcReport) {
      let line = group?.Lines.find(l => l.Label === 'Benefit');
      if (!line) {
        if (verbalizationFromCHW.Label) line = {Label: 'Benefit', Class: verbalizationFromCHW.Label.replace(/ /g, '_').replace(/:/g, ''), Items: []};
        if (group && line) group.Lines.push(line);
        if (line && verbalizationFromCHW.Attributes) line.Items.push({Value: verbalizationFromCHW.Attributes[0].Value, ValueItems: []});
      } else {
        line.Items.push({Label: verbalizationFromCHW.Label, ValueItems: []});
        if (verbalizationFromCHW.Attributes) line.Items.push({Value: verbalizationFromCHW.Attributes[0].Value, ValueItems: []});
      }
    } else {
      let line = group?.Lines[2];

        if (!line) {
        if (verbalizationFromCHW.Label) line = {Class: verbalizationFromCHW.Label.replace(/ /g, '_').replace(/:/g, ''), Items: []};
        if (group && line) group.Lines.push(line);
        if (line && verbalizationFromCHW.Label && verbalizationFromCHW.Attributes) line.Items.push({Label: verbalizationFromCHW.Label.concat(':'), Value: verbalizationFromCHW.Attributes[0].Value, ValueItems: []});
      } else {
        if (verbalizationFromCHW.Label && verbalizationFromCHW.Attributes) line.Items.push({Label: verbalizationFromCHW.Label.concat(':'), Value: verbalizationFromCHW.Attributes[0].Value, ValueItems: []});
      }
    }
  }

  /**
   * The verbalization item is interrogated to determine how to map the content of the verbalization to a group,
   * a line on the group, and a line item for the line of a group.
   *
   * @param verbalizationFromCHW IVerbalization - This is the verbalization item that is currently being iterated over.
   * @param reportContent IVerbalization - This is the current reportModel that is being constructed by iterating over
   * the many verbalizations.
   *
   */
  public mapGroup(verbalizationFromCHW: IVerbalization, reportContent: EligibilityReport): void {
    if (this.verbalizedLineItemContentIsOnExclusionList(verbalizationFromCHW)) {
      return;
    }

    reportContent.groups = reportContent.groups || [];
    let lineItemContent: IEligibilityReportGroupLineItem = { ValueItems: [] };
    const lineItemContents: IEligibilityReportGroupLineItem[] = [];
    if (verbalizationFromCHW.Attributes && verbalizationFromCHW.Attributes.length === 1) {
      if (reportContent.prcReport) {
        lineItemContent = {
          Label: '',
          Value: verbalizationFromCHW.Attributes[0].Value,
          ValueItems: []
        };
      } else {
        const attributeValue = verbalizationFromCHW.Attributes[0].Value;
        let valueContentClass = '';
        if (attributeValue.toLowerCase().indexOf('easyoptions') >= 0 ) {
          valueContentClass = 'easy-options';
        }
        const valueContentLineItem = {
          class : valueContentClass,
          Value : attributeValue
        };
        lineItemContent = {
          Label: verbalizationFromCHW.Attributes[0].Type === 'SUB_LABEL' ? verbalizationFromCHW.Label : '',
          Value: attributeValue,
          ValueItems: []
        };
        if (lineItemContent.ValueItems) lineItemContent.ValueItems.push(valueContentLineItem);
      }
    } else if (verbalizationFromCHW.Attributes && verbalizationFromCHW.Attributes.length > 1) {
      const lineValues = verbalizationFromCHW.Attributes.filter(attribute => attribute.Type !== 'SUB_LABEL');
      if (verbalizationFromCHW.GroupLabel === 'Contact Lenses' && verbalizationFromCHW.Label === 'Coverage:') {
        let i = 0;
        let j = 0;
        verbalizationFromCHW.Attributes.forEach((attribute) => {
          let lineValue  = '';
          if (j < lineValues.length) {
            lineValue = lineValues[j].Value;
          }
          if (i % 2 === 0 && verbalizationFromCHW.Attributes && i <= verbalizationFromCHW.Attributes.length) {
            lineItemContent = {
              Label: attribute.Value,
              Class: 'sub-Label',
              Value: lineValue,
              ValueItems: []
            };
            lineItemContents.push(lineItemContent);
            j++;
          }
          i++;
        });
      } else if (verbalizationFromCHW.ContentType === 'EasyOptions_Summary') {
        lineItemContent = {
          Label: verbalizationFromCHW.Attributes.filter(attrib => attrib.Type === 'SUB_LABEL').length > 0
            ? verbalizationFromCHW.Attributes.filter(attrib => attrib.Type === 'SUB_LABEL').map(element => element.Value).join('<br>')
            : '',
          Class: 'easyOptionLabel',
          Values: verbalizationFromCHW.Attributes.filter(attrib => attrib.Type === 'CONTENT').map(element => element.Value),
          ValueItems: []
        };
      } else if (verbalizationFromCHW.GroupLabel === 'Lens Enhancement Details' && reportContent.prcReport) {
        lineValues.forEach((attribute) => {
          lineItemContent = {
            Label: '',
            Class: 'sub-Label',
            Value: attribute.Value,
            ValueItems: []
          };
          lineItemContents.push(lineItemContent);
        });
      } else if (verbalizationFromCHW.GroupLabel === 'Plan Details' && reportContent.prcReport) {
        lineItemContent = {
          Label: verbalizationFromCHW.Attributes[0]?.Value,
          Value: lineValues.map(element => element.Value).join(' '),
          ValueItems: []
        };
      } else {
        const elementValue = lineValues.map(element => element.Value).join(' ');
        lineItemContent = {
          Label: verbalizationFromCHW.Attributes[0]?.Value,
          Class: 'sub-Label',
          Value: elementValue,
          ValueItems: []
        };
      }
    }

    let group = reportContent.groups.find(g => g.Label === verbalizationFromCHW.GroupLabel);
    if (!group && verbalizationFromCHW.GroupLabel) {
      group = {
        Label: verbalizationFromCHW.GroupLabel,
        Class: verbalizationFromCHW.GroupLabel.replace(/ /g, '_').replace(/:/g, ''),
        Lines: []
      };
      reportContent.groups.push(group);
    }

    if (group && verbalizationFromCHW.Attributes && verbalizationFromCHW.Name && verbalizationFromCHW.Name.toLowerCase().indexOf('footnote') > -1) {
      group.Footnote = verbalizationFromCHW.Attributes[0].Value;
      let footnoteAttribute;
      if ( group.Footnote.toLowerCase().indexOf('easyoptions') >= 0 ) {
        footnoteAttribute = {
          Class: 'easy-options',
          Value: group.Footnote,
        };
        group.FootnoteAttributes = footnoteAttribute;
      } else {
        footnoteAttribute = {
          Class: '',
          Value: group.Footnote,
        };
        group.FootnoteAttributes = footnoteAttribute;
      }
      return;
    }

    let line = group?.Lines.find(l => l.Label === (verbalizationFromCHW.Label || lineItemContent.Label));
    if (!line) {
      if (verbalizationFromCHW.ContentType === 'EasyOptions_Summary') {
        line = {
          Label: (verbalizationFromCHW.Label || lineItemContent.Label),
          Class: 'Easy_Options_Summary',
          InnerTag: 'easyOptionsHeader',
          Items: []
        };
      } else {
        if (lineItemContent.Label) {
          line = {
            Label: (verbalizationFromCHW.Label || lineItemContent.Label),
            Class: (verbalizationFromCHW.Label || lineItemContent.Label).replace(/ /g, '_').replace(/:/g, ''),
            Items: []
          };
        }
        else {
          line = {
            Label: (verbalizationFromCHW.Label || lineItemContent.Label),
            Class: '',
            Items: []
          };
        }

      }
      group?.Lines.push(line);
    }

    const subLabelItem =
      line.Items.find(item => item.Class === 'sub-Label' && item.Label === lineItemContent.Label && item.Class === lineItemContent.Class);
    if (subLabelItem) {
      let valueContentClass = '';
      const lineItemContentValue = lineItemContent.Value;
      if (lineItemContentValue && lineItemContentValue.toLowerCase().indexOf('easyoptions') >= 0 ) {
        valueContentClass = 'second-easy-option';
      }
      const valueContentSubItem = {
        class : '',
        Value : subLabelItem.Value
      };

      const valueContentLineItem = {
        class : valueContentClass,
        Value : lineItemContentValue
      };

      if (subLabelItem.ValueItems && !subLabelItem.Values) {
        subLabelItem.Values = [];
        subLabelItem.ValueItems.push(valueContentSubItem);
      }
      subLabelItem.Value = undefined;
      if (subLabelItem.ValueItems) subLabelItem.ValueItems.push(valueContentLineItem);
    } else {
      if (verbalizationFromCHW.Attributes) {
        verbalizationFromCHW.Attributes.length > 1 &&
            ((verbalizationFromCHW.GroupLabel === 'Contact Lenses' && verbalizationFromCHW.Label === 'Coverage:') ||
              (verbalizationFromCHW.GroupLabel === 'Lens Enhancement Details' && reportContent.prcReport)) ?
        line.Items.push(...lineItemContents) : line.Items.push(lineItemContent);
      }
    }
  }

  public isWalmartVisionSavingsPass(productPackage: IProductPackage): boolean {
    return productPackage?.ProductPackageName === 'VSPVisionSavingsPass' && productPackage.NetworkId === 'WALM';
  }

  /**
   * Patient identification mapper. In CHW flow, RPE response doesn't have the verbalizations for patient information.
   * Returning an empty body here.
   */
  public piMapper(patient: IEligibilityResponse, packageNumber: number): IEligibilityReportGroup {
      return {
        Label: 'Patient Identification',
        Class: 'Patient_Identification',
        Lines: [
          {
            Items: [
              {
                Label: 'Patient Name:',
                Value: patient.Membership.FirstName + ' ' + patient.Membership.LastName,
              },
              {
                Label: 'Authorization #:',
                Value: patient?.Packages[packageNumber].Retail.VsrNumber
              }
            ]
          },
          {
            Items: [
              {
                Label: 'Relationship:',
                Value: patient.Membership.RelationToSubscriber,
              },
              {
                Label: 'Birth Date:',
                Value: patient.Membership.RetailMembership.DateOfBirth,
              }
            ]
          }
        ]
      } as IEligibilityReportGroup;
  }

  public mapReports(currentPatient: IEligibilityResponse, prcReport: boolean): EligibilityReports {
    const productPackagesFromCHW: IProductPackage[] = currentPatient.Packages;
    let packageNumber = 0;

    const mappedReports: EligibilityReports = {
      packages: productPackagesFromCHW?.map((productPackage: IProductPackage) => {
        const patientInformationReportGroup: IEligibilityReportGroup = this.piMapper(currentPatient, packageNumber);  
        packageNumber ++;
        const reportHeader = {
          logo: '',
          title: '',
          Line1A: {
            Label: '',
            Value: ''
          } as IReportHeaderLine,
          Line1B: {
            Label: '',
            Value: ''
          } as IReportHeaderLine,
          Line2A: {
            Label: '',
            Value: ''
          } as IReportHeaderLine,
          Line2B: {
            Label: '',
            Value: ''
          } as IReportHeaderLine,
        } as IReportHeader;
        const reportForProductPackage: EligibilityReport = {
          groups: [patientInformationReportGroup],
          prcReport: prcReport,
          isWalmartVisionSavingsPass: this.isWalmartVisionSavingsPass(productPackage),
          footerAttribute: [],
          header: reportHeader
        };
        // TO DO
        if (productPackage.Retail.Verbalizations) {
          productPackage.Retail.Verbalizations.map((verbalizationFromCHW: IVerbalization) => {
            this.mapVerbalizationsFromProductPackageToReport(verbalizationFromCHW, reportForProductPackage);
          });
        }
        return reportForProductPackage;
      }),
      prcReport: prcReport
    };

    this.sortPlanDetails(mappedReports);
    mappedReports.packages?.forEach(prod => prod.groups?.forEach(group => {
      group.Lines.forEach(line => {
        if (line.Items.filter(item => item.Class === 'sub-Label').length > 0 && !line.InnerTag) {
          line.IsSubLine = true;
        }
      });
    }));
    return mappedReports;
  }

  private verbalizationItemHasFooterContent(verbalizationFromCHW: IVerbalization, isPrcReport: boolean): boolean {
    return (verbalizationFromCHW.GroupName === 'additionalInformation' && !isPrcReport);
  }

  private verbalizationItemHasHeaderContent(verbalizationFromCHW: IVerbalization): boolean {
    return (verbalizationFromCHW.GroupName === 'reportHeader');
  }

  private verbalizationItemHasBenefitContent(verbalizationFromCHW: IVerbalization): boolean {
    return (verbalizationFromCHW.Name === 'benefit' || verbalizationFromCHW.Name === 'membergroup');
  }

  private verbalizationItemHasGroupLabelAndGroupName(verbalizationFromCHW: IVerbalization): boolean {
    if (verbalizationFromCHW.GroupLabel && verbalizationFromCHW.GroupName) {
      return true;
    }
    return false;
  }

  /**
   * This method checks if the verbalization item that is being iterated over while building the report object used for
   * displaying the patient eligibility report contains data that we want to exclude from being displayed on the patient
   * eligibility report.
   *
   * @param verbalizationFromCHW IVerbalization - This is the current verbalization item that is being iterated over
   * when generating the report object.
   *
   */
  private verbalizedLineItemContentIsOnExclusionList(verbalizationFromCHW: IVerbalization): boolean {
    if (verbalizationFromCHW.Attributes) {
      return verbalizationFromCHW.Attributes.length > 0 &&
      verbalizationFromCHW.Attributes[0].Type === 'SUB_LABEL' &&
      PatientEligibilityReportConstants.excludeVerbalizationBySubLabel.includes(verbalizationFromCHW.Attributes[0].Value as string);
    }
    return false;
  }

  /**
   * This method sorts the Lines of information on the plan details group for each report to be displayed on the patient
   * eligibility report.
   *
   * @param mappedReports Reports - This is the already mapped reports object that needs to have each report's (package)
   * group interrogated to see if it is for plan details, then sort the Lines for the plan details group.
   *
   */
  private sortPlanDetails(mappedReports: EligibilityReports): void {
    mappedReports.packages?.map( (mappedReport: EligibilityReport) => {
      mappedReport.groups.map( (group: IEligibilityReportGroup) => {
        if (group.Label === 'Plan Details') {
          group.Lines.sort((firstElement, secondElement) => {
            let firstElementIndex = 0;
            let secondElementIndex = 0;
            if (firstElement.Label) firstElementIndex = this.getPlanDetailLineOrderIndex(firstElement.Label.toLowerCase());
            if (secondElement.Label) secondElementIndex = this.getPlanDetailLineOrderIndex(secondElement.Label.toLowerCase());
            if (firstElementIndex < secondElementIndex) {
              return -1;
            }
            if (firstElementIndex > secondElementIndex) {
              return 1;
            }
            return 0;
          });
        }
      });
    });
  }

  /**
   * This method returns the index that the Label of a plan details verbalization item matched to based on the plan
   * details line order specified in the constants. The plan details line order is an array of objects that specify the
   * sub string that should be included in the verbalization Label, and the option to include a sub string that should be
   * excluded from the match on the verbalization Label. The lower the number the index returned, the higher in the report
   * that verbalization should display. If there is no match on the verbalization Label against any of the entries in the
   * plan detail line order, then the largest number (the length of the plan detail line order array) is used as the index,
   * which forces the verbalization to the bottom of the report.
   *
   * @param elementLabel string - The Label of the plan detail Type verbalization item.
   *
   * @return number - The decided Value in the plan detail line order hierarchy that will be used to determine where to
   * sort the plan detail verbalization.
   *
   */
  private getPlanDetailLineOrderIndex(elementLabel: string): number {
    const planDetailsLineOrder: IPlanDetailOrder[] = PatientEligibilityReportConstants.planDetailsLineOrder;
    let planDetailLineOrderIndex: number = planDetailsLineOrder.length;
    for (let index = 0; index < planDetailsLineOrder.length; index++) {
      const planDetailLineAtIndex: IPlanDetailOrder = planDetailsLineOrder[index];
      if (elementLabel.includes(planDetailLineAtIndex.includes) &&
        (planDetailLineAtIndex.excludes && (!(planDetailLineAtIndex.excludes) || !elementLabel.includes(planDetailLineAtIndex.excludes)))) {
        planDetailLineOrderIndex = index;
        break;
      }
    }
    return planDetailLineOrderIndex;
  }

}
