<div class="align-items-center d-flex justify-content-between px-3">

    <!-- quick links -->
    <div class="d-none d-md-flex fw-bold justify-content-center justify-content-md-start w-25">
        <pm-footer-quicklinks *ngIf="isNavigationEnabled"></pm-footer-quicklinks>
    </div>

    <!-- copyright -->
    <div class="flex-fill text-center" [innerHTML]="'FOOTER.COPYRIGHT_COMPACT'|translate:{year}"></div>

    <!-- app version -->
    <div class="d-none d-md-flex justify-content-center justify-content-md-end w-25">{{'FOOTER.VERSION'|translate}}: {{version}}
    </div>

</div>