<div class="justify-content-between row d-none d-xl-flex flex-nowrap" *ngIf="patientIdValid">
    <div class="col-auto flex d-flex justify-content-between align-items-center flex-column">
        <div>{{ "PATIENT_OVERVIEW.AGE" | translate }}</div>
        <div class="text-nowrap width-auto">{{ patientProfile.age }}</div>
    </div>
    <div class="col-auto flex d-flex justify-content-between align-items-start flex-column" [matTooltip]="patientProfile.insuranceOne">
        <div>{{ "PATIENT_OVERVIEW.INSURANCE_ONE" | translate }}</div>
        <div class="text-nowrap width-auto">{{ patientInsurance }}</div>
    </div>
    <div class="col-auto flex d-flex justify-content-between align-items-start flex-column" [matTooltip]="patientProfile.provider">
        <div>{{ "PATIENT_OVERVIEW.PROVIDER" | translate }}</div>
        <div class="text-nowrap width-auto">{{ patientProvider }}</div>
    </div>
    <div class="col-auto flex d-flex justify-content-between align-items-start flex-column">
        <a [href]="'Patient/MakePayment?id=' + patientId | link">
            <div>{{ "PATIENT_OVERVIEW.BALANCE" | translate }}</div>
        </a>
        <div [ngClass]="patientProfile.balance !== '--' ? 'text-danger' : 'text-success'" class="text-nowrap width-auto">{{ patientProfile.balance === '--' ? '--' : (+patientProfile.balance | currency) }}</div>
    </div>
    <div class="col-auto flex d-flex justify-content-between align-items-start flex-column">
        <a [href]="'Patient/MaterialOrdersMvc?id=' + patientId | link">
            <div>{{ "PATIENT_OVERVIEW.CREDIT" | translate }}</div>
        </a>
        <div [ngClass]="{'text-success': patientProfile.credit !== '--'}" class="text-nowrap width-auto">{{ patientProfile.credit === '--' ? '--' : (+patientProfile.credit | currency) }}</div>
    </div>
</div>
<div class="d-flex d-xl-none" align="right" #smallComponent *ngIf="patientIdValid">
    <button mat-button [matMenuTriggerFor]="menu" class="patientInfoBtnSm d-flex align-items-center flex-column ms-auto"><mat-icon>account_circle</mat-icon></button>
    <mat-menu #menu="matMenu">
        <button mat-menu-item class="col-auto flex d-flex justify-content-between align-items-start flex-column">
            <div class="font-sm">{{ "PATIENT_OVERVIEW.AGE" | translate }}</div>
            <div>{{ patientProfile.age }}</div>
        </button>
        <button mat-menu-item class="col-auto flex d-flex justify-content-between align-items-start flex-column">
            <div class="font-sm">{{ "PATIENT_OVERVIEW.INSURANCE_ONE" | translate }}</div>
            <div>{{ patientInsurance }}</div>
        </button>
        <button mat-menu-item class="col-auto flex d-flex justify-content-between align-items-start flex-column">
            <div class="font-sm">{{ "PATIENT_OVERVIEW.PROVIDER" | translate }}</div>
            <div>{{ patientProvider }}</div>
        </button>
        <a [href]="'Patient/MakePayment?id=' + patientId | link">
            <button mat-menu-item class="col-auto flex d-flex justify-content-between align-items-start flex-column">
                <div class="font-sm">{{ "PATIENT_OVERVIEW.BALANCE" | translate }}</div>
                <div [ngClass]="{'text-danger': patientProfile.balance !== '--'}">{{ patientProfile.balance === '--' ? '--' : (+patientProfile.balance | currency) }}</div>
            </button>
        </a>
        <a [href]="'Patient/MaterialOrdersMvc?id=' + patientId | link">
            <button mat-menu-item class="col-auto flex d-flex justify-content-between align-items-start flex-column">
                <div class="font-sm">{{ "PATIENT_OVERVIEW.CREDIT" | translate }}</div>
                <div [ngClass]="{'text-success': patientProfile.credit !== '--'}">{{ patientProfile.credit === '--' ? '--' : (+patientProfile.credit | currency) }}</div>
            </button>
        </a>
    </mat-menu>
</div>