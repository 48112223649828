<pm-footer-quicklinks class="d-block pb-1 px-3" *ngIf="isNavigationEnabled"></pm-footer-quicklinks>
<div class="align-items-center d-flex flex-column flex-md-row gap-2 gap-md-0 justify-content-md-between py-2 px-3">

    <!-- training mode -->
    <div class="d-flex fw-bold justify-content-center justify-content-md-start side text-ellipsis">
        <ng-template [ngIf]="trainingCompanyId.length>0 && isTrainingEnabled" [ngIfElse]="live">
            <a data-ef="footer-training-toggle" href="#" (click)="toggleTraining($event)"
                *ngIf="!isTrainingPendingLive; else pendingLive">
                {{'TRAINING.TRAINING_TEXT'|translate}}<span class="d-none d-lg-inline-block">:
                    {{'TRAINING.LIVE_SWITCH'|translate}}</span></a>
            <ng-template #pendingLive>{{'TRAINING.TRAINING_TEXT'|translate}}</ng-template>
        </ng-template>
        <ng-template #live>{{'TRAINING.LIVE_TEXT'|translate}}</ng-template>
    </div>

    <!-- copyright -->
    <div class="flex-fill text-center text-nowrap" [innerHTML]="'FOOTER.COPYRIGHT'|translate:{year}"></div>

    <!-- app version -->
    <div class="d-flex justify-content-center justify-content-md-end side text-nowrap">{{'FOOTER.VERSION'|translate}}:
        {{version}}
    </div>

</div>