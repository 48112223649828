import { TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AppConfigurationService } from '../services/app-configuration.service';

/**
 * Custom loader for externalizing text, images, links, etc.
 */
export class CustomLoader implements TranslateLoader {
    /**
     * Initializes instance if the CustomLoader class.
     * @param http HTTP client
     * @param config Application configuration service
     */
    constructor(
        private http: HttpClient,
        private config: AppConfigurationService
    ) { }

    /**
     * Get translations for the specified language.
     * @param langCode
     * @returns {any}
     */
    getTranslation(langCode: string): Observable<object> {
        return this.http.get(this.config.getAssetPath(`i18n/${langCode}.json`));
    }
}