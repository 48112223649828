<div class="d-flex gap-3 m-3">
    <div>
        <mat-icon aria-label="Error" class="d-block"
            *ngIf="error.status!==419">error</mat-icon>
        <mat-icon aria-label="Warning" class="d-block"
            *ngIf="error.status===419">warning</mat-icon>
    </div>

    <!-- client side or network error -->
    <div *ngIf="error.status===0; else serverErrors" class="d-flex align-items-center">
        <div class="d-flex gap-3">
            <span>Error:</span>
            <span class="text-break">{{ 'ERRORS.SERVICE_UNAVAILABLE' | translate }}</span>
        </div>
    </div>

    <!-- server errors -->
    <ng-template #serverErrors>
        <div [ngSwitch]="error.status">

            <div *ngSwitchCase="400" [innerHTML]="'ERRORS.API_BAD_REQUEST' | translate"></div>
            <div *ngSwitchCase="401" [innerHTML]="'ERRORS.API_SECURITY' | translate"></div>
            <div *ngSwitchCase="403" [innerHTML]="'ERRORS.API_SECURITY' | translate"></div>
            <div *ngSwitchCase="404" [innerHTML]="'ERRORS.API_BAD_REQUEST' | translate"></div>
            <div *ngSwitchCase="419" [innerHTML]="'ERRORS.API_TIMEOUT' | translate"></div>
            <div *ngSwitchCase="500" [innerHTML]="'ERRORS.API_BAD_REQUEST' | translate"></div>
            <div *ngSwitchDefault class="d-flex gap-3">
                <span>Error:</span>
                <span>{{ error.message }}</span>
            </div>
        </div>
    </ng-template>
</div>
