import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { IEnvironmentConfig } from '@pm/core';
import { AlertComponent, IAlert } from '@pm/core/alerts';
import { CompaniesService } from '@pm/core/companies';
import { AppConfigurationService } from '@pm/core/configuration';

/**
 * Component for frame data availability alert message.
 */
@Component({
  selector: 'pm-frame-data-availability-alert',
  templateUrl: './frame-data-availability-alert.component.html',
  styleUrls: ['./frame-data-availability-alert.component.scss'],
  standalone: true,
  imports: [
    AlertComponent,
    CommonModule,
    TranslateModule
  ]
})
export class FrameDataAvailabilityAlertComponent implements OnInit {
  /** Frame data availability alert with default values. */
  public alert: IAlert = { type: 'Error' };
  /** Frame data availability message type. */
  public messageType: 'Standard' | 'Jobson' | undefined;
  /** Need more info. ??? */
  public res: string[] = [];
  /** Frame data availability message. (jobson) */
  public messageJobson?: string | null;

  /** Link used in frame data availability messages. */
  private link = this.config.getLinkPath(this.environment.links.additionalIntegrations);
  /** Key used for session storage. */
  private storageKey = 'FrameDataMessage';

  /** Alert options. */
  @Input() set options(alert: IAlert) {
    Object.assign(this.alert, alert);
  }

  /** Template used to render content outside of selector. */
  @ViewChild('template') template!: TemplateRef<never>;

  /**
   * Initializes instance of the FrameDataAvailabilityAlertComponent class.
   * @param changeDetectorRef Change detector
   * @param company Company service
   * @param config Application configuration service
   * @param environment Environment config
   */
  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private companies: CompaniesService,
    private config: AppConfigurationService,
    @Inject('environment') private environment: IEnvironmentConfig
  ) { }

  /**
   * OnInit lifecycle hook.
   * - Initialize frame data availability message
   */
  ngOnInit(): void {
    this.initFrameDataAvailabilityMessage(this.config.frameDataAvailability);
  }

  /**
   *  Initialize frame data availability message
   * @param frameDataAvailability ??? Need more info
   */
  initFrameDataAvailabilityMessage(frameDataAvailability: string | undefined): void {
    if (frameDataAvailability?.length) {
      this.res = frameDataAvailability.split('-');
      this.messageJobson = sessionStorage.getItem(this.storageKey);
      this.messageType = this.messageJobson ? 'Jobson' : 'Standard';
      this.changeDetectorRef.detectChanges();
    }
  }

  /**
   * Navigate to office integrations.
   * @param event DOM event
   */
  async gotoOffice(event: Event): Promise<void> {
    event.preventDefault();
    await this.companies.loadAdminOfficeContext();
    window.location.href = this.link;
  }
}
