import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { AlertComponent, IAlert } from '@pm/core/alerts';
import { AppConfigurationService } from '@pm/core/configuration';
import { FulfillmentService } from '@pm/core/fulfillment';
import { RouteDirective } from '@pm/layout';

/**
 * Component for frame board management alert message.
 */
@Component({
  selector: 'pm-frame-board-management-alert',
  templateUrl: './frame-board-management-alert.component.html',
  styleUrls: ['./frame-board-management-alert.component.scss'],
  standalone: true,
  imports: [
    AlertComponent,
    CommonModule,
    RouteDirective,
    TranslateModule
  ]
})
export class FrameBoardManagementAlertComponent implements OnInit {
  /** Alert options with default values. */
  public alert: IAlert = { type: 'Error' };
  /** Failed frame order count. */
  public failedOrderCount = 0;

  /** Alert options. */
  @Input() set options(value: IAlert) {
    Object.assign(this.alert, value);
  }
  get options(): IAlert {
    return this.alert;
  }

  /** Template used to render content outside of selector. */
  @ViewChild('template') template!: TemplateRef<never>;

  /**
   * Initializes instance of FrameBoardManagementAlertComponent class.
   * @param config Application configuration sertvice
   * @param changeDetectorRef Change detector
   * @param retail Retail service
   */
  constructor(
    private config: AppConfigurationService,
    private changeDetectorRef: ChangeDetectorRef,
    private fulfillment: FulfillmentService
  ) { }

  /**
   * OnInit lifecycle hook.
   * - get failed frame order count.
   */
  ngOnInit(): void {
    this.getFailedOrderCount();
  }

  /**
   * Get failed frame order count.
   */
  async getFailedOrderCount(): Promise<void> {
    const failedFrameOrders = await this.fulfillment.getFailedFrameOrders();
    this.failedOrderCount = failedFrameOrders.TotalAvailable;
    this.changeDetectorRef.markForCheck();
    this.changeDetectorRef.detectChanges();
  }
}
