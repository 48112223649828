<ng-template #template *ngIf="alert.message">
    <!-- Daily close alert with day close role -->
    <pm-alert data-ef="store-operations-alert-with-role" [options]="alert" (click)="gotoDailyClosing($event)"
        *ngIf="hasDayCloseRole">
    </pm-alert>

    <!-- Daily close alert without day close role -->
    <pm-alert data-ef="store-operations-alert" [options]="alert" *ngIf="!hasDayCloseRole">
    </pm-alert>
</ng-template>

<ng-content *ngTemplateOutlet="alert.isGrouped ? null : template"></ng-content>