import { Pipe, PipeTransform } from '@angular/core';

import { AppConfigurationService } from '@pm/core/configuration';

/**
 * Pipe used for generating the full path to an asset.
 */
@Pipe({
  name: 'asset',
  standalone: true
})
export class AssetPipe implements PipeTransform {
  /**
   * Initializes an instance of the AssetPipe class.
   * @param config Application configuration service
   */
  constructor(private config: AppConfigurationService) {}

  /** 
   * Generate the full asset path. 
   * @param relativePath Relative path for the asset.
   */
  transform(relativePath: string): string {
    return this.config.getAssetPath(relativePath);
  }
}
