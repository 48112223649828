import { IFrame } from '../interfaces/frame';
import { CatalogItem } from './catalog-item';

/**
 * Frame class.
 */
export class Frame extends CatalogItem {
    /** Bridge measurement. */
    public bridge: number | null;
    /** Collection. */
    public collection: string;
    /** Color. */
    public color: string | null;
    /** Eye measurement. */
    public eye: number | null
    /** Temple measurement. */
    public temple: number | null
    /** Style. */
    public style: string | null;

    /**
     * Initializes instance of the Frame class.
     * @param frame Frame data.
     */
    constructor(frame: IFrame) {
        super(frame);
        this.onHandQty = frame.OnHand;
        this.price = this.adjustedPrice = this.convertPrice(frame.Retail);
        this.upc = frame.UPCCode;

        this.bridge = frame.Bridge;
        this.collection = frame.Collection;
        this.color = frame.Color;
        this.eye = frame.Eye;
        this.temple = frame.Temple;
        this.style = frame.Style;
    }

    /** Frame description. */
    get description(): string {
        return `${this.style} ${this.color} ${this.eye}/${this.bridge}/${this.temple}`;
    }
}
