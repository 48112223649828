import { IPatientPhone } from '../../interfaces/patient-pm/patient-phone';
import { Phone } from './phone';

export class PatientPhone {
    public id: number;
    public phone: Phone;

    constructor(patientPhone: IPatientPhone) {
        this.id = patientPhone.Id;
        this.phone = new Phone(patientPhone.Phone);
    }
}