import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { TranslateModule } from '@ngx-translate/core';

import { LinkPipe } from '@pm/core';
import { QuickListComponent } from '../quick-list/quick-list.component';
import { AppFeatureService } from '@pm/core/configuration';

/**
 * Component for the navigation section of the header.
 */
@Component({
  selector: 'pm-navigation-claims',
  standalone: true,
  imports: [
    CommonModule,
    LinkPipe,
    MatButtonModule,
    MatExpansionModule,
    MatIconModule,
    MatMenuModule,
    MatToolbarModule,
    QuickListComponent,
    TranslateModule
  ],
  templateUrl: './navigation-claims.component.html',
  styleUrls: ['./navigation-claims.component.css'],
})
export class NavigationClaimsComponent {
  /** Is analytics and insights enabled? */
  public isAnalyticsAndInsightsEnabled = this.feature.isAnalyticsAndInsightsEnabled;
  /** Is ERA enabled? */
  public isEraEnabled = this.feature.isEraEnabled();
  /** Is patient statemenst enabled? */
  public isPatientStatementsEnabled = false;
  /** Is Clearinghouse Claim Status Enabled */
  public isClearinghouseClaimStatusIntegratedEnabled = false;

  /**
   * Initializes instance of the HeaderNavigationComponent class.
   * @param feature Application feature service
   */
  constructor(private feature: AppFeatureService) {
    this.feature.isPatientStatementsEnabled().then(value => this.isPatientStatementsEnabled = value);
    this.feature.isClearinghouseClaimStatusIntegratedEnabled().then(value => this.isClearinghouseClaimStatusIntegratedEnabled = value);
  }
}
