<h1>{{ 'REPORTS.QUICK_LIST' | translate : {today: (today | date : 'M/d/yyyy')} }}</h1>
<table>
    <thead>
        <tr>
            <th>{{ 'TABLES.COLUMNS.PATIENT' | translate }}</th>
            <th>{{ 'TABLES.COLUMNS.PHONE' | translate }}</th>
            <th>{{ 'TABLES.COLUMNS.APPT_TIME' | translate }}</th>
            <th>{{ 'TABLES.COLUMNS.PROVIDER' | translate }}</th>
            <th>{{ 'TABLES.COLUMNS.SERVICE' | translate }}</th>
            <th>{{ 'TABLES.COLUMNS.LOCATION_STATUS' | translate }}</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let patient of patients">
            <td>{{ patient.fullName }}</td>
            <td>{{ patient.phone }}</td>
            <td>{{ patient.appointmentTime }}</td>
            <td>{{ patient.providerName }}</td>
            <td>{{ patient.serviceName }}</td>
            <td>{{ patientLocation(patient) }}</td>
        </tr>
    </tbody>
</table>