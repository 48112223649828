/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';

import { PatientDetail } from '../classes/patient-detail';
import { PatientPinnedNote } from '../classes/patient-pinned-note';
import { PatientPinnedNoteType } from '../enums/patient-pinned-note-type';
import { PatientPinnedNoteMockData } from '../mock-data/patient-pinned-note';
import { PatientsMockData } from '../mock-data/patients';
import { PatientsService } from './patients.service';

/**
 * Content service mock for testing.
 */
@Injectable()
export class PatientsMockService extends PatientsService {
    /**
     * Get patient mock data.
     * @param patientInternalId Patient internal id.
     * @returns Mock data
     */
    override getPatient(patientInternalId: number): Promise<PatientDetail | undefined> {
        return new Promise(resolve => {
            const data = PatientsMockData()[0];
            data.PatientInternalId = patientInternalId;
            resolve(new PatientDetail(data));
        });
    }

    /**
   * Get patient pinned note by type mock data.
   * @param patientInternalId Patient internal id
   * @param pinnedNoteType Patient pinned note type
   * @returns pinned note object
   */
    override async getPatientPinnedNoteByType(patientInternalId: number, pinnedNoteType = PatientPinnedNoteType.DEMO): Promise<PatientPinnedNote | null> {
        return new PatientPinnedNote(PatientPinnedNoteMockData());
    }

    /**
     * Get the number of patients added from the online scheduler that need review. (mock data)
     * @returns Patient count
     */
    override async getPatientReviewCount(): Promise<number> {
        return 7;
    }

    /**
     * Mock save patient pinned note.
     * @param pinnedNote Patient pinned note
     * @returns boolean value indicating success
     */
    override async savePatientPinnedNote(pinnedNote: PatientPinnedNote): Promise<PatientPinnedNote | null> {
        return pinnedNote;
    }
}