import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Subscription } from 'rxjs';

import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { TranslateModule } from '@ngx-translate/core';

import { LinkPipe } from '@pm/core';
import { AppFeatureService } from '@pm/core/configuration';
import { SecurityService } from '@pm/core/security';
import { NavLinkDirective } from '@pm/layout';
import { QuickListComponent } from '../quick-list/quick-list.component';

/** Need to call for fullscreen calendar. */
declare const window: { winResize: () => void };

/**
 * Component for the navigation section of the header.
 */
@Component({
  selector: 'pm-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    LinkPipe,
    MatButtonModule,
    MatExpansionModule,
    MatIconModule,
    MatMenuModule,
    MatToolbarModule,
    NavLinkDirective,
    QuickListComponent,
    TranslateModule
  ]
})
export class NavigationComponent implements OnDestroy, OnInit {
  /** Has dashboard role? */
  public hasDashboardRole = false;
  /** Is analytics and insights enabled? */
  public isAnalyticsAndInsightsEnabled = this.feature.isAnalyticsAndInsightsEnabled;
  /** Is browser in fullscreen mode. */
  public isFullscreen = !!document.fullscreenElement;

  /** Array of subscriptions to observables. */
  private subscriptions: Subscription[] = [];

  /** Detect fullscreen browser mode. */
  @HostListener('window:resize', ['$event.target'])
  onResize(): void {
    this.isFullscreen = !!document.fullscreenElement;
  }

  /** Header mode. (calendar or standard) */
  @Input() mode: 'calendar' | 'standard' = 'standard';

  /**
   * Initializes instance of the HeaderNavigationComponent class.
   * @param feature Application feature service
   * @param security Security service
   */
  constructor(
    private feature: AppFeatureService,
    private security: SecurityService
  ) { }

  /**
   * OnInit lifecycle hook.
   * -- Subscribe to has dashboard role flag.
   */
  ngOnInit(): void {
    this.subscriptions.push(this.security.hasDashboardRole$.subscribe(value => this.hasDashboardRole = value));
  }

  /**
   * OnDestroy lifecycle hook.
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  /**
   * Entedr fullscreen calendar.
   */
  calendarEnterFullscreen(): void {
    document.documentElement.requestFullscreen();
    window.winResize();
  }

  /**
   * Exit fullscreen calendar.
   */
  calendarExitFullscreen(): void {
    document.exitFullscreen();
    window.winResize();
  }

  /**
   * Clear session storage value used by scheduler.
   * @param event 
   */
  clearSchedulerSessionStorage(event: Event): void {
    event.preventDefault();
    sessionStorage.removeItem('appointmentsOfficeContext');
  }
}
