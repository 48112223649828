import { IAddress } from '../interfaces/patient-pm/address';
import { IPatientRelationships } from '../interfaces/patient-relationship';

/**
 * PatientRelationships class
 */
export class PatientRelationships {
    /** Patient ID */
    patientId: number;
    /** Dependent ID */
    dependentId: number;
    /** User ID */
    userId: number;
    /** Provider ID */
    providerId: number;
    /** Title */
    title: number;
    /** Last name */
    lastName: string;
    /** First name */
    firstName: string;
    /** Full name */
    fullName: string;
    /** Middle initial */
    middleInitial: string;
    /** Nickname */
    nickName: string;
    /** Addresses */
    addresses?: IAddress[] | null; // Ensure Address is defined elsewhere
    /** Email address */
    email: string;
    /** Phone number */
    phoneNumber: string;
    /** Primary phone type */
    primaryPhoneType: number;
    /** Primary phone call time */
    primaryPhoneCallTime: number;
    /** Medical record number */
    medicalRecordNumber: string;
    /** Social security number */
    socialSecurityNumber: string;
    /** Sex */
    sex: string;
    /** Date of birth */
    dateOfBirth: string;
    /** Copy email status */
    copyEmail: boolean;
    /** Copy insurance status */
    copyInsurance: boolean;
    /** Patient status */
    isPatient: boolean;
    /** Responsible party status */
    isResponsibleParty: boolean;
    /** Relationship to insured */
    relationshipToInsured: string;
    /** Date of last exam */
    lastExamDate: string;
    /** Suffix */
    suffix: number;
    /** Relation to subscriber */
    relationToSubscriber: number;

    constructor(data: IPatientRelationships) {
        this.patientId = data.PatientId;
        this.dependentId = data.DependentId;
        this.userId = data.UserId;
        this.providerId = data.ProviderId;
        this.title = data.Title;
        this.lastName = data.LastName;
        this.firstName = data.FirstName;
        this.fullName = data.FullName;
        this.middleInitial = data.MiddleInitial;
        this.nickName = data.NickName;
        this.addresses = data.Addresses;
        this.email = data.Email;
        this.phoneNumber = data.PhoneNumber;
        this.primaryPhoneType = data.PrimaryPhoneType;
        this.primaryPhoneCallTime = data.PrimaryPhoneCallTime;
        this.medicalRecordNumber = data.MedicalRecordNumber;
        this.socialSecurityNumber = data.SocialSecurityNumber;
        this.sex = data.Sex;
        this.dateOfBirth = data.DateOfBirth;
        this.copyEmail = data.CopyEmail;
        this.copyInsurance = data.CopyInsurance;
        this.isPatient = data.IsPatient;
        this.isResponsibleParty = data.IsResponsibleParty;
        this.relationshipToInsured = data.RelationshipToInsured;
        this.lastExamDate = data.LastExamDate;
        this.suffix = data.Suffix;
        this.relationToSubscriber = data.RelationToSubscriber;
    }
}