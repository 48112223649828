import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatIconModule } from '@angular/material/icon';

import { IAlert } from '../../alert';

/**
 * Component for alert messages.
 */
@Component({
  selector: 'pm-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule
  ]
})
export class AlertComponent {
  /** CSS class for style. */
  public cssClass = 'ef-alert-basic ef-alert-info';

  /** Alert options with default values. */
  private alert: IAlert = {
    isGrouped: false,
    style: 'Basic',
    type: 'Info'
  };

  /** Alert options. */
  @Input() set options(value: IAlert) {
    Object.assign(this.alert, value);
    switch (this.alert.style) {
      case 'Clickable':
        this.cssClass = 'ef-alert-clickable'
        break;
      default:
        this.cssClass = 'ef-alert-basic'
        break;
    }
    this.cssClass += ` ef-alert-${this.options.type?.toLowerCase() || 'info'}`
    if(this.options.isGrouped) this.cssClass += ' ef-alert-grouped';
  }
  get options(): IAlert {
    return this.alert;
  }

  /** Alert title. */
  @Input() title?: string;
}