import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { UiService } from '@pm/layout';
import { FooterComponent } from '@pm/layout/footer';
import { HeaderComponent } from '@pm/layout/header';

/**
 * Main application component.
 */
@Component({
  selector: 'pm-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [
    FooterComponent,
    HeaderComponent,
    MatProgressSpinnerModule,
    RouterModule
  ]
})
export class AppComponent {
  /**
   * Initializes a new instance of the AppComponent class.
   * @param ui UI service
   */
  constructor(public ui: UiService) { }
}
