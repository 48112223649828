import { IAddress } from '../../interfaces/patient-pm/address';

export class Address {
    public addressId: number;
    public addressTypeId: number;
    public address1: string;
    public address2: string;
    public city: string;
    public state: string;
    public zipCode: string;
    public isPrimary: boolean;
    public addressType: string;
    public country: string;
    public countryId: number;

    constructor(address: IAddress) {
        this.addressId = address.AddressId;
        this.addressTypeId = address.AddressTypeId;
        this.address1 = address.Address1;
        this.address2 = address.Address2;
        this.city = address.City;
        this.state = address.State;
        this.zipCode = address.ZipCode;
        this.isPrimary = address.IsPrimary;
        this.addressType = address.AddressType;
        this.country = address.Country;
        this.countryId = address.CountryId;
    }
}