import { IPatientProfile } from '../interfaces/patient-profile';

/**
 * The patient profile used at the top of all patient pages in EPM
 */
export class PatientProfile {
    /** patient identifier */
    patientId: number;
    /** patient age */
    age: string;
    /** pateint primary insurance */
    insuranceOne: string;
    /** patient provider */
    provider: string;
    /** patient credit */
    credit: string;
    /** patient balance */
    balance: string;

    constructor(patientProfile: IPatientProfile) {
        this.patientId = patientProfile.PatientId;
        this.age = patientProfile.Age;
        this.insuranceOne = patientProfile.InsuranceOne;
        this.provider = patientProfile.Provider;
        this.credit = patientProfile.Credit;
        this.balance = patientProfile.Balance;
    }
}