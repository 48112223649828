import { IQuickListResponse } from '../interfaces/quick-list-response';

export function QuickListResponseMockData(): IQuickListResponse {
    return {
        'quicklistSummaries': [
            {
                'LastName': 'Adam',
                'FirstName': 'Sam',
                'PatientId': 18782179,
                'Phone': '(650) 520-0089 (c)',
                'AppointmentTime': '8:00 AM',
                'AppointmentId': 20427842,
                'ServiceName': '0330T - Lipiview Treatment',
                'ProviderName': 'Sri ProvidrOne Kanaparthi CI',
                'SelectedLocationOrStatus': 18,
                'IsScheduledPatient': true,
                'Time': '2024-05-01T08:00:00'
            },
            {
                'LastName': 'Kanaparthi',
                'FirstName': 'Sri Patnt Five',
                'PatientId': 18778057,
                'Phone': '(283) 728-7337 (c)',
                'AppointmentTime': '1:00 PM',
                'AppointmentId': 20427871,
                'ServiceName': '9201-Pe Team Do Not Use',
                'ProviderName': 'Sri Kanaparthi',
                'SelectedLocationOrStatus': 0,
                'IsScheduledPatient': true,
                'Time': '2024-05-01T13:00:00'
            },
            {
                'LastName': 'Ackerman',
                'FirstName': 'Levi',
                'PatientId': 18776556,
                'Phone': '(412) 323-4524 (c)',
                'AppointmentTime': null,
                'AppointmentId': 0,
                'ServiceName': '',
                'ProviderName': ' ',
                'SelectedLocationOrStatus': 0,
                'IsScheduledPatient': false,
                'Time': '0001-01-01T00:00:00'
            },
            {
                'LastName': 'Alito',
                'FirstName': 'Samuel',
                'PatientId': 1111852,
                'Phone': '(949) 500-6645 (c)',
                'AppointmentTime': null,
                'AppointmentId': 0,
                'ServiceName': '',
                'ProviderName': ' ',
                'SelectedLocationOrStatus': 0,
                'IsScheduledPatient': false,
                'Time': '0001-01-01T00:00:00'
            },
            {
                'LastName': 'Brooks',
                'FirstName': 'Danny',
                'PatientId': 18787137,
                'Phone': '(517) 884-2323 (c)',
                'AppointmentTime': null,
                'AppointmentId': 0,
                'ServiceName': '',
                'ProviderName': ' ',
                'SelectedLocationOrStatus': 0,
                'IsScheduledPatient': false,
                'Time': '0001-01-01T00:00:00'
            },
            {
                'LastName': 'Davis',
                'FirstName': 'Bette',
                'PatientId': 18772852,
                'Phone': '(916) 851-5050 (c)',
                'AppointmentTime': null,
                'AppointmentId': 0,
                'ServiceName': '',
                'ProviderName': ' ',
                'SelectedLocationOrStatus': 0,
                'IsScheduledPatient': false,
                'Time': '0001-01-01T00:00:00'
            },
            {
                'LastName': 'DoNotUs GI',
                'FirstName': 'PE Pt Pm Ci',
                'PatientId': 18786502,
                'Phone': '(123) 456-7890 (c)',
                'AppointmentTime': null,
                'AppointmentId': 0,
                'ServiceName': '',
                'ProviderName': ' ',
                'SelectedLocationOrStatus': 0,
                'IsScheduledPatient': false,
                'Time': '0001-01-01T00:00:00'
            },
            {
                'LastName': 'Fosth',
                'FirstName': 'Robin',
                'PatientId': 10071733,
                'Phone': '(248) 555-8326 (h)',
                'AppointmentTime': null,
                'AppointmentId': 0,
                'ServiceName': '',
                'ProviderName': ' ',
                'SelectedLocationOrStatus': 0,
                'IsScheduledPatient': false,
                'Time': '0001-01-01T00:00:00'
            },
            {
                'LastName': 'G M',
                'FirstName': 'Da X',
                'PatientId': 18787146,
                'Phone': '(123) 456-7890 (c)',
                'AppointmentTime': null,
                'AppointmentId': 0,
                'ServiceName': '',
                'ProviderName': ' ',
                'SelectedLocationOrStatus': 0,
                'IsScheduledPatient': false,
                'Time': '0001-01-01T00:00:00'
            },
            {
                'LastName': 'Griffin',
                'FirstName': 'Chris',
                'PatientId': 15107744,
                'Phone': '(555) 555-5555 (c)',
                'AppointmentTime': null,
                'AppointmentId': 0,
                'ServiceName': '',
                'ProviderName': ' ',
                'SelectedLocationOrStatus': 0,
                'IsScheduledPatient': false,
                'Time': '0001-01-01T00:00:00'
            },
            {
                'LastName': 'H C',
                'FirstName': 'Ama O',
                'PatientId': 18787145,
                'Phone': '(123) 456-7890 (c)',
                'AppointmentTime': null,
                'AppointmentId': 0,
                'ServiceName': '',
                'ProviderName': ' ',
                'SelectedLocationOrStatus': 0,
                'IsScheduledPatient': false,
                'Time': '0001-01-01T00:00:00'
            },
            {
                'LastName': 'I T',
                'FirstName': 'B F',
                'PatientId': 18787149,
                'Phone': '(123) 456-7890 (c)',
                'AppointmentTime': null,
                'AppointmentId': 0,
                'ServiceName': '',
                'ProviderName': ' ',
                'SelectedLocationOrStatus': 0,
                'IsScheduledPatient': false,
                'Time': '0001-01-01T00:00:00'
            },
            {
                'LastName': 'K A',
                'FirstName': 'B G',
                'PatientId': 18787148,
                'Phone': '(123) 456-7890 (c)',
                'AppointmentTime': null,
                'AppointmentId': 0,
                'ServiceName': '',
                'ProviderName': ' ',
                'SelectedLocationOrStatus': 0,
                'IsScheduledPatient': false,
                'Time': '0001-01-01T00:00:00'
            },
            {
                'LastName': 'Lind',
                'FirstName': 'Hiram',
                'PatientId': 18787140,
                'Phone': '(123) 456-7890 (c)',
                'AppointmentTime': null,
                'AppointmentId': 0,
                'ServiceName': '',
                'ProviderName': ' ',
                'SelectedLocationOrStatus': 0,
                'IsScheduledPatient': false,
                'Time': '0001-01-01T00:00:00'
            },
            {
                'LastName': 'Mouse',
                'FirstName': 'Mickey',
                'PatientId': 1134200,
                'Phone': '(916) 456-4564 (c)',
                'AppointmentTime': null,
                'AppointmentId': 0,
                'ServiceName': '',
                'ProviderName': ' ',
                'SelectedLocationOrStatus': 0,
                'IsScheduledPatient': false,
                'Time': '0001-01-01T00:00:00'
            },
            {
                'LastName': 'N G',
                'FirstName': 'Da U',
                'PatientId': 18787142,
                'Phone': '(123) 456-7890 (c)',
                'AppointmentTime': null,
                'AppointmentId': 0,
                'ServiceName': '',
                'ProviderName': ' ',
                'SelectedLocationOrStatus': 0,
                'IsScheduledPatient': false,
                'Time': '0001-01-01T00:00:00'
            },
            {
                'LastName': 'P W',
                'FirstName': 'Ama R',
                'PatientId': 18787144,
                'Phone': '(123) 456-7890 (c)',
                'AppointmentTime': null,
                'AppointmentId': 0,
                'ServiceName': '',
                'ProviderName': ' ',
                'SelectedLocationOrStatus': 0,
                'IsScheduledPatient': false,
                'Time': '0001-01-01T00:00:00'
            },
            {
                'LastName': 'Patel',
                'FirstName': 'Komal',
                'PatientId': 14538647,
                'Phone': '(123) 456-7890 (c)',
                'AppointmentTime': null,
                'AppointmentId': 0,
                'ServiceName': '',
                'ProviderName': ' ',
                'SelectedLocationOrStatus': 0,
                'IsScheduledPatient': false,
                'Time': '0001-01-01T00:00:00'
            },
            {
                'LastName': 'Prr',
                'FirstName': 'Prr',
                'PatientId': 18787135,
                'Phone': '(852) 252-2222 (c)',
                'AppointmentTime': null,
                'AppointmentId': 0,
                'ServiceName': '',
                'ProviderName': ' ',
                'SelectedLocationOrStatus': 0,
                'IsScheduledPatient': false,
                'Time': '0001-01-01T00:00:00'
            },
            {
                'LastName': 'Pst',
                'FirstName': 'Pst',
                'PatientId': 18787150,
                'Phone': '(852) 252-2222 (c)',
                'AppointmentTime': null,
                'AppointmentId': 0,
                'ServiceName': '',
                'ProviderName': ' ',
                'SelectedLocationOrStatus': 0,
                'IsScheduledPatient': false,
                'Time': '0001-01-01T00:00:00'
            },
            {
                'LastName': 'R X',
                'FirstName': 'Da L',
                'PatientId': 18787147,
                'Phone': '(123) 456-7890 (c)',
                'AppointmentTime': null,
                'AppointmentId': 0,
                'ServiceName': '',
                'ProviderName': ' ',
                'SelectedLocationOrStatus': 0,
                'IsScheduledPatient': false,
                'Time': '0001-01-01T00:00:00'
            },
            {
                'LastName': 'Skywalker',
                'FirstName': 'Anakin',
                'PatientId': 18776478,
                'Phone': '(191) 621-3450 (c)',
                'AppointmentTime': null,
                'AppointmentId': 0,
                'ServiceName': '',
                'ProviderName': ' ',
                'SelectedLocationOrStatus': 0,
                'IsScheduledPatient': false,
                'Time': '0001-01-01T00:00:00'
            },
            {
                'LastName': 'Skywalker',
                'FirstName': 'Luke',
                'PatientId': 18776479,
                'Phone': '(555) 555-5555 (h)',
                'AppointmentTime': null,
                'AppointmentId': 0,
                'ServiceName': '',
                'ProviderName': ' ',
                'SelectedLocationOrStatus': 0,
                'IsScheduledPatient': false,
                'Time': '0001-01-01T00:00:00'
            },
            {
                'LastName': 'Smith',
                'FirstName': 'Aaron',
                'PatientId': 13605514,
                'Phone': '(555) 555-5555 (c)',
                'AppointmentTime': null,
                'AppointmentId': 0,
                'ServiceName': '',
                'ProviderName': ' ',
                'SelectedLocationOrStatus': 0,
                'IsScheduledPatient': false,
                'Time': '0001-01-01T00:00:00'
            },
            {
                'LastName': 'T K',
                'FirstName': 'Fr H',
                'PatientId': 18787139,
                'Phone': '(123) 456-7890 (c)',
                'AppointmentTime': null,
                'AppointmentId': 0,
                'ServiceName': '',
                'ProviderName': ' ',
                'SelectedLocationOrStatus': 0,
                'IsScheduledPatient': false,
                'Time': '0001-01-01T00:00:00'
            },
            {
                'LastName': 'Test',
                'FirstName': 'Abbxy',
                'PatientId': 18773131,
                'Phone': '(212) 121-2121 (c)',
                'AppointmentTime': null,
                'AppointmentId': 0,
                'ServiceName': '',
                'ProviderName': ' ',
                'SelectedLocationOrStatus': 0,
                'IsScheduledPatient': false,
                'Time': '0001-01-01T00:00:00'
            },
            {
                'LastName': 'Test',
                'FirstName': 'AC',
                'PatientId': 18775776,
                'Phone': '(142) 233-6697 (c)',
                'AppointmentTime': null,
                'AppointmentId': 0,
                'ServiceName': '',
                'ProviderName': ' ',
                'SelectedLocationOrStatus': 0,
                'IsScheduledPatient': false,
                'Time': '0001-01-01T00:00:00'
            },
            {
                'LastName': 'Test',
                'FirstName': 'Ankit',
                'PatientId': 18778908,
                'Phone': '(222) 222-2222 (c)',
                'AppointmentTime': null,
                'AppointmentId': 0,
                'ServiceName': '',
                'ProviderName': ' ',
                'SelectedLocationOrStatus': 0,
                'IsScheduledPatient': false,
                'Time': '0001-01-01T00:00:00'
            },
            {
                'LastName': 'Test',
                'FirstName': 'Attribute',
                'PatientId': 18786993,
                'Phone': '(111) 111-1111 (c)',
                'AppointmentTime': null,
                'AppointmentId': 0,
                'ServiceName': '',
                'ProviderName': ' ',
                'SelectedLocationOrStatus': 0,
                'IsScheduledPatient': false,
                'Time': '0001-01-01T00:00:00'
            },
            {
                'LastName': 'Test',
                'FirstName': 'Jayatest',
                'PatientId': 18772865,
                'Phone': '(230) 230-2300 (h)',
                'AppointmentTime': null,
                'AppointmentId': 0,
                'ServiceName': '',
                'ProviderName': ' ',
                'SelectedLocationOrStatus': 0,
                'IsScheduledPatient': false,
                'Time': '0001-01-01T00:00:00'
            },
            {
                'LastName': 'Test',
                'FirstName': 'Middle',
                'PatientId': 18787138,
                'Phone': '(895) 623-9333 (c)',
                'AppointmentTime': null,
                'AppointmentId': 0,
                'ServiceName': '',
                'ProviderName': ' ',
                'SelectedLocationOrStatus': 0,
                'IsScheduledPatient': false,
                'Time': '0001-01-01T00:00:00'
            },
            {
                'LastName': 'VSPUnlimited',
                'FirstName': 'Anitha',
                'PatientId': 18778814,
                'Phone': '(725) 999-4511 (c)',
                'AppointmentTime': null,
                'AppointmentId': 0,
                'ServiceName': '',
                'ProviderName': ' ',
                'SelectedLocationOrStatus': 0,
                'IsScheduledPatient': false,
                'Time': '0001-01-01T00:00:00'
            },
            {
                'LastName': 'W T',
                'FirstName': 'Da T',
                'PatientId': 18787143,
                'Phone': '(123) 456-7890 (c)',
                'AppointmentTime': null,
                'AppointmentId': 0,
                'ServiceName': '',
                'ProviderName': ' ',
                'SelectedLocationOrStatus': 0,
                'IsScheduledPatient': false,
                'Time': '0001-01-01T00:00:00'
            },
            {
                'LastName': 'Witting',
                'FirstName': 'Jace',
                'PatientId': 18787141,
                'Phone': '(123) 456-7890 (c)',
                'AppointmentTime': null,
                'AppointmentId': 0,
                'ServiceName': '',
                'ProviderName': ' ',
                'SelectedLocationOrStatus': 0,
                'IsScheduledPatient': false,
                'Time': '0001-01-01T00:00:00'
            },
            {
                'LastName': 'YHTEST',
                'FirstName': 'VSPYH',
                'PatientId': 18778675,
                'Phone': '(333) 333-3333 (c)',
                'AppointmentTime': null,
                'AppointmentId': 0,
                'ServiceName': '',
                'ProviderName': ' ',
                'SelectedLocationOrStatus': 0,
                'IsScheduledPatient': false,
                'Time': '0001-01-01T00:00:00'
            }
        ],
        'quicklistLocations': [
            {
                'LocationId': 0,
                'Description': 'Select',
                'OfficeNum': '936',
                'IsLocationInUse': false,
                'ID': 0,
                'ErrorMessages': []
            },
            {
                'LocationId': 21,
                'Description': 'Check In',
                'OfficeNum': '936',
                'IsLocationInUse': false,
                'ID': 20445,
                'ErrorMessages': []
            },
            {
                'LocationId': 18,
                'Description': 'Check Out',
                'OfficeNum': '936',
                'IsLocationInUse': true,
                'ID': 7993,
                'ErrorMessages': []
            },
            {
                'LocationId': 11,
                'Description': 'Checked Out',
                'OfficeNum': '936',
                'IsLocationInUse': false,
                'ID': 34114,
                'ErrorMessages': []
            },
            {
                'LocationId': 11,
                'Description': 'Checked Out',
                'OfficeNum': '936',
                'IsLocationInUse': false,
                'ID': 34125,
                'ErrorMessages': []
            },
            {
                'LocationId': 11,
                'Description': 'Checked Out',
                'OfficeNum': '936',
                'IsLocationInUse': false,
                'ID': 34147,
                'ErrorMessages': []
            },
            {
                'LocationId': 9,
                'Description': 'Contact Lens Training',
                'OfficeNum': '936',
                'IsLocationInUse': false,
                'ID': 34145,
                'ErrorMessages': []
            },
            {
                'LocationId': 9,
                'Description': 'Contact Lens Training',
                'OfficeNum': '936',
                'IsLocationInUse': false,
                'ID': 34123,
                'ErrorMessages': []
            },
            {
                'LocationId': 9,
                'Description': 'Contact Lens Training',
                'OfficeNum': '936',
                'IsLocationInUse': false,
                'ID': 34112,
                'ErrorMessages': []
            },
            {
                'LocationId': 3,
                'Description': 'Diagnostic Testing',
                'OfficeNum': '936',
                'IsLocationInUse': false,
                'ID': 34106,
                'ErrorMessages': []
            },
            {
                'LocationId': 3,
                'Description': 'Diagnostic Testing',
                'OfficeNum': '936',
                'IsLocationInUse': false,
                'ID': 34139,
                'ErrorMessages': []
            },
            {
                'LocationId': 3,
                'Description': 'Diagnostic Testing',
                'OfficeNum': '936',
                'IsLocationInUse': false,
                'ID': 34117,
                'ErrorMessages': []
            },
            {
                'LocationId': 12,
                'Description': 'Dialating',
                'OfficeNum': '936',
                'IsLocationInUse': false,
                'ID': 7338,
                'ErrorMessages': []
            },
            {
                'LocationId': 8,
                'Description': 'Dispensary',
                'OfficeNum': '936',
                'IsLocationInUse': false,
                'ID': 34111,
                'ErrorMessages': []
            },
            {
                'LocationId': 8,
                'Description': 'Dispensary',
                'OfficeNum': '936',
                'IsLocationInUse': false,
                'ID': 34122,
                'ErrorMessages': []
            },
            {
                'LocationId': 8,
                'Description': 'Dispensary',
                'OfficeNum': '936',
                'IsLocationInUse': false,
                'ID': 34144,
                'ErrorMessages': []
            },
            {
                'LocationId': 4,
                'Description': 'Exam Room 1',
                'OfficeNum': '936',
                'IsLocationInUse': false,
                'ID': 34118,
                'ErrorMessages': []
            },
            {
                'LocationId': 4,
                'Description': 'Exam Room 1',
                'OfficeNum': '936',
                'IsLocationInUse': false,
                'ID': 34140,
                'ErrorMessages': []
            },
            {
                'LocationId': 4,
                'Description': 'Exam Room 1',
                'OfficeNum': '936',
                'IsLocationInUse': false,
                'ID': 34107,
                'ErrorMessages': []
            },
            {
                'LocationId': 19,
                'Description': 'Exam Room 1',
                'OfficeNum': '936',
                'IsLocationInUse': false,
                'ID': 9080,
                'ErrorMessages': []
            },
            {
                'LocationId': 14,
                'Description': 'Exam Room 2',
                'OfficeNum': '936',
                'IsLocationInUse': false,
                'ID': 7340,
                'ErrorMessages': []
            },
            {
                'LocationId': 5,
                'Description': 'Exam Room 2',
                'OfficeNum': '936',
                'IsLocationInUse': false,
                'ID': 34108,
                'ErrorMessages': []
            },
            {
                'LocationId': 5,
                'Description': 'Exam Room 2',
                'OfficeNum': '936',
                'IsLocationInUse': false,
                'ID': 34141,
                'ErrorMessages': []
            },
            {
                'LocationId': 5,
                'Description': 'Exam Room 2',
                'OfficeNum': '936',
                'IsLocationInUse': false,
                'ID': 34119,
                'ErrorMessages': []
            },
            {
                'LocationId': 6,
                'Description': 'Exam Room 3',
                'OfficeNum': '936',
                'IsLocationInUse': false,
                'ID': 34120,
                'ErrorMessages': []
            },
            {
                'LocationId': 6,
                'Description': 'Exam Room 3',
                'OfficeNum': '936',
                'IsLocationInUse': false,
                'ID': 34142,
                'ErrorMessages': []
            },
            {
                'LocationId': 6,
                'Description': 'Exam Room 3',
                'OfficeNum': '936',
                'IsLocationInUse': false,
                'ID': 34109,
                'ErrorMessages': []
            },
            {
                'LocationId': 6,
                'Description': 'Exam Room 3',
                'OfficeNum': '936',
                'IsLocationInUse': false,
                'ID': 6791,
                'ErrorMessages': []
            },
            {
                'LocationId': 7,
                'Description': 'Exam Room 4',
                'OfficeNum': '936',
                'IsLocationInUse': false,
                'ID': 34110,
                'ErrorMessages': []
            },
            {
                'LocationId': 7,
                'Description': 'Exam Room 4',
                'OfficeNum': '936',
                'IsLocationInUse': false,
                'ID': 34143,
                'ErrorMessages': []
            },
            {
                'LocationId': 7,
                'Description': 'Exam Room 4',
                'OfficeNum': '936',
                'IsLocationInUse': false,
                'ID': 34121,
                'ErrorMessages': []
            },
            {
                'LocationId': 20,
                'Description': 'House lab',
                'OfficeNum': '936',
                'IsLocationInUse': false,
                'ID': 12323,
                'ErrorMessages': []
            },
            {
                'LocationId': 8,
                'Description': 'Optical Dispensary',
                'OfficeNum': '936',
                'IsLocationInUse': false,
                'ID': 6793,
                'ErrorMessages': []
            },
            {
                'LocationId': 15,
                'Description': 'Pre-Testing',
                'OfficeNum': '936',
                'IsLocationInUse': false,
                'ID': 7341,
                'ErrorMessages': []
            },
            {
                'LocationId': 2,
                'Description': 'Pre-Testing',
                'OfficeNum': '936',
                'IsLocationInUse': false,
                'ID': 34105,
                'ErrorMessages': []
            },
            {
                'LocationId': 2,
                'Description': 'Pre-Testing',
                'OfficeNum': '936',
                'IsLocationInUse': false,
                'ID': 34116,
                'ErrorMessages': []
            },
            {
                'LocationId': 2,
                'Description': 'Pre-Testing',
                'OfficeNum': '936',
                'IsLocationInUse': false,
                'ID': 34138,
                'ErrorMessages': []
            },
            {
                'LocationId': 1,
                'Description': 'Reception',
                'OfficeNum': '936',
                'IsLocationInUse': false,
                'ID': 34115,
                'ErrorMessages': []
            },
            {
                'LocationId': 1,
                'Description': 'Reception',
                'OfficeNum': '936',
                'IsLocationInUse': false,
                'ID': 34137,
                'ErrorMessages': []
            },
            {
                'LocationId': 1,
                'Description': 'Reception',
                'OfficeNum': '936',
                'IsLocationInUse': false,
                'ID': 34104,
                'ErrorMessages': []
            },
            {
                'LocationId': 10,
                'Description': 'Vision Therapy',
                'OfficeNum': '936',
                'IsLocationInUse': false,
                'ID': 6795,
                'ErrorMessages': []
            },
            {
                'LocationId': 10,
                'Description': 'Vision Therapy',
                'OfficeNum': '936',
                'IsLocationInUse': false,
                'ID': 34113,
                'ErrorMessages': []
            },
            {
                'LocationId': 10,
                'Description': 'Vision Therapy',
                'OfficeNum': '936',
                'IsLocationInUse': false,
                'ID': 34124,
                'ErrorMessages': []
            },
            {
                'LocationId': 10,
                'Description': 'Vision Therapy',
                'OfficeNum': '936',
                'IsLocationInUse': false,
                'ID': 34146,
                'ErrorMessages': []
            }
        ]
    };
}