import { Component, OnInit } from '@angular/core';
import { EligibilityReports } from '../model/eligibility-reports';
import { PatientStateService } from '../services/patient-state.service';
import {ReportMapper} from '../mappers/report.mapper';
import { StickyButtonComponent } from '../sticky-button/sticky-button.component';
import { EligibilityReportHeaderComponent } from '../report-header/report-header.component';
import { EligibilityReportGroupComponent } from '../report-group/report-group.component';
import { EligibilityReportFooterComponent } from '../report-footer/report-footer.component';
import { CommonModule } from '@angular/common';
import { filter } from 'rxjs';
import { AppConfigurationService } from '@pm/core/configuration';
@Component({
  selector: 'pm-eligibility',
  templateUrl: './eligibility.component.html',
  styleUrls: ['./eligibility.component.scss'],
  standalone: true,
  imports: [
    StickyButtonComponent,
    EligibilityReportHeaderComponent,
    EligibilityReportGroupComponent,
    EligibilityReportFooterComponent,
    CommonModule
  ]
})
export class EligibilityComponent implements OnInit {
  public loaded = false;
  public reports: EligibilityReports = new EligibilityReports;
  public prcReport = false;
  
  /**
   * @param patientStateService Patient state service
   * @param reportMapper Report mapper
   */
  constructor(
    private patientStateService: PatientStateService,
    private reportMapper: ReportMapper,
    private config: AppConfigurationService
  ) { }

  /**
   * Modifying the currentPatient before sending it to the report mapper
   */
  ngOnInit() : void {
    this.patientStateService.currentPatient$.pipe(filter(x => x !== undefined)).subscribe(currentPatient => {
      if (currentPatient) {
        if (currentPatient.Packages) {
          for (let i = 0; i < currentPatient.Packages.length; i ++) {
            if (!currentPatient.Packages[i].Retail.Verbalizations) {
              currentPatient.Packages.splice(i, 1);
            }
          }
        }
        
        if (currentPatient?.Packages[0]?.Retail?.Verbalizations && currentPatient?.Packages[0]?.Retail?.Verbalizations[0]?.Attributes && currentPatient.Packages[0].Retail.Verbalizations[0].Attributes[0].Value == 'Patient Eligibility Report') {
          this.prcReport = true;
        } else {
          this.prcReport = false;
        }
        this.reports = this.reportMapper.mapReports(currentPatient, this.prcReport);
      } 
    });
  }
}
