import { ICatalogItem } from '../interfaces/catalog-item';

export function ShippingMethodsMockData(): ICatalogItem[] {
    return [
        {
            'ItemID': 197707,
            'ItemName': 'Free Shipping',
            'ItemNumber': '309',
            'Price': 40.0,
            'AllowZeroPrice': false
        },
        {
            'ItemID': 197715,
            'ItemName': 'Contacts Overnight - Week Day',
            'ItemNumber': '312',
            'Price': 25.0,
            'AllowZeroPrice': false
        },
        {
            'ItemID': 199209,
            'ItemName': 'Glasses Overnight - Week Day',
            'ItemNumber': '317',
            'Price': 12.0,
            'AllowZeroPrice': false
        },
        {
            'ItemID': 199210,
            'ItemName': 'Glasses Overnight - Weekend',
            'ItemNumber': '318',
            'Price': 25.0,
            'AllowZeroPrice': false
        },
        {
            'ItemID': 199211,
            'ItemName': 'Contact Overnight - Weekend',
            'ItemNumber': '319',
            'Price': 25.0,
            'AllowZeroPrice': false
        },
        {
            'ItemID': 720832,
            'ItemName': 'Overnight Weekdays',
            'ItemNumber': '324',
            'Price': 25.0,
            'AllowZeroPrice': false
        },
        {
            'ItemID': 720833,
            'ItemName': 'Overnight Saturday',
            'ItemNumber': '325',
            'Price': 30.0,
            'AllowZeroPrice': false
        },
        {
            'ItemID': 723756,
            'ItemName': '2nd Day Air',
            'ItemNumber': '329',
            'Price': 0.0,
            'AllowZeroPrice': true
        },
        {
            'ItemID': 1607183,
            'ItemName': 'Annual Supply Ground',
            'ItemNumber': '340',
            'Price': 0.0,
            'AllowZeroPrice': false
        },
        {
            'ItemID': 1607186,
            'ItemName': 'Annual Supply Next Day',
            'ItemNumber': '342',
            'Price': 20.0,
            'AllowZeroPrice': false
        }
    ];
}