import { ILab } from '../interfaces/lab';

/**
 * Lab class.
 */
export class Lab {
    /** Lab internal id. */
    public labInternalId: string;
    /** Lab name. */
    public name: string;

    /**
     * Initializes instance of the Lab class.
     * @param lab Lab data.
     */
    constructor(lab: ILab) {
        this.labInternalId = lab.LabInternalId;
        this.name = lab.Name;
    }
}
