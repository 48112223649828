<ng-container *ngIf="alert$ | async as alert">

    <!-- dialog title -->
    <h1 mat-dialog-title>
        <span *ngIf="alert.operationType==='closing'">{{'DIALOGS.DAILY_CLOSING_WARNING'|translate}}</span>
        <span *ngIf="alert.operationType==='opening'">{{'DIALOGS.DAILY_OPENING_WARNING'|translate}}</span>
        <button data-ef-button="daily-closing-warning-close" mat-icon-button [attr.aria-label]="'BUTTONS.CLOSE'"
            mat-dialog-close="close">
            <mat-icon>close</mat-icon>
        </button>
    </h1>

    <!-- dialog content -->
    <div mat-dialog-content>
        {{ alert.message }}
    </div>

    <!-- dialog actions -->
    <div mat-dialog-actions class="justify-content-end">

        <!-- skip -->
        <button data-ef-button="skip-daily-closing" mat-flat-button color="primary" mat-dialog-close="skip">
            <span *ngIf="alert.operationType==='closing'">{{'BUTTONS.SKIP_DAILY_CLOSING'|translate}}</span>
            <span *ngIf="alert.operationType==='opening'">{{'BUTTONS.SKIP_DAILY_OPENING'|translate}}</span>
        </button>

        <!-- complete -->
        <button data-ef-button="complete-daily-closing" mat-flat-button color="primary" cdkFocusInitial
            mat-dialog-close="complete">
            <span *ngIf="alert.operationType==='closing'">{{'BUTTONS.COMPLETE_DAILY_CLOSING'|translate}}</span>
            <span *ngIf="alert.operationType==='opening'">{{'BUTTONS.COMPLETE_DAILY_OPENING'|translate}}</span>
        </button>

    </div>

</ng-container>