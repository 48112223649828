import { EyeglassFrame } from '@pm/core/catalog';
import { Lab } from '@pm/core/labs';
import { Patient } from '@pm/core/patients';
import { IFailedFrameOrder } from '../interfaces/failed-frame-order';

/**
 * Failed frame order class.
 */
export class FailedFrameOrder {
    /** Eyeglass frame. */
    public frame: EyeglassFrame;
    /** Lab. */
    public lab?: Lab;
    /** Order internal id. */
    public orderInternalId;
    /** Patient. */
    public patient: Patient;
    /** Frame order submitted date. (string) */
    public submittedDate: Date

    /**
     * Initializes instance of the FailedFrameOrder class.
     * @param order Failed frame order data.
     */
    constructor(order: IFailedFrameOrder) {
        this.frame = new EyeglassFrame(order.Frame);
        this.lab = order.Lab ? new Lab(order.Lab) : undefined;
        this.patient = new Patient(order.Patient);
        this.orderInternalId = order.OrderInternalId;
        this.submittedDate = new Date(order.SubmittedDate);
    }

    /** Link to order summary. */
    get orderSummaryLink(): string {
        return `Patient/EyeglassOrder?id=${this.patient.patientInternalId}&oid=${this.orderInternalId}`;
    }
}