import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { TranslateModule } from '@ngx-translate/core';

import { LinkPipe, RenderedComponent, WindowComponent } from '@pm/core';
import { AppConfigurationService } from '@pm/core/configuration';
import { ContentService } from '@pm/core/content';
import { PatientLocation, PatientWithAppointment, QuickListService } from '@pm/core/patients';
import { QuickListReportComponent } from './quick-list-report/quick-list-report.component';

/**
 * Quicklist dialog.
 */
@Component({
  selector: 'pm-quick-list-dialog',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    LinkPipe,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSortModule,
    MatTableModule,
    QuickListReportComponent,
    RenderedComponent,
    TranslateModule,
    WindowComponent
  ],
  templateUrl: './quick-list.dialog.html',
  styleUrls: ['./quick-list.dialog.css']
})
export class QuickListDialog implements AfterViewInit {
  /** Material table data source. */
  public dataSource!: MatTableDataSource<PatientWithAppointment>;
  /** Columns to display in table. */
  public displayedColumns: string[] = ['fullName', 'phone', 'appointmentTime', 'providerName', 'serviceName', 'selectedLocationOrStatus'];
  /** Are orders loaded from fulfilment service? */
  public isLoaded = false;
  /** Array of patient locations. */
  public locations: PatientLocation[] = [];
  /** Array of patients with appointment info. */
  public patients: PatientWithAppointment[] = [];
  /** Used to create the report for printing. */
  public showReport = false;
  /** View appointments only? */
  public viewAppointmentsOnly = false;

  /** Material paginator. */
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  /** Material sort. */
  @ViewChild(MatSort) sort!: MatSort;

  /**
   * Initialize instanst of the QuicklistComponent class.
   * @param config Application configuration service
   * @param content External content service
   * @param dialog Material dialog service
   */
  constructor(
    private config: AppConfigurationService,
    private content: ContentService,
    private quickListSvc: QuickListService
  ) { }

  /**
   * AfterViewInit lifecycle hook.
   */
  ngAfterViewInit(): void {
    this.loadPatients();
  }

  /**
   * Flter patient quick list base on view appointments only checkbox.
   */
  filterPatients(): void {
    const filtered = this.patients.filter(x => !this.viewAppointmentsOnly || x.isScheduled);
    this.dataSource = new MatTableDataSource(filtered);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  /**
   * Load quick list.
   */
  async loadPatients(): Promise<void> {
    [this.patients, this.locations] = await this.quickListSvc.getQuickList();
    this.filterPatients();
    this.isLoaded = true;
  }

  /**
   * Create or print quick list report.  Print will be triggered automatically
   * when rendering is complete.
   */
  printReport(): void {
    this.showReport ? this.print() : (this.showReport = true);
  }

  /**
   * Print quick list report.
   */
  print(): void {
    setTimeout(() => {
      const iframe = document.getElementById('quickListReport') as HTMLIFrameElement;
      iframe.contentWindow?.print();
    }, 50);
  }

  /**
   * Set title and add stylesheet to report window.
   * @param externalWindow Iframe containing the report.
   */
  async setupReportWindow(externalWindow: Window): Promise<void> {
    const content = await this.content.appContent;
    const styleSheetElement = document.createElement('link',);
    styleSheetElement.rel = 'stylesheet';
    styleSheetElement.href = this.config.getAssetPath('css/quick-list-report.css');
    externalWindow.document.head.appendChild(styleSheetElement);
    externalWindow.document.title = content?.appNameLong ?? '';
  }

  /**
   * Save changes to the patient location.
   * @param patient Patient details.
   */
  saveLocation(patient: PatientWithAppointment): void {
    this.quickListSvc.saveLocation(patient);
  }
}
