import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';

import { LinkPipe } from '@pm/core';
import { QuickListService, PatientWithAppointment, PatientLocation } from '@pm/core/patients';
import { DialogHelper } from '@pm/layout';
import { QuickListDialog } from '../../dialogs/quick-list/quick-list.dialog';
import { Subject, Subscription, debounceTime, distinctUntilChanged, switchMap } from 'rxjs';

/**
 * Component for the quick list menu.
 */
@Component({
  selector: 'pm-quick-list',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    LinkPipe,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    TranslateModule
  ],
  templateUrl: './quick-list.component.html',
  styleUrls: ['./quick-list.component.css']
})
export class QuickListComponent implements OnDestroy, OnInit {
  /** Array of patients with appointment info. (filtered for the menu) */
  public patients: PatientWithAppointment[] | null = null;

  /** Helper class for dialogs. */
  private dialogHelper = new DialogHelper();
  /** Search text used to filter the list. */
  private searchText = new Subject<string>();
  /** Array of subscriptions to observables. */
  private subscriptions: Subscription[] = [];

  /**
   * Initialize instanst of the QuicklistComponent class.
   * @param dialog Material dialog service
   * @param quickListSvc Quick list service
   */
  constructor(
    private dialog: MatDialog,
    private quickListSvc: QuickListService
  ) { }

  /**
   * OnInit lifecycle hook.
   */
  ngOnInit(): void {    
    this.subscriptions.push(this.searchText.pipe(
      debounceTime(500), // add a debounce time to avoid rapid requests
      distinctUntilChanged(), // only emit if the search query changes
      switchMap(searchText => this.quickListSvc.getQuickList(searchText))
    ).subscribe(data => this.loadQuickList(data)));
    
    this.searchText.next('');
  }

  /**
   * OnDestroy lifecycle hook.
   * - Unsubscribe from observables
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  /**
   * Search as the user types in the text box.
   */
  search(event: Event): void {
    const searchText = (event.target as HTMLInputElement).value;
    this.searchText.next(searchText);
  }

  /**
   * Open dialog to view all patients in the quick list.
   */
  viewAll(event: Event): void {
    event.preventDefault();
    this.dialog.open(QuickListDialog, this.dialogHelper.extraLarge(this.patients, false));
  }

  /**
   * Load and filter quick list menu with patients.
   */
  private async loadQuickList(data: [PatientWithAppointment[], PatientLocation[]]): Promise<void> {
    const isShowAllPatientsInQuickList = await this.quickListSvc.showAllPatientsInQuickList();
    if (isShowAllPatientsInQuickList === false) {
      const dt = new Date();
      dt.setMinutes(dt.getMinutes() - 30);
      this.patients = data[0].filter(x => new Date(x.time) > dt || x.appointmentTime === null).slice(0, 8);
    }
    else {
      this.patients = data[0].slice(0, 8);
    }

  }
}
