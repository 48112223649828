import { IPatientLegacy } from '../interfaces/patient-legacy';

export const PATIENTS_LEGACY_MOCK_DATA: IPatientLegacy[] = [
    {
        'PatientId': 18772852,
        'ProviderEmployeeId': 74084,
        'LastName': 'Davis',
        'FirstName': 'Bette',
        'NickName': 'Betsy',
        'DateOfBirth': '6/8/1965',
        'Age': 58,
        'Addresses': [
            {
                'PatientId': 18772852,
                'AddressId': 26811589,
                'Address': {
                    'AddressId': 26811589,
                    'Address1': '10875 International Drive',
                    'Address2': '925-12-3887',
                    'City': 'Rancho Cordova',
                    'State': 'CA',
                    'ZipCode': '956701234',
                    'IsPrimary': true,
                    'Country': null,
                    'CountryId': 0
                }
            },
            {
                'PatientId': 18772852,
                'AddressId': 26856694,
                'Address': {
                    'AddressId': 26856694,
                    'Address1': '10875 International Drive',
                    'Address2': '925-12-3887',
                    'City': 'Rancho Cordova',
                    'State': 'CA',
                    'ZipCode': '95670',
                    'IsPrimary': false,
                    'Country': null,
                    'CountryId': 0
                }
            },
            {
                'PatientId': 18772852,
                'AddressId': 26856737,
                'Address': {
                    'AddressId': 26856737,
                    'Address1': '128888  Elsewhere',
                    'Address2': '925-12-3887',
                    'City': 'Toronto',
                    'State': 'KS',
                    'ZipCode': '66777',
                    'IsPrimary': false,
                    'Country': null,
                    'CountryId': 0
                }
            },
            {
                'PatientId': 18772852,
                'AddressId': 26856897,
                'Address': {
                    'AddressId': 26856897,
                    'Address1': '140 Stark Street',
                    'Address2': '',
                    'City': 'Birmingham',
                    'State': 'MI',
                    'ZipCode': '48009',
                    'IsPrimary': false,
                    'Country': null,
                    'CountryId': 0
                }
            },
            {
                'PatientId': 18772852,
                'AddressId': 26858026,
                'Address': {
                    'AddressId': 26858026,
                    'Address1': '1234 Somewhere Ave',
                    'Address2': 'Apt 10',
                    'City': 'Southfield',
                    'State': 'MI',
                    'ZipCode': '48075',
                    'IsPrimary': false,
                    'Country': null,
                    'CountryId': 0
                }
            }
        ],
        'Phones': [
            {
                'Id': 31420862,
                'Phone': {
                    'PhoneId': 31420862,
                    'PhoneNumber': '9168515050',
                    'Extension': '',
                    'PhoneType': 306,
                    'IsPrimary': true
                }
            },
            {
                'Id': 31437331,
                'Phone': {
                    'PhoneId': 31437331,
                    'PhoneNumber': '9165555555',
                    'Extension': '',
                    'PhoneType': 306,
                    'IsPrimary': false
                }
            }
        ],
        'IsResponsibleParty': true,
        'PatientUid': '807aec44-45f0-48b4-b35e-842d288efa79',
        'LastExamDate': '2023-08-14T15:32:38',
        'Sex': 'F'
    }
];