import { IOffice } from '../../interfaces/patient-pm';

export class Office {
    public officeNumber: string;
    public officeName: string;
    public companyId: string;
    public officeKey: string;

    constructor(office: IOffice) {
        this.officeNumber = office.OfficeNumber;
        this.officeName = office.OfficeName;
        this.companyId = office.CompanyId;
        this.officeKey = office.OfficeKey;
    }
}