/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { FindPatientsForReviewRequest } from '../classes/patient-review-request';
import { PatientDocumentIntegration } from '../classes/patient-document-integration';
import { PatientDocumentIntegrationService } from './patient-document-integration.service';
import { PatientDocumentIntegrationMockData } from '../mock-data/patient-document-integration';
import { ISearchResults } from '@pm/core';


@Injectable()
export class PatientDocumentIntegrationMockService extends PatientDocumentIntegrationService {
  
  override getPatientsReviewList(request: FindPatientsForReviewRequest): Promise<ISearchResults<PatientDocumentIntegration>> {    
    return new Promise(resolve => {
      const data : PatientDocumentIntegration[] = [ 
        new PatientDocumentIntegration(PatientDocumentIntegrationMockData()[0]), 
        new PatientDocumentIntegration(PatientDocumentIntegrationMockData()[1]), 
        new PatientDocumentIntegration(PatientDocumentIntegrationMockData()[2])
       ];  
      resolve( { Items: data, TotalAvailable: 3})
  });
  }

}
