import { IOrderPatientInsurance } from '../interfaces/order-patient-insurance';

export function OrderPatientInsurancesMockData(): IOrderPatientInsurance[] {
    return [
        {
            'PatientInsuranceId': 6982617,
            'PatientId': 18772852,
            'Subscriber': {
                'FirstName': 'TEST',
                'LastName': 'ANITHA',
                'MiddleName': null,
                'DisplayName': 'TEST ANITHA',
                'Sex': null,
                'Ssn': null,
                'SubscriberId': '3887',
                'PatientInsuranceId': 0,
                'Address': null,
                'Phone': null,
                'BirthDate': null,
                'Employer': null,
                'GroupName': '',
                'GroupNum': '',
                'RelationToSubscriberId': 0,
                'MemberId': null,
                'ConsumerId': null
            },
            'Plan': {
                'PlanId': 5661,
                'PlanName': 'Cigna Manual Plan - Vision',
                'EligibilityRule': {
                    'CarrierId': null,
                    'PlanId': null,
                    'MaxUsage': 0,
                    'Exams': 0,
                    'Frames': 0,
                    'Lenses': 0,
                    'Contacts': 0,
                    'CLFit': 0,
                    'AppliestoPrimaryAndDependents': false,
                    'AllowLensOnly': false,
                    'AllowFrameOnly': false,
                    'FrameOrContact': false,
                    'LensOrContact': false,
                    'AllowSplitExamMaterials': false,
                    'EnforceExamMaterialsOnSameDay': false,
                    'EnforceMultiplePairsOnSameDay': false,
                    'ModifiedDate': '0001-01-01T00:00:00',
                    'ModifiedBy': 0
                },
                'Carrier': {
                    'CarrierId': 'CIG         ',
                    'CarrierName': 'CIGNA',
                    'IsVsp': false,
                    'IsActive': true,
                    'IsAutoChargeOversizeLens': false,
                    'IsAutoChargeRimlessFrame': false
                },
                'IsManualPlan': false,
                'IsActive': true,
                'CalculationSource': 2
            },
            'Eligibilities': [
                {
                    'EligibilityId': 6064334,
                    'AuthNumber': '1341232132',
                    'AuthDate': '2023-12-19T00:00:00',
                    'AuthExpireDate': '2024-01-18T00:00:00',
                    'IsExamElig': false,
                    'IsFrameElig': true,
                    'IsLensElig': true,
                    'IsClElig': false,
                    'IsClFitElig': false,
                    'IsIncomplete': false,
                    'RemainingAvailableExamOrders': 1,
                    'RemainingAvailableFrameOrders': 1,
                    'RemainingAvailableLensOrders': 1,
                    'RemainingAvailableCLOrders': 1,
                    'RemainingAvailableCLFitOrders': 1,
                    'TotalAllowance': '500',
                    'TotalAllowanceRemaining': '500'
                }
            ],
            'IsIncomplete': false,
            'ShowViewRules': false
        },
        {
            'PatientInsuranceId': 6971351,
            'PatientId': 18772852,
            'Subscriber': {
                'FirstName': 'BETTE',
                'LastName': 'DAVIS',
                'MiddleName': null,
                'DisplayName': 'BETTE DAVIS',
                'Sex': null,
                'Ssn': null,
                'SubscriberId': '327578278',
                'PatientInsuranceId': 0,
                'Address': null,
                'Phone': null,
                'BirthDate': null,
                'Employer': null,
                'GroupName': 'BIS TEST GROUP DO NOT USE-TEST PURPOSES ONLY',
                'GroupNum': '1234567800890089',
                'RelationToSubscriberId': 0,
                'MemberId': null,
                'ConsumerId': null
            },
            'Plan': {
                'PlanId': 1480,
                'PlanName': 'VSP Signature',
                'EligibilityRule': {
                    'CarrierId': null,
                    'PlanId': null,
                    'MaxUsage': 0,
                    'Exams': 0,
                    'Frames': 0,
                    'Lenses': 0,
                    'Contacts': 0,
                    'CLFit': 0,
                    'AppliestoPrimaryAndDependents': false,
                    'AllowLensOnly': false,
                    'AllowFrameOnly': false,
                    'FrameOrContact': false,
                    'LensOrContact': false,
                    'AllowSplitExamMaterials': false,
                    'EnforceExamMaterialsOnSameDay': false,
                    'EnforceMultiplePairsOnSameDay': false,
                    'ModifiedDate': '0001-01-01T00:00:00',
                    'ModifiedBy': 0
                },
                'Carrier': {
                    'CarrierId': 'VSP         ',
                    'CarrierName': 'VSP',
                    'IsVsp': true,
                    'IsActive': true,
                    'IsAutoChargeOversizeLens': true,
                    'IsAutoChargeRimlessFrame': true
                },
                'IsManualPlan': false,
                'IsActive': true,
                'CalculationSource': 1
            },
            'Eligibilities': [
                {
                    'EligibilityId': 6064320,
                    'AuthNumber': '36178734',
                    'AuthDate': '2023-12-18T12:49:45',
                    'AuthExpireDate': '2024-01-17T00:00:00',
                    'IsExamElig': true,
                    'IsFrameElig': true,
                    'IsLensElig': true,
                    'IsClElig': true,
                    'IsClFitElig': true,
                    'IsIncomplete': false,
                    'RemainingAvailableExamOrders': 1,
                    'RemainingAvailableFrameOrders': 1,
                    'RemainingAvailableLensOrders': 1,
                    'RemainingAvailableCLOrders': 1,
                    'RemainingAvailableCLFitOrders': 1,
                    'TotalAllowance': '0',
                    'TotalAllowanceRemaining': '0'
                }
            ],
            'IsIncomplete': false,
            'ShowViewRules': false
        }
    ];
}