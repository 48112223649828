import { ILab } from '../interfaces/lab';

export const LABS_MOCK_DATA: ILab[] = [
    {
        LabInternalId: '501',
        Name: 'ONE Sacramento'
    },
    {
        LabInternalId: '09151',
        Name: 'Carl Zeiss Vision California'
    },
    {
        LabInternalId: '1',
        Name: 'On Site'
    }
];