import { Injectable } from '@angular/core';
import { ISearchResults } from '@pm/core';
import { Sort } from '@angular/material/sort';
import { HttpClient } from '@angular/common/http';
import { FindPatientsForReviewRequest } from '../classes/patient-review-request';
import { PatientDocumentIntegration } from '../classes/patient-document-integration';
import { IPatientDocumentIntegration } from '../interfaces/patient-document-integration';

@Injectable({
  providedIn: 'root'
})
export class PatientDocumentIntegrationService {
  /** API controller */
  private controller = 'PatientDemographics';

  /** Default sort state for failed patient orders. */
  public defaultPatientsReviewListOrderSort: Sort = { active: 'LastExamDate', direction: 'asc' };
  /**
  * Initializes instance of the PatientDemographicsService class.
  * @param http HTTP client
  */
  constructor(private http: HttpClient) { }

  /** 
   * Find patients for review from API controller
   * @param request filter request object 
   * @returns a list of patients items that needs review
   */
  async getPatientsReviewList(request : FindPatientsForReviewRequest ): Promise<ISearchResults<PatientDocumentIntegration>> {
    if (request.orderDir === '') request.orderDir = this.defaultPatientsReviewListOrderSort.direction;    
    return new Promise<ISearchResults<PatientDocumentIntegration>>(resolve => {
      const url = `${this.controller}/FindPatientsForReview`;
      this.http.post<ISearchResults<IPatientDocumentIntegration> | null>(url, request)
      .subscribe({
        next: data => resolve(data ? { Items: data.Items.map(x => new PatientDocumentIntegration(x)), TotalAvailable: data.TotalAvailable }
          : { Items: [], TotalAvailable: 0 }),
        error: () => resolve({ Items: [], TotalAvailable: 0 })
      });
    });
  }


}
