import { Component, Input } from '@angular/core';

@Component({
  selector: 'pm-app-sticky-button',
  templateUrl: './sticky-button.component.html',
  styleUrls: ['./sticky-button.component.scss'],
  standalone: true
})
export class StickyButtonComponent {
  @Input()
  /** The class we will be using for our sticky button component */
  public iconClass!: string;
}
