import { DateHelper } from '@pm/core';
import { Address } from '@pm/core/address';
import { Phone } from '@pm/core/phone';
import { IPatientDetail } from '../interfaces/patient-detail';
import { Patient } from './patient';

/**
 * Patient detail class.
 */
export class PatientDetail extends Patient {
    /** Array of addresses. */
    public addresses?: Address[] | null;
    /** Age. */
    public age?: number | null;
    /** Is responsible party? */
    public isResponsibleParty?: boolean | null;
    /** Patient uid. */
    public patientUid: string;
    /** Array of phone number. */
    public phones?: Phone[] | null;
    /** Provider employee internal id. */
    public providerId?: number | null;
    /** Sex. */
    public sex: string;

    /** Date of birth value. */
    private dateOfBirthValue: Date | null = null;
    /** Last exam date value. */
    private lastExamDateValue: Date | null = null;

    /**
     * Initializes intance of the PatientDetail class.
     * @param patient Patient detail.
     */
    constructor(patient: IPatientDetail) {
        super(patient);
        this.addresses = patient.Addresses?.map(x => new Address(x));
        this.age = patient.Age;
        this.dateOfBirth = patient.DateOfBirth;
        this.isResponsibleParty = patient.IsResponsibleParty;
        this.lastExamDate = patient.LastExamDate;
        this.patientUid = patient.PatientUid;
        this.phones = patient.Phones?.map(x => new Phone(x));
        this.providerId = patient.ProviderId;
        this.sex = patient.Sex;
    }

    /** Get date of birth as a date type. */
    get dateOfBirth(): Date | null {
        return this.dateOfBirthValue;
    }

    /** Set date of birth from a string or date type. */
    set dateOfBirth(value: Date | string | null | undefined) {
        this.dateOfBirthValue = DateHelper.convertToDate(value);
    }

    /** Get last exam date as a date type. */
    get lastExamDate(): Date | null {
        return this.lastExamDateValue;
    }

    /** Set last exam date from a string or date type. */
    set lastExamDate(value: Date | string | null | undefined) {
        this.lastExamDateValue = DateHelper.convertToDate(value);
    }
}
