/**
 * Represents a request to find patients for review based on various criteria.
 */
export class FindPatientsForReviewRequest {
    /** Internal ID for patient document integration. */
    patientDocumentIntegrationInternalId?: number;
    /** Internal ID for patient document. */
    patientDocumentInternalId?: number;
    /** Internal ID for the patient. */
    patientInternalId?: number;
    /** External ID for the company. */
    companyExternalId?: string;
    /** External ID for the office. */
    officeExternalId?: string;
    /** Start date for the appointment search range, or null if not specified. */
    appointmentStartDate: string | null;
    /** End date for the appointment search range, or null if not specified. */
    appointmentEndDate: string | null;
    /** Last name of the patient. */
    lastName?: string;
    /** First name of the patient. */
    firstName?: string;
    /** Date of birth of the patient. */
    dateOfBirth?: string;
    /** Phone number of the patient. */
    phone?: string;
    /** Domain associated with the patient. */
    domain?: string;
    /** Source of the patient data. */
    source?: string;
    /** Current status of the patient. */
    currentStatus?: string;
    /** Reason for the current status of the patient. */
    currentStatusReason?: string;
    /** Number of records to skip in the search results. */
    skip: number;
    /** Number of records to take from the search results. */
    take: number;
    /** Field to order the search results by. */
    orderBy?: string;
    /** Direction to order the search results (e.g., ascending or descending). */
    orderDir?: string;

    /**
     * Creates an instance of the FindPatientsForReviewRequest class.
     * Initializes default values for skip, take, appointmentStartDate, and appointmentEndDate.
     */
    constructor() {
        this.skip = 0;
        this.take = 1;
        this.appointmentStartDate = null;
        this.appointmentEndDate = null;
    }
}
