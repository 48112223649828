<mat-toolbar class="header-navbar">

    <!-- desktop navigation -->
    <div class="d-none d-md-flex">
        <div class="d-flex gap-1">

            <!-- patients menu -->
            <button data-ef-button="nav-patients" mat-button [matMenuTriggerFor]="menuPatients" role="button">
                {{'NAVIGATION.PATIENTS'|translate}}</button>

            <mat-menu #menuPatients="matMenu">
                <ng-template #patients matMenuContent>
                    <a data-ef-link="nav-patient-search" mat-menu-item pmNavLink="Patient/Search">
                        {{'NAVIGATION.SEARCH_ADD'|translate}}</a>
                    <a data-ef-link="nav-patient-merge" mat-menu-item pmNavLink="Patient/Merge">
                        {{'NAVIGATION.MERGE_PATIENTS'|translate}}</a>
                    <a data-ef-link="nav-patient-vsp-authorizations" id="VSPAuthorization" mat-menu-item
                        pmNavLink="Patient/VSPAuthorizations">
                        {{'NAVIGATION.VSP_AUTHORIZATIONS'|translate}}</a>
                </ng-template>
            </mat-menu>

            <!-- appointments menu -->
            <button data-ef-button="nav-appointments" mat-button [matMenuTriggerFor]="menuAppointments" role="button">
                {{'NAVIGATION.APPOINTMENTS'|translate}}</button>

            <mat-menu #menuAppointments="matMenu">
                <ng-template #appointments matMenuContent>
                    <a data-ef-link="nav-appointments-calendar" mat-menu-item pmNavLink="Appointments/Calendar"
                        (click)="clearSchedulerSessionStorage($event)">
                        {{'NAVIGATION.APPOINTMENT_CALENDAR'|translate}}</a>
                    <a data-ef-link="nav-appointments-confirmations" mat-menu-item
                        pmNavLink="Appointments/AppointmentConfirmations">
                        {{'NAVIGATION.APPOINTMENT_CONFIRMATION'|translate}}</a>
                    <a data-ef-link="nav-appointments-resource-schedule" mat-menu-item
                        pmNavLink="Appointments/ResourceSchedule">
                        {{'NAVIGATION.RESOURCE_SCHEDULE'|translate}}</a>
                    <a data-ef-link="nav-appointments-holidays" mat-menu-item pmNavLink="Appointments/Holidays">
                        {{'NAVIGATION.HOLIDAYS'|translate}}</a>
                </ng-template>
            </mat-menu>

            <!-- orders menu -->
            <button data-ef-button="nav-orders" mat-button [matMenuTriggerFor]="menuOrders" role="button">
                {{'NAVIGATION.ORDERS'|translate}}</button>

            <mat-menu #menuOrders="matMenu">
                <ng-template #orders matMenuContent>
                    <a data-ef-link="nav-orders-management" mat-menu-item pmNavLink="Management/OrderManagement">
                        {{'NAVIGATION.ORDER_MANAGEMENT'|translate}}</a>
                </ng-template>
            </mat-menu>

            <!-- catalog menu -->
            <button data-ef-button="nav-catalog" mat-button [matMenuTriggerFor]="menuCatalog" role="button">
                {{'NAVIGATION.CATALOG'|translate}}</button>

            <mat-menu #menuCatalog="matMenu">
                <ng-template #catalog matMenuContent>
                    <a data-ef-link="nav-catalog-frame" mat-menu-item pmNavLink="ProductCatalog/FrameLookup">
                        {{'NAVIGATION.FRAMES'|translate}}</a>
                    <a data-ef-link="nav-catalog-eyeglass-lens" mat-menu-item
                        pmNavLink="ProductCatalog/EyeglassLensLookup">
                        {{'NAVIGATION.EYEGLASS_LENSES'|translate}}</a>
                    <a data-ef-link="nav-catalog-contact-lens" mat-menu-item
                        pmNavLink="ProductCatalog/ContactLensLookup">
                        {{'NAVIGATION.CONTACT_LENSES'|translate}}</a>
                    <a data-ef-link="nav-catalog-accessory" mat-menu-item pmNavLink="ProductCatalog/AccessoryLookup">
                        {{'NAVIGATION.ACCESSORIES'|translate}}</a>
                    <a data-ef-link="nav-catalog-insurance-plan" mat-menu-item
                        pmNavLink="ProductCatalog/InsurancePlanLookup">
                        {{'NAVIGATION.INSURANCE_PLANS'|translate}}</a>
                </ng-template>
            </mat-menu>

            <!-- claim management menu (gt-md) -->
            <button data-ef-button="nav-claims-management" mat-button class="d-none d-lg-block"
                [matMenuTriggerFor]="menuClaims" role="button">{{'NAVIGATION.CLAIM_MANAGEMENT'|translate}}</button>

            <mat-menu #menuClaims="matMenu">
                <ng-template #claims matMenuContent>
                    <a data-ef-link="nav-claims-management" mat-menu-item pmNavLink="Billing">
                        {{'NAVIGATION.CLAIM_MANAGEMENT'|translate}}</a>
                </ng-template>
            </mat-menu>

            <!-- inventory menu (gt-md) -->
            <button data-ef-button="nav-inventory" mat-button class="d-none d-lg-block"
                [matMenuTriggerFor]="menuInventory" role="button">{{'NAVIGATION.INVENTORY'|translate}}</button>

            <mat-menu #menuInventory="matMenu">
                <ng-template #inventory matMenuContent>
                    <a data-ef-link="nav-inventory-product" mat-menu-item pmNavLink="Product/Inventory">
                        {{'NAVIGATION.PRODUCT_INVENTORY'|translate}}</a>
                </ng-template>
            </mat-menu>

            <!-- reporting menu (gt-md) -->
            <button data-ef-button="nav-reporting" mat-button class="d-none d-lg-block"
                [matMenuTriggerFor]="menuReporting" role="button">{{'NAVIGATION.REPORTING'|translate}}</button>

            <mat-menu #menuReporting="matMenu">
                <ng-template #reporting matMenuContent>
                    <a data-ef-link="nav-reporting-my-dashboard" mat-menu-item pmNavLink="Reporting/MyDashboardReports"
                        *ngIf="hasDashboardRole">
                        {{'NAVIGATION.DASHBOARD'|translate}}</a>
                    <a data-ef-link="nav-reporting-my-reports" mat-menu-item pmNavLink="Reporting/MyReports">
                        {{'NAVIGATION.STANDARD_REPORTS'|translate}}</a>
                    <a data-ef-link="nav-reporting-analytics-insights" mat-menu-item
                        pmNavLink="Reporting/AnalyticsAndInsights"
                        *ngIf="isAnalyticsAndInsightsEnabled">{{'NAVIGATION.ANALYTICS'|translate}}</a>
                </ng-template>
            </mat-menu>

            <!-- more menu (md) -->
            <button data-ef="nav-more" mat-button class="d-block d-lg-none" [matMenuTriggerFor]="menuMore"
                role="button">{{'NAVIGATION.MORE'|translate}}</button>

            <mat-menu #menuMore="matMenu">
                <a data-ef-link="nav-more-billing" mat-menu-item pmNavLink="Billing">
                    {{'NAVIGATION.CLAIM_MANAGEMENT'|translate}}</a>
                <a data-ef-link="nav-more-inventory" mat-menu-item pmNavLink="Product/Inventory">
                    {{'NAVIGATION.PRODUCT_INVENTORY'|translate}}</a>
                <a data-ef-link="nav-more-reporting" mat-menu-item [matMenuTriggerFor]="menuReporting">
                    {{'NAVIGATION.REPORTING'|translate}}</a>
            </mat-menu>

        </div>
    </div>

    <!-- mobile navigation -->
    <div class="d-flex d-md-none flex-fill">
        <button data-ef-button="nav-mobile-menu" mat-icon-button [matMenuTriggerFor]="menuMobile" role="button">
            <mat-icon>menu</mat-icon>
        </button>
        <mat-menu #menuMobile="matMenu" class="mobile-nav-menu">
            <mat-accordion>

                <!-- patients -->
                <mat-expansion-panel>
                    <mat-expansion-panel-header (click)="$event.stopPropagation()">
                        <mat-panel-title>{{'NAVIGATION.PATIENTS'|translate}}</mat-panel-title>
                    </mat-expansion-panel-header>
                    <ng-container [ngTemplateOutlet]="patients"></ng-container>
                </mat-expansion-panel>

                <!-- appointments -->
                <mat-expansion-panel>
                    <mat-expansion-panel-header (click)="$event.stopPropagation()">
                        <mat-panel-title>{{'NAVIGATION.APPOINTMENTS'|translate}}</mat-panel-title>
                    </mat-expansion-panel-header>
                    <ng-container [ngTemplateOutlet]="appointments"></ng-container>
                </mat-expansion-panel>

                <!-- orders -->
                <mat-expansion-panel>
                    <mat-expansion-panel-header (click)="$event.stopPropagation()">
                        <mat-panel-title>{{'NAVIGATION.ORDERS'|translate}}</mat-panel-title>
                    </mat-expansion-panel-header>
                    <ng-container [ngTemplateOutlet]="orders"></ng-container>
                </mat-expansion-panel>

                <!-- catalog -->
                <mat-expansion-panel>
                    <mat-expansion-panel-header (click)="$event.stopPropagation()">
                        <mat-panel-title>{{'NAVIGATION.CATALOG'|translate}}</mat-panel-title>
                    </mat-expansion-panel-header>
                    <ng-container [ngTemplateOutlet]="catalog"></ng-container>
                </mat-expansion-panel>

                <!-- claim management -->
                <mat-expansion-panel>
                    <mat-expansion-panel-header (click)="$event.stopPropagation()">
                        <mat-panel-title>{{'NAVIGATION.CLAIM_MANAGEMENT'|translate}}</mat-panel-title>
                    </mat-expansion-panel-header>
                    <ng-container [ngTemplateOutlet]="claims"></ng-container>
                </mat-expansion-panel>

                <!-- inventiory -->
                <mat-expansion-panel>
                    <mat-expansion-panel-header (click)="$event.stopPropagation()">
                        <mat-panel-title>{{'NAVIGATION.INVENTORY'|translate}}</mat-panel-title>
                    </mat-expansion-panel-header>
                    <ng-container [ngTemplateOutlet]="inventory"></ng-container>
                </mat-expansion-panel>

                <!-- reporting -->
                <mat-expansion-panel>
                    <mat-expansion-panel-header (click)="$event.stopPropagation()">
                        <mat-panel-title>{{'NAVIGATION.REPORTING'|translate}}</mat-panel-title>
                    </mat-expansion-panel-header>
                    <ng-container [ngTemplateOutlet]="reporting"></ng-container>
                </mat-expansion-panel>

            </mat-accordion>
        </mat-menu>
    </div>

    <!-- quick list -->
    <div class="d-flex flex-fill justify-content-end">
        <button data-ef-button="nav-quick-list" mat-button [matMenuTriggerFor]="menuQuickList" role="button">
            {{'NAVIGATION.QUICK_LIST'|translate}}</button>
        <mat-menu #menuQuickList="matMenu" class="no-max-width" xPosition="before">
            <ng-template matMenuContent>
                <pm-quick-list></pm-quick-list>
            </ng-template>
        </mat-menu>

        <!-- appointment calendar fullscreen -->
        <ng-template [ngIf]="mode==='calendar'">
            <button data-ef-button="nav-calendar-enter-fullscreen" mat-icon-button role="button"
                (click)="calendarEnterFullscreen()" *ngIf="!isFullscreen">
                <mat-icon>fullscreen</mat-icon>
            </button>
            <button data-ef-button="nav-calendar-exit-fullscreen" mat-icon-button role="button"
                (click)="calendarExitFullscreen()" *ngIf="isFullscreen">
                <mat-icon>close_fullscreen</mat-icon>
            </button>
        </ng-template>

    </div>
</mat-toolbar>