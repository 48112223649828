import { ICatalogItem } from '../interfaces/catalog-item';

/**
 * Catalog item class.
 */
export class CatalogItem {
    /** Adjusted price. */
    public adjustedPrice: number;
    /** Allow zero price. */
    public allowZeroPrice?: boolean;
    /** Item id. */
    public itemId: number;
    /** Item number. */
    public itemNumber: string;
    /** Company item name. */
    public name?: string | null;
    /** Price. */
    public price: number;
    /** List Price. */
    public listPrice: number;
    /** Quantity on hand. */
    public onHandQty?: number;
    /** Quantity for order. */
    public qty = 0;
    /** UPC code. */
    public upc?: string | null;

    /**
     * Initializes instance of the CatalogItem class.
     * @param item Catalog item data
     */
    constructor(item: ICatalogItem | CatalogItem) {
        if (item instanceof CatalogItem) {
            this.allowZeroPrice = item.allowZeroPrice;
            this.itemId = item.itemId;
            this.itemNumber = item.itemNumber;
            this.name = item.name;
            this.price = this.adjustedPrice = item.price;
            this.listPrice = item.listPrice;
            this.onHandQty = item.onHandQty;
            this.upc = item.upc;
        } else {
            this.allowZeroPrice = item.AllowZeroPrice;
            this.itemId = item.ItemID;
            this.itemNumber = item.ItemNumber;
            this.name = item.ItemName;
            this.price = this.adjustedPrice = this.convertPrice(item.Price);
            this.listPrice = this.convertPrice(item.ListPrice);
            this.onHandQty = item.OnHandQuantity;
            this.upc = item.UpcNumber;
        }
    }

    /** Can adjust price when retail price is zero. */
    get canAdjustPrice(): boolean {
        return this.price === 0;
    }

    /** Is adjusted price valid. */
    get isAdjustedPriceValid(): boolean {
        return (this.allowZeroPrice && this.adjustedPrice === 0) || this.adjustedPrice > 0;
    }

    /**
     * Convert price into number. 
     * @param price Price value to convert
     * @returns 
     */
    protected convertPrice(price: unknown): number {
        if (typeof price === 'number') {
            return price;
        } else if (typeof price === 'string') {
            return Number(price.replace(/[^0-9.]+/g, ''));
        } else {
            return 0;
        }
    }
}
