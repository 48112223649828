import { Patient } from './patient';
import { IPatientReview } from '../interfaces/patient-review';

/**
 * Represents a detailed review of a patient, extending the basic patient information.
 */
export class PatientReview extends Patient {    
    /** External ID for the company. */
    public companyExternalId: string;
    /** Middle name of the patient. */
    public middleName: string;
    /** Internal ID for the suffix. */
    public suffixInternalId?: number;
    /** Birth sex of the patient. */
    public birthSex: string;
    /** Social Security Number of the patient. */
    public ssn: string;
    /** Date of birth of the patient. */
    public dob: Date;
    /** Internal ID for the title. */
    public titleInternalId: number;
    /** Internal ID for the responsible party. */
    public responsibleInternalId: number;
    /** Internal ID for the referral source. */
    public referralInternalId: number;
    /** Indicates if the patient is deceased. */
    public deceased: boolean;
    /** Email address of the patient. */
    public email: string;
    /** Indicates if the patient has special needs. */
    public specialNeeds: boolean;
    /** Indicates if the patient has diabetes. */
    public diabetes: boolean;
    /** Medical record number of the patient. */
    public medicalRecordNum: string;
    /** Date of the last exam of the patient. */
    public lastExamDate: Date;
    /** Indicates if the patient data is migrated. */
    public isMigrated: boolean;
    /** Home office of the patient. */
    public homeOffice: string;
    /** Exam office of the patient. */
    public examOffice: string;
    /** Reference number for the patient. */
    public referenceNumber: string;
    /** Indicates if the patient is active. */
    public isActive: boolean;
    /** Indicates if the individual is a patient. */
    public isPatient: boolean;
    /** Indicates if the individual is a responsible party. */
    public isResponsibleParty: boolean;
    /** Unique identifier for the patient. */
    public patientId: string; 
    /** External ID for the member. */
    public memberExternalId: string;
    /** Indicates if the patient is on hold. */
    public isPatientHold: boolean;
    /** Count of insurance policies associated with the patient. */
    public insuranceCount: number;
    /** Array of internal IDs for the patient's addresses. */
    public addressInternalIds: number[] = [];
    /** Array of internal IDs for the patient's phone numbers. */
    public phoneInternalIds: number[] = [];
    /** Birth date of the patient. */
    public birthDate?: Date; 
    /** Internal ID for the patient's race. */
    public raceInternalId?: number; 
    /** Internal ID for the patient's ethnicity. */
    public ethnicityInternalId?: number; 
    /** Internal ID for the patient's employment status. */
    public employmentStatusInternalId: number;
    /** Internal ID for the patient's preferred language. */
    public preferredLanguageInternalId?: number; 
    /** Internal ID for the patient's communication preference. */
    public communicationPreferenceInternalId?: number; 
    /** Internal ID for the patient's marital status. */
    public maritalStatusInternalId?: number; 
    /** Internal ID for the patient's occupation. */
    public occupationInternalId?: number; 

    /**
     * Creates an instance of the PatientReview class.
     * @param patientData - An object containing detailed patient review data.
     */
    constructor(patientData: IPatientReview) {
        super(patientData);        
        this.companyExternalId = patientData.CompanyExternalId;
        this.middleName = patientData.MiddleName;
        this.suffixInternalId = patientData.SuffixInternalId;
        this.birthSex = patientData.BirthSex;
        this.ssn = patientData.SSN;
        this.dob = patientData.DOB;
        this.titleInternalId = patientData.TitleInternalId;
        this.responsibleInternalId = patientData.ResponsibleInternalId;
        this.referralInternalId = patientData.ReferralInternalId;
        this.deceased = patientData.Deceased;
        this.email = patientData.Email;
        this.specialNeeds = patientData.SpecialNeeds;
        this.diabetes = patientData.Diabetes;
        this.medicalRecordNum = patientData.MedicalRecordNum;
        this.lastExamDate = patientData.LastExamDate;
        this.isMigrated = patientData.IsMigrated;
        this.homeOffice = patientData.HomeOffice;
        this.examOffice = patientData.ExamOffice;
        this.referenceNumber = patientData.ReferenceNumber;
        this.isActive = patientData.IsActive;
        this.isPatient = patientData.IsPatient;
        this.isResponsibleParty = patientData.IsResponsibleParty;
        this.patientId = patientData.PatientId;
        this.memberExternalId = patientData.MemberExternalId;
        this.isPatientHold = patientData.IsPatientHold;
        this.insuranceCount = patientData.InsuranceCount;
        this.addressInternalIds = patientData.AddressInternalIds;
        this.phoneInternalIds = patientData.PhoneInternalIds;
        this.birthDate = patientData.BirthDate;
        this.raceInternalId = patientData.RaceInternalId;
        this.ethnicityInternalId = patientData.EthnicityInternalId;
        this.employmentStatusInternalId = patientData.EmploymentStatusInternalId;
        this.preferredLanguageInternalId = patientData.PreferredLanguageInternalId;
        this.communicationPreferenceInternalId = patientData.CommunicationPreferenceInternalId;
        this.maritalStatusInternalId = patientData.MaritalStatusInternalId;
        this.occupationInternalId = patientData.OccupationInternalId;
    }    
}
