import { IPatientAppointment } from '../interfaces/patient-appointment';

export function MockPatientAppointments(): IPatientAppointment[] {
  return [
    {
      AppointmentId: 1,
      AppointmentDate: '2023-01-01',
      AppointmentTime: '10:00 AM',
      DoctorFullName: 'Dr. John Smith',
      AppointmentType: 'Routine Checkup',
      AppointmentShowStatus: 'Showed',
      AppointmentConfirmationStatus: 'Confirmed',
      AppointmentDateTime: '2023-01-01T10:00:00Z',
      OfficeId: 'OFFICE001'
    },
    {
      AppointmentId: 2,
      AppointmentDate: '2023-01-02',
      AppointmentTime: '11:00 AM',
      DoctorFullName: 'Dr. Jane Doe',
      AppointmentType: 'Consultation',
      AppointmentShowStatus: 'No Show',
      AppointmentConfirmationStatus: 'Pending',
      AppointmentDateTime: '2023-01-02T11:00:00Z',
      OfficeId: 'OFFICE002'
    },
    {
      AppointmentId: 3,
      AppointmentDate: '2023-01-03',
      AppointmentTime: '12:00 PM',
      DoctorFullName: 'Dr. Emily Johnson',
      AppointmentType: 'Follow-up',
      AppointmentShowStatus: 'Cancelled',
      AppointmentConfirmationStatus: 'Cancelled',
      AppointmentDateTime: '2023-01-03T12:00:00Z',
      OfficeId: 'OFFICE003'
    }
  ];
}