<mat-card class="notifications">
    <mat-card-header>
        <mat-card-title>{{ 'ALERTS.NOTIFICATIONS_TITLE' | translate }}</mat-card-title>
    </mat-card-header>

    <mat-card-content #content class="d-flex flex-column pt-3">
        
        <ng-container>
            <pm-store-operations-alert #storeOperationsAlert [options]="{isGrouped:true}"></pm-store-operations-alert>
            <ng-content *ngTemplateOutlet="storeOperationsAlert.template"></ng-content>
        </ng-container>

        <ng-container>
            <pm-frame-data-availability-alert #frameDataAvailabilityAlert [options]="{isGrouped:true}"></pm-frame-data-availability-alert>
            <ng-content *ngTemplateOutlet="frameDataAvailabilityAlert.template"></ng-content>
        </ng-container>

        <ng-container>
            <pm-frame-board-management-alert #frameBoardManagementAlert [options]="{isGrouped:true}"></pm-frame-board-management-alert>
            <ng-content *ngTemplateOutlet="frameBoardManagementAlert.template"></ng-content>
        </ng-container>

        <ng-container>
            <pm-review-patients-alert #reviewPatientsAlert [options]="{isGrouped:true}"></pm-review-patients-alert>
            <ng-content *ngTemplateOutlet="reviewPatientsAlert.template"></ng-content>
        </ng-container>

    </mat-card-content>
</mat-card>