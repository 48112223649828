import { ICptModifierCode } from '../interfaces/cpt-modifier-code';

export function ModifierCodesMockData(): ICptModifierCode[] {
    return [
        {
            'ModifierCode': '21',
            'Description': 'Prolonged eval mgmt',
            'IsActive': true,
            'CptModifierCodeInternalId': 16
        },
        {
            'ModifierCode': '22',
            'Description': 'Increased services\r\n',
            'IsActive': true,
            'CptModifierCodeInternalId': 17
        },
        {
            'ModifierCode': '23',
            'Description': 'Unusual anesthesia\r\n',
            'IsActive': true,
            'CptModifierCodeInternalId': 18
        },
        {
            'ModifierCode': '24',
            'Description': 'Unrelated mgmt same physician\r\n',
            'IsActive': true,
            'CptModifierCodeInternalId': 19
        },
        {
            'ModifierCode': '25',
            'Description': 'Separate eval mgmt same physician\r\n',
            'IsActive': true,
            'CptModifierCodeInternalId': 20
        },
        {
            'ModifierCode': '26',
            'Description': 'Professional component\r\n',
            'IsActive': true,
            'CptModifierCodeInternalId': 21
        },
        {
            'ModifierCode': '27',
            'Description': '27',
            'IsActive': true,
            'CptModifierCodeInternalId': 22
        },
        {
            'ModifierCode': '32',
            'Description': 'Mandated services\r\n',
            'IsActive': true,
            'CptModifierCodeInternalId': 23
        },
        {
            'ModifierCode': '47',
            'Description': 'Anesthesia by surgeon\r\n',
            'IsActive': true,
            'CptModifierCodeInternalId': 24
        },
        {
            'ModifierCode': '50',
            'Description': 'Bilateral procedure\r\n',
            'IsActive': true,
            'CptModifierCodeInternalId': 25
        },
        {
            'ModifierCode': '51',
            'Description': 'Multiple procedures\r\n',
            'IsActive': true,
            'CptModifierCodeInternalId': 26
        },
        {
            'ModifierCode': '52',
            'Description': 'Reduced service',
            'IsActive': true,
            'CptModifierCodeInternalId': 6
        },
        {
            'ModifierCode': '53',
            'Description': 'Discontinued procedure\r\n',
            'IsActive': true,
            'CptModifierCodeInternalId': 27
        },
        {
            'ModifierCode': '54',
            'Description': 'Surgical care only\r\n',
            'IsActive': true,
            'CptModifierCodeInternalId': 28
        },
        {
            'ModifierCode': '55',
            'Description': 'Post Op Services',
            'IsActive': true,
            'CptModifierCodeInternalId': 9
        },
        {
            'ModifierCode': '56',
            'Description': 'Preop mgmt\r\n',
            'IsActive': true,
            'CptModifierCodeInternalId': 29
        },
        {
            'ModifierCode': '57',
            'Description': 'Eval mgmt result in surgery\r\n',
            'IsActive': true,
            'CptModifierCodeInternalId': 30
        },
        {
            'ModifierCode': '58',
            'Description': 'Postop related procedure\r\n',
            'IsActive': true,
            'CptModifierCodeInternalId': 31
        },
        {
            'ModifierCode': '59',
            'Description': 'Distinct service\r\n',
            'IsActive': true,
            'CptModifierCodeInternalId': 32
        },
        {
            'ModifierCode': '60',
            'Description': '60',
            'IsActive': true,
            'CptModifierCodeInternalId': 33
        },
        {
            'ModifierCode': '62',
            'Description': 'Co-surgeons\r\n',
            'IsActive': true,
            'CptModifierCodeInternalId': 34
        },
        {
            'ModifierCode': '63',
            'Description': '63',
            'IsActive': true,
            'CptModifierCodeInternalId': 35
        },
        {
            'ModifierCode': '66',
            'Description': 'Team surgeons\r\n',
            'IsActive': true,
            'CptModifierCodeInternalId': 36
        },
        {
            'ModifierCode': '72',
            'Description': '72',
            'IsActive': true,
            'CptModifierCodeInternalId': 37
        },
        {
            'ModifierCode': '73',
            'Description': 'Discontinued hospital\r\n',
            'IsActive': true,
            'CptModifierCodeInternalId': 38
        },
        {
            'ModifierCode': '74',
            'Description': 'Post anesthesia discontinued hospital\r\n',
            'IsActive': true,
            'CptModifierCodeInternalId': 39
        },
        {
            'ModifierCode': '76',
            'Description': 'Repeat procedure\r\n',
            'IsActive': true,
            'CptModifierCodeInternalId': 40
        },
        {
            'ModifierCode': '77',
            'Description': 'Unassigned',
            'IsActive': true,
            'CptModifierCodeInternalId': 90
        },
        {
            'ModifierCode': '78',
            'Description': 'Post op related surgery\r\n',
            'IsActive': true,
            'CptModifierCodeInternalId': 41
        },
        {
            'ModifierCode': '79',
            'Description': 'Unassigned',
            'IsActive': true,
            'CptModifierCodeInternalId': 91
        },
        {
            'ModifierCode': '80',
            'Description': 'Assistant surgeon\r\n',
            'IsActive': true,
            'CptModifierCodeInternalId': 42
        },
        {
            'ModifierCode': '81',
            'Description': 'Minimum assistant surgeon\r\n',
            'IsActive': true,
            'CptModifierCodeInternalId': 43
        },
        {
            'ModifierCode': '82',
            'Description': 'Assistant surgeon\r\n',
            'IsActive': true,
            'CptModifierCodeInternalId': 44
        },
        {
            'ModifierCode': '89',
            'Description': '89',
            'IsActive': true,
            'CptModifierCodeInternalId': 45
        },
        {
            'ModifierCode': '90',
            'Description': 'Unassigned',
            'IsActive': true,
            'CptModifierCodeInternalId': 92
        },
        {
            'ModifierCode': '91',
            'Description': 'Unassigned',
            'IsActive': true,
            'CptModifierCodeInternalId': 93
        },
        {
            'ModifierCode': '99',
            'Description': 'Multiple modifiers\r\n',
            'IsActive': true,
            'CptModifierCodeInternalId': 46
        },
        {
            'ModifierCode': 'A1',
            'Description': 'Dressing one wound\r\n',
            'IsActive': true,
            'CptModifierCodeInternalId': 47
        },
        {
            'ModifierCode': 'A2',
            'Description': 'Dressing two wounds\r\n',
            'IsActive': true,
            'CptModifierCodeInternalId': 48
        },
        {
            'ModifierCode': 'A3',
            'Description': 'Dressing three wounds\r\n',
            'IsActive': true,
            'CptModifierCodeInternalId': 49
        },
        {
            'ModifierCode': 'A4',
            'Description': 'Dressing four wounds\r\n',
            'IsActive': true,
            'CptModifierCodeInternalId': 50
        },
        {
            'ModifierCode': 'BB',
            'Description': 'BB',
            'IsActive': true,
            'CptModifierCodeInternalId': 51
        },
        {
            'ModifierCode': 'CG',
            'Description': 'Policy criteria applied\r\n',
            'IsActive': true,
            'CptModifierCodeInternalId': 52
        },
        {
            'ModifierCode': 'E1',
            'Description': 'Upper Lid Left',
            'IsActive': true,
            'CptModifierCodeInternalId': 2
        },
        {
            'ModifierCode': 'E2',
            'Description': 'Lower Lid Left',
            'IsActive': true,
            'CptModifierCodeInternalId': 3
        },
        {
            'ModifierCode': 'E3',
            'Description': 'Upper Lid Right',
            'IsActive': true,
            'CptModifierCodeInternalId': 4
        },
        {
            'ModifierCode': 'E4',
            'Description': 'Lower Lid Right',
            'IsActive': true,
            'CptModifierCodeInternalId': 5
        },
        {
            'ModifierCode': 'ED',
            'Description': 'ED',
            'IsActive': true,
            'CptModifierCodeInternalId': 81
        },
        {
            'ModifierCode': 'GA',
            'Description': 'ABN issued and on file\r\n as Required',
            'IsActive': true,
            'CptModifierCodeInternalId': 82
        },
        {
            'ModifierCode': 'GC',
            'Description': 'Resident perfomed service\r\n',
            'IsActive': true,
            'CptModifierCodeInternalId': 83
        },
        {
            'ModifierCode': 'GL',
            'Description': 'Medically unnecessary upgrade provided',
            'IsActive': true,
            'CptModifierCodeInternalId': 53
        },
        {
            'ModifierCode': 'GP',
            'Description': 'Services delivered outpt PT plan of care',
            'IsActive': true,
            'CptModifierCodeInternalId': 110
        },
        {
            'ModifierCode': 'GT',
            'Description': 'GT',
            'IsActive': true,
            'CptModifierCodeInternalId': 54
        },
        {
            'ModifierCode': 'GW',
            'Description': 'Svc not related to hospice pt\'s term cond',
            'IsActive': true,
            'CptModifierCodeInternalId': 101
        },
        {
            'ModifierCode': 'GX',
            'Description': 'ABN issued and on file as Voluntary',
            'IsActive': true,
            'CptModifierCodeInternalId': 96
        },
        {
            'ModifierCode': 'GY',
            'Description': 'Item or service statutorily excluded (ABN)',
            'IsActive': true,
            'CptModifierCodeInternalId': 95
        },
        {
            'ModifierCode': 'GZ',
            'Description': 'Item expected to be denied (ABN)',
            'IsActive': true,
            'CptModifierCodeInternalId': 94
        },
        {
            'ModifierCode': 'HI',
            'Description': 'HI',
            'IsActive': true,
            'CptModifierCodeInternalId': 55
        },
        {
            'ModifierCode': 'HU',
            'Description': 'HU',
            'IsActive': true,
            'CptModifierCodeInternalId': 56
        },
        {
            'ModifierCode': 'KX',
            'Description': 'Specific required documentation on file',
            'IsActive': true,
            'CptModifierCodeInternalId': 13
        },
        {
            'ModifierCode': 'L1',
            'Description': 'L1',
            'IsActive': true,
            'CptModifierCodeInternalId': 57
        },
        {
            'ModifierCode': 'L2',
            'Description': 'L2',
            'IsActive': true,
            'CptModifierCodeInternalId': 58
        },
        {
            'ModifierCode': 'L3',
            'Description': 'L3',
            'IsActive': true,
            'CptModifierCodeInternalId': 59
        },
        {
            'ModifierCode': 'L4',
            'Description': 'L4',
            'IsActive': true,
            'CptModifierCodeInternalId': 60
        },
        {
            'ModifierCode': 'L5',
            'Description': 'L5',
            'IsActive': true,
            'CptModifierCodeInternalId': 61
        },
        {
            'ModifierCode': 'LT',
            'Description': 'Left Eye',
            'IsActive': true,
            'CptModifierCodeInternalId': 7
        },
        {
            'ModifierCode': 'ND',
            'Description': 'ND',
            'IsActive': true,
            'CptModifierCodeInternalId': 97
        },
        {
            'ModifierCode': 'NU',
            'Description': 'Initial Equipment',
            'IsActive': true,
            'CptModifierCodeInternalId': 11
        },
        {
            'ModifierCode': 'PL',
            'Description': 'Progressive lenses\r\n',
            'IsActive': true,
            'CptModifierCodeInternalId': 62
        },
        {
            'ModifierCode': 'PM',
            'Description': 'Post mortem',
            'IsActive': true,
            'CptModifierCodeInternalId': 63
        },
        {
            'ModifierCode': 'PP',
            'Description': 'PP',
            'IsActive': true,
            'CptModifierCodeInternalId': 64
        },
        {
            'ModifierCode': 'PS',
            'Description': 'PS',
            'IsActive': true,
            'CptModifierCodeInternalId': 84
        },
        {
            'ModifierCode': 'QW',
            'Description': 'CLIA waiver\r\n',
            'IsActive': true,
            'CptModifierCodeInternalId': 10
        },
        {
            'ModifierCode': 'RA',
            'Description': 'Replacement Equipment',
            'IsActive': true,
            'CptModifierCodeInternalId': 12
        },
        {
            'ModifierCode': 'RB',
            'Description': 'Replacement furnished as part of a repair',
            'IsActive': true,
            'CptModifierCodeInternalId': 65
        },
        {
            'ModifierCode': 'RP',
            'Description': 'RP',
            'IsActive': true,
            'CptModifierCodeInternalId': 66
        },
        {
            'ModifierCode': 'RS',
            'Description': 'RS',
            'IsActive': true,
            'CptModifierCodeInternalId': 67
        },
        {
            'ModifierCode': 'RT',
            'Description': 'Right Eye',
            'IsActive': true,
            'CptModifierCodeInternalId': 8
        },
        {
            'ModifierCode': 'S0580',
            'Description': 'S0580',
            'IsActive': true,
            'CptModifierCodeInternalId': 85
        },
        {
            'ModifierCode': 'SC',
            'Description': 'Medically necessary bifocal\r\n',
            'IsActive': true,
            'CptModifierCodeInternalId': 14
        },
        {
            'ModifierCode': 'SL',
            'Description': 'SL',
            'IsActive': true,
            'CptModifierCodeInternalId': 68
        },
        {
            'ModifierCode': 'ST',
            'Description': 'Related to injury\r\n',
            'IsActive': true,
            'CptModifierCodeInternalId': 69
        },
        {
            'ModifierCode': 'SW',
            'Description': 'Services provided by a certified diabetic educator',
            'IsActive': true,
            'CptModifierCodeInternalId': 70
        },
        {
            'ModifierCode': 'TC',
            'Description': 'Technical component\r\n',
            'IsActive': true,
            'CptModifierCodeInternalId': 71
        },
        {
            'ModifierCode': 'TG',
            'Description': 'Medically necessary trifocal\r\n',
            'IsActive': true,
            'CptModifierCodeInternalId': 15
        },
        {
            'ModifierCode': 'TM',
            'Description': 'TM',
            'IsActive': true,
            'CptModifierCodeInternalId': 86
        },
        {
            'ModifierCode': 'TS',
            'Description': 'Follow up service\r\n',
            'IsActive': true,
            'CptModifierCodeInternalId': 87
        },
        {
            'ModifierCode': 'U1',
            'Description': 'U1',
            'IsActive': true,
            'CptModifierCodeInternalId': 88
        },
        {
            'ModifierCode': 'U2',
            'Description': 'U2',
            'IsActive': true,
            'CptModifierCodeInternalId': 89
        },
        {
            'ModifierCode': 'VC',
            'Description': 'VC',
            'IsActive': true,
            'CptModifierCodeInternalId': 72
        },
        {
            'ModifierCode': 'VG',
            'Description': 'VG',
            'IsActive': true,
            'CptModifierCodeInternalId': 73
        },
        {
            'ModifierCode': 'VH',
            'Description': 'VH',
            'IsActive': true,
            'CptModifierCodeInternalId': 74
        },
        {
            'ModifierCode': 'VI',
            'Description': 'VI',
            'IsActive': true,
            'CptModifierCodeInternalId': 75
        },
        {
            'ModifierCode': 'VP',
            'Description': 'VP',
            'IsActive': true,
            'CptModifierCodeInternalId': 76
        },
        {
            'ModifierCode': 'WR',
            'Description': 'WR',
            'IsActive': true,
            'CptModifierCodeInternalId': 77
        },
        {
            'ModifierCode': 'XC',
            'Description': 'Specific required documentation on file',
            'IsActive': true,
            'CptModifierCodeInternalId': 107
        },
        {
            'ModifierCode': 'XE',
            'Description': 'Separate encounter',
            'IsActive': true,
            'CptModifierCodeInternalId': 106
        },
        {
            'ModifierCode': 'XP',
            'Description': 'Separate Practitioner',
            'IsActive': true,
            'CptModifierCodeInternalId': 104
        },
        {
            'ModifierCode': 'XS',
            'Description': 'Separate Structure',
            'IsActive': true,
            'CptModifierCodeInternalId': 105
        },
        {
            'ModifierCode': 'XU',
            'Description': 'Unusual Non-Overlapping Service',
            'IsActive': true,
            'CptModifierCodeInternalId': 103
        },
        {
            'ModifierCode': 'XV',
            'Description': 'XV',
            'IsActive': true,
            'CptModifierCodeInternalId': 78
        },
        {
            'ModifierCode': 'YF',
            'Description': 'YF',
            'IsActive': true,
            'CptModifierCodeInternalId': 79
        },
        {
            'ModifierCode': 'ZI',
            'Description': 'ZI',
            'IsActive': true,
            'CptModifierCodeInternalId': 80
        }
    ];
}