import { IPatientPM } from '../interfaces/patient-pm';
import { Patient } from './patient';
import { Office } from './patient-pm/office';
import { PatientAddress } from './patient-pm/patient-address';
import { PatientPhone } from './patient-pm/patient-phone';

/** 
 * Class created based on Eyefinity.PracticeManagement.Model.PatientPM 
 * */
export class PatientPM extends Patient {    
     /** Company ID */
     public companyId: string;
     /** Home office location */
     public homeOffice: string;
     /** Exam office location */
     public examOffice: string;
     /** Patient ID */
     public patientId: number;
     /** Provider employee ID */
     public providerEmployeeId: number;
     /** Employment status */
     public employmentStatus: number;
     /** Date of birth */
     public dateOfBirth: string;
     /** Age */
     public age: number;
     /** List of patient addresses */
     public addresses: PatientAddress[];
     /** List of patient phones */
     public phones: PatientPhone[];
     /** Office details */
     public office: Office;
     /** Inactive status */
     public inActive: boolean;
     /** Patient status */
     public isPatient: boolean;
     /** Responsible party status */
     public isResponsibleParty: boolean;
     /** EMR patient ID */
     public emrPatientId: string;
     /** Patient UID */
     public patientUid: string;
     /** Old patient number */
     public patientNumOld: number;
     /** Date of last exam */
     public lastExamDate: string;
     /** Sex */
     public sex: string;
     /** Lifestyle question enabled status */
     public isLifeStyleQuestionEnabled: boolean;
     /** Price transparency enabled status */
     public isPriceTransparencyEnabled: boolean;

    constructor (patient: IPatientPM) {
        super(patient);
        this.companyId = patient.CompanyId;
        this.homeOffice = patient.HomeOffice;
        this.examOffice = patient.ExamOffice;
        this.patientId = patient.PatientId;
        this.providerEmployeeId = patient.ProviderEmployeeId;
        this.employmentStatus = patient.EmploymentStatus;
        this.dateOfBirth = patient.DateOfBirth;
        this.age = patient.Age;
        this.addresses = patient.Addresses.map(address => new PatientAddress(address));
        this.phones = patient.Phones.map(phone => new PatientPhone(phone));
        this.office = new Office(patient.Office);
        this.inActive = patient.InActive;
        this.isPatient = patient.IsPatient;
        this.isResponsibleParty = patient.IsResponsibleParty;
        this.emrPatientId = patient.EmrPatientId;
        this.patientUid = patient.PatientUid;
        this.patientNumOld = patient.PatientNumOld;
        this.lastExamDate = patient.LastExamDate;
        this.sex = patient.Sex;
        this.isLifeStyleQuestionEnabled = patient.IsLifeStyleQuestionEnabled;
        this.isPriceTransparencyEnabled = patient.IsPriceTransparencyEnabled;
    }
}
