import { IAppContent } from '../interfaces/app-content';

/**
 * Application logo class.
 */
export class AppContent {
    /** Full application name. */
    public appNameLong: string;
    /** Abbreviated application name. */
    public appNameShort: string;
    /** Path for the application logo. (SVG for dark background) */
    public appLogoSvgForDarkBackground: string;
    /** Path for the application logo. (SVG for light background) */
    public appLogoSvgForLightBackground: string;
    /** Path for the application logo with premier. (SVG for dark background) */
    public appLogoWithPremierSvgForDarkBackground: string;
    /** Path for the application logo with premier. (SVG for light background) */
    public appLogoWithPremierSvgForLightBackground: string;
    /** Path for the premier logo. (SVG for dark background) */
    public premierLogoSvgForDarkBackground: string;
    /** Path for the premier logo. (SVG for light background) */
    public premierLogoSvgForLightBackground: string;

    /**
     * Initializes intance of the AppLogo class.
     * @param logo Logo paths for the application logo
     */
    constructor(content: IAppContent) {
        this.appNameLong = content.AppNameLong;
        this.appNameShort = content.AppNameShort;
        this.appLogoSvgForDarkBackground = content.AppLogoSvgForDarkBackground;
        this.appLogoSvgForLightBackground = content.AppLogoSvgForLightBackground;
        this.appLogoWithPremierSvgForDarkBackground = content.AppLogoWithPremierSvgForDarkBackground;
        this.appLogoWithPremierSvgForLightBackground = content.AppLogoWithPremierSvgForLightBackground;
        this.premierLogoSvgForDarkBackground = content.PremierLogoSvgForDarkBackground;
        this.premierLogoSvgForLightBackground = content.PremierLogoSvgForLightBackground;
    }
}
