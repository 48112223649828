import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Observable } from 'rxjs';

import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { IStoreOperationsAlert } from '../../interfaces/store-operations-alert';
import { OperationsService } from '../../services/operations.service';

/**
 * Dialog for store operations warning.
 */
@Component({
  selector: 'pm-store-operations-warning',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    TranslateModule
  ],
  templateUrl: './store-operations-warning.dialog.html',
  styleUrls: ['./store-operations-warning.dialog.scss']
})
export class StoreOperationsWarningDialog {
  /** Store operations alert. */
  public alert$: Observable<IStoreOperationsAlert>;

  /**
   * Initializes instance of DailyClosingWarningDialog class.
   * @param operations Operations service.
   */
  constructor(private operations: OperationsService) {
    this.alert$ = this.operations.storeOperationsAlert$;
  }
}
