import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IOffice } from '../interfaces/office';

@Injectable({
  providedIn: 'root'
})
/**
 * Service for managing office locations
 **/
export class OfficesService {
  /** Controller name **/
  private controller = 'Resources';
  
  /**
   * Constructor
   * 
   * @param http  The HTTP client
   **/
  constructor(private http: HttpClient) {}

  /**
   * Get the list of employee offices
   * 
   * @returns The list of employee offices
   */
  async getEmployeeOffices()  : Promise<IOffice[] | null> {
    // Implementation for changing the location
    return new Promise<IOffice[] | null>(resolve => {
      const url = `${this.controller}/GetEmployeeOffices`;

     this.http.get<IOffice[] | null>(url).subscribe({
        next: offices => {
          resolve(offices ?? null);
        },
        error: () => {
          resolve(null);
        }
      });
    });
  }
}



