import { IQuickList } from '../interfaces/quick-list';
import { Patient } from './patient';

/**
 * Patient with appointment details.
 */
export class PatientWithAppointment extends Patient {
    /** Phone number. */
    phone: string;
    /** Appointment time. */
    appointmentTime: string | null;
    /** Appointment id. */
    appointmentId: number;
    /** Service name. */
    serviceName: string;
    /** Provider name. */
    providerName: string;
    /** Selected location. */
    selectedLocationOrStatus: number;
    /** Is scheduled? */
    isScheduled: boolean;
    /** ??? */
    time: string;

    /**
     * Initialize instance of the PatientWithAppointment class.
     * @param quicklist Patient quicklist data.
     * @param locations Array of patient locations.
     */
    constructor(quicklist: IQuickList) {
        super({
            PatientInternalId: quicklist.PatientId,
            FirstName: quicklist.FirstName,
            LastName: quicklist.LastName
        });
        this.phone = quicklist.Phone;
        this.appointmentTime = quicklist.AppointmentTime;
        this.appointmentId = quicklist.AppointmentId;
        this.serviceName = quicklist.ServiceName;
        this.providerName = quicklist.ProviderName;
        this.selectedLocationOrStatus = quicklist.SelectedLocationOrStatus;
        this.isScheduled = quicklist.IsScheduledPatient;
        this.time = quicklist.Time;
    }
}
