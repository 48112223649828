/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';

import { BenefitReportDetail } from '../classes/benefit-report-detail';
import { Eligibility } from '../classes/eligibility';
import { BenefitReportDetailMockData } from '../mock-data/benefit-report-detail';
import { InsurancesService } from './insurances.service';

/**
 * Insurances mock service for testing.
 */
@Injectable()
export class InsurancesMockService extends InsurancesService {
    /**
     * Get benefit report detail mock data.
     * @returns Mock data
     */
    override async getBenefitReportDetail(patientInternalId: number, eligibility: Eligibility): Promise<BenefitReportDetail | null> {
        return new BenefitReportDetail(BenefitReportDetailMockData(), this.config);
    }
}