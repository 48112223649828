import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { PatientDetail } from '../classes/patient-detail';
import { PatientOverview } from '../classes/patient-overview';
import { PatientPinnedNote } from '../classes/patient-pinned-note';
import { IPatientLegacy } from '../interfaces/patient-legacy';
import { IPatientOverview } from '../interfaces/patient-overview';
import { IPatientPinnedNote } from '../interfaces/patient-pinned-note';
import { PatientPinnedNoteType } from '../enums/patient-pinned-note-type';

import { Sort } from '@angular/material/sort';
import { IPatientReviewVm } from '../interfaces/patient-review-vm';
import {PatientReviewVm} from '../classes/patient-review-vm';
import { PatientProfile } from '../classes/patient-profile';
import { IPatientProfile } from '../interfaces/patient-profile';
/**
 * Service used for working with patient data.
 */
@Injectable({
  providedIn: 'root'
})
export class PatientsService {
  /** API controller. */
  private controller = 'Patient';  

  /** Default sort state for failed patient orders. */
  public defaultFailedPatientOrderSort: Sort = { active: 'LastName', direction: 'desc' };
  
  /**
   * Initializes instance of the PatientsService class.
   * @param http HTTP client
   */
  constructor(private http: HttpClient) { }

  /**
   * Get patient details from API controller.
   * @param patientInternalId Patient internal id.
   * @returns Patient detail object or null if not found.
   */
  async getPatient(patientInternalId: number): Promise<PatientDetail | undefined> {
    const url = `${this.controller}/GetPatientById?id=${encodeURIComponent(patientInternalId)}`;
    return new Promise<PatientDetail | undefined>((resolve, reject) => this.http.get<IPatientLegacy>(url).subscribe({
      next: data =>
        resolve(data ? new PatientDetail({
          Addresses: data.Addresses?.map(x => x.Address),
          Age: data.Age,
          DateOfBirth: data.DateOfBirth,
          IsResponsibleParty: data.IsResponsibleParty,
          LastExamDate: data.LastExamDate,
          PatientUid: data.PatientUid,
          Phones: data.Phones?.map(x => x.Phone),
          ProviderId: data.ProviderEmployeeId,
          Sex: data.Sex,
          PatientInternalId: data.PatientId,
          FirstName: data.FirstName,
          LastName: data.LastName,
          NickName: data.NickName
        }) : undefined),
      error: e => reject(e)
    }));
  }

  /**
   * Get patient pinned note by type.
   * @param patientInternalId Patient internal id
   * @param pinnedNoteType Patient pinned note type
   * @returns pinned note object
   */
  getPatientPinnedNoteByType(patientInternalId: number, pinnedNoteType = PatientPinnedNoteType.DEMO): Promise<PatientPinnedNote | null> {
    const url = `${this.controller}/GetPatientPinnedNoteByType?id=${patientInternalId}&pinnedNoteType=${pinnedNoteType}`;
    return new Promise<PatientPinnedNote | null>(resolve => this.http.get<IPatientPinnedNote>(url).subscribe({
      next: data =>
        resolve(data ? new PatientPinnedNote(data) : PatientPinnedNote.create(patientInternalId)),
      error: () => resolve(null)
    }));
  }

  /**
   * Get the number of patients added from the online scheduler that need review.
   * @returns Patient count.
   */
  async getPatientReviewCount(): Promise<number> {
    const url = `${this.controller}/GetReviewNeededPatientCount`;
    return new Promise<number>(resolve => this.http.get<number>(url).subscribe({
      next: count => resolve(count),
      error: () => resolve(0)
    }));
  }

    /**
   * Get patients added from the online scheduler that need review.
   * @returns Patient count.
   */
    async getPatientReview(): Promise<PatientReviewVm[] | undefined> {
      const url = `${this.controller}/GetReviewNeededPatients`;
      return new Promise<PatientReviewVm[] | undefined>(resolve => this.http.get<IPatientReviewVm[]>(url)
      .subscribe({
        next: data => resolve(data ? data.map(item => new PatientReviewVm(item)) : undefined),
        error: () => resolve([])
      }));
    }

  /**
   * Save patient pinned note.
   * @param pinnedNote Patient pinned note
   * @returns boolean value indicating success
   */
  savePatientPinnedNote(pinnedNote: PatientPinnedNote): Promise<PatientPinnedNote | null> {
    const url = `${this.controller}/SavePatientPinnedNote`;
    const request = pinnedNote.convert();
    return new Promise<PatientPinnedNote | null>(resolve => this.http.post<IPatientPinnedNote | 'deleted'>(url, request).subscribe({
      next: data => resolve(data === 'deleted' ? PatientPinnedNote.create(pinnedNote.patientInternalId) : new PatientPinnedNote(data)),
      error: () => resolve(null)
    }));
  }

  async getPatientOverview(officeNumber: string, patientId: number): Promise<PatientOverview | undefined> {
      let officeNumberParam = '';
      if (officeNumber.length > 0) {
        officeNumberParam = `officeNumber=${officeNumber}&`;
      }
      const url = `${this.controller}/GetPatientOverview?${officeNumberParam}patientId=${patientId}`;
      return new Promise<PatientOverview | undefined>(resolve => 
        this.http.get<IPatientOverview>(url)
        .subscribe({
          next: data => resolve(data ? new PatientOverview(data) : undefined),
          error: () => resolve(undefined)
        })
      );
  }

  async markPatientAsReviewed(patientId: number): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      const url = `${this.controller}/MarkPatientAsReviewed?patientId=${patientId}`;   
      this.http.post<boolean>(url, patientId)
        .subscribe({
            next: success => resolve(success),
            error: () => resolve(false)
        });
    });
  }

  async getPatientProfile(id: number): Promise<PatientProfile | undefined> {
    const url = `${this.controller}/GetPatientProfile?patientId=${id}`;
    return new Promise<PatientProfile | undefined>(resolve => 
      this.http.get<IPatientProfile>(url)
      .subscribe({
        next: data => resolve(data ? new PatientProfile(data) : undefined),
        error: () => resolve(undefined)
      })
    );
  }
}