import { ICatalogItem } from '../interfaces/catalog-item';

export function RepairFeesMockData(): ICatalogItem[] {
    return [
        {
            'ItemID': 199324,
            'ItemName': 'Miscellaneous Parts',
            'ItemNumber': 'R199324',
            'Price': 33.0,
            'AllowZeroPrice': true
        },
        {
            'ItemID': 1128590,
            'ItemName': 'Eyeglass Tuneup',
            'ItemNumber': 'R1128590',
            'Price': 5.0,
            'AllowZeroPrice': false
        },
        {
            'ItemID': 1454248,
            'ItemName': 'Maui Jim Repairs',
            'ItemNumber': 'R1454248',
            'Price': 15.0,
            'AllowZeroPrice': true
        },
        {
            'ItemID': 1607331,
            'ItemName': 'Italee Bridge Repair',
            'ItemNumber': 'R1607331',
            'Price': 5.0,
            'AllowZeroPrice': true
        },
        {
            'ItemID': 1611262,
            'ItemName': 'Drill Mount Fee',
            'ItemNumber': 'R1611262',
            'Price': 10.0,
            'AllowZeroPrice': false
        },
        {
            'ItemID': 1631084,
            'ItemName': 'NP 10 Min Repair',
            'ItemNumber': 'R1631084',
            'Price': 0.0,
            'AllowZeroPrice': true
        }
    ];
}