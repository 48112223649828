import { IPatientAppointment } from '../interfaces/patient-appointment';

/**
 * PatientAppointment class
 */
export class PatientAppointment {
    /** Appointment ID */
    public appointmentId: number;
    /** Appointment date */
    public appointmentDate: string;
    /** Appointment time */
    public appointmentTime: string;
    /** Doctor's full name */
    public doctorFullName: string;
    /** Type of appointment */
    public appointmentType: string;
    /** Show status of the appointment */
    public appointmentShowStatus: string;
    /** Confirmation status of the appointment */
    public appointmentConfirmationStatus: string;
    /** Date and time of the appointment */
    public appointmentDateTime: string;
    /** Office ID */
    public officeId: string;

    constructor(data: IPatientAppointment) {
        this.appointmentId = data.AppointmentId;
        this.appointmentDate = data.AppointmentDate;
        this.appointmentTime = data.AppointmentTime;
        this.doctorFullName = data.DoctorFullName;
        this.appointmentType = data.AppointmentType;
        this.appointmentShowStatus = data.AppointmentShowStatus;
        this.appointmentConfirmationStatus = data.AppointmentConfirmationStatus;
        this.appointmentDateTime = data.AppointmentDateTime;
        this.officeId = data.OfficeId;
    }
}