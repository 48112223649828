<mat-toolbar class="header-navbar">

    <!-- desktop navigation -->
    <div class="d-none d-md-flex">
        <div class="align-items-center d-flex gap-1">

            <div class="fs-4 pe-4 pe-xl-5">{{'NAVIGATION.CLAIM_MANAGEMENT'|translate}}</div>

            <!-- dashboard -->
            <a data-ef-link="nav-dashboard" mat-button role="button" [href]="'Billing'|link" *ngIf="isClearinghouseClaimStatusIntegratedEnabled">
                {{'CLAIMS.NAVIGATION.DASHBOARD'|translate}}
            </a>

            <!-- claim search -->
            <a data-ef-link="nav-process-claims" mat-button [href]="'Billing/Home/ClaimSearch'|link" role="button">
                {{'CLAIMS.NAVIGATION.CLAIM_SEARCH'|translate}}</a>

            <!-- billing claims -->
            <button data-ef-button="nav-billing-claims" mat-button [matMenuTriggerFor]="menuBillingClaims"
                role="button">{{'CLAIMS.NAVIGATION.BILLING_CLAIMS'|translate}}</button>

            <mat-menu #menuBillingClaims="matMenu">
                <ng-template #billingClaims matMenuContent>
                    <a data-ef-link="nav-cms-form" mat-menu-item [href]="'Billing/BillingClaim/CmsForm'|link">
                        {{'CLAIMS.NAVIGATION.CMS_FORM'|translate}}</a>
                    <a data-ef-link="nav-edi-transmission" mat-menu-item
                        [href]="'Billing/BillingClaim/GatewayEdiTransmission'|link">
                        {{'CLAIMS.NAVIGATION.EDI_TRANSMISSION'|translate}}</a>
                    <a data-ef-link="nav-patient-letter" mat-menu-item
                        [href]="'Billing/BillingClaim/PatientLetter'|link" *ngIf="!isPatientStatementsEnabled">
                        {{'CLAIMS.NAVIGATION.PATIENT_LETTER'|translate}}</a>
                </ng-template>
            </mat-menu>

            <!-- process payments -->
            <button data-ef-button="nav-process-payments" mat-button [matMenuTriggerFor]="menuProcessPayments"
                role="button">{{'CLAIMS.NAVIGATION.PROCESS_PAYMENTS'|translate}}</button>

            <mat-menu #menuProcessPayments="matMenu">
                <ng-template #processPayments matMenuContent>
                    <a data-ef-link="nav-carrier-payments" mat-menu-item
                        [href]="'Billing/ProcessPayments/CarrierPayments'|link">
                        {{'CLAIMS.NAVIGATION.CARRIER_PAYMENTS'|translate}}</a>
                    <a data-ef-link="nav-patient-payments" mat-menu-item
                        [href]="'Billing/ProcessPayments/PatientPayments'|link">
                        {{'CLAIMS.NAVIGATION.PATIENT_PAYMENTS'|translate}}</a>
                    <a data-ef-link="nav-payment-remittance" mat-menu-item
                        [href]="'Billing/ProcessPayments/PaymentRemittance'|link" *ngIf="isEraEnabled|async">
                        {{'CLAIMS.NAVIGATION.PAYMENT_REMITTANCE'|translate}}</a>
                    <a data-ef-link="nav-batch-adjustments" mat-menu-item
                        [href]="'Billing/Miscellaneous/BatchAdjustments'|link">
                        {{'CLAIMS.NAVIGATION.BATCH_ADJUSTMENTS'|translate}}</a>
                </ng-template>
            </mat-menu>

            <!-- billing reports (analytics & insights enabled) -->
            <button data-ef-button="nav-billing-reports" mat-button [matMenuTriggerFor]="menuBillingReports"
                role="button" class="d-none d-lg-block" *ngIf="isAnalyticsAndInsightsEnabled">
                {{'CLAIMS.NAVIGATION.BILLING_REPORTS'|translate}}</button>

            <mat-menu #menuBillingReports="matMenu">
                <a data-ef-link="nav-standard-reports" mat-menu-item [href]="'Billing/Reporting/BillingReports'|link">
                    {{'CLAIMS.NAVIGATION.STANDARD_REPORTS'|translate}}</a>
              <!-- commented out until analytics and insights billing dashboards are available
                <a data-ef-link="nav-analytics-and-insights" mat-menu-item
                    [href]="'Billing/Reporting/AnalyticsAndInsights'|link">
                    {{'CLAIMS.NAVIGATION.ANALYTICS_AND_INSIGHTS'|translate}}</a>
              -->
            </mat-menu>

            <!-- patient statements -->
            <a data-ef-link="nav-patient-statements" mat-button
                [href]="'Billing/PatientStatements/PatientStatement'|link" role="button" class="d-none d-lg-flex"
                *ngIf="isPatientStatementsEnabled">
                {{'CLAIMS.NAVIGATION.PATIENT_STATEMENTS'|translate}}</a>

            <!-- billing reports (analytics & insights disabled) -->
            <a data-ef-link="nav-billing-reports" mat-button [href]="'Billing/Reporting/BillingReports'|link"
                role="button" class="d-none d-lg-flex" *ngIf="!isAnalyticsAndInsightsEnabled">
                {{'CLAIMS.NAVIGATION.BILLING_REPORTS'|translate}}</a>


            <!-- more menu (md) -->
            <button data-ef="nav-more" mat-button class="d-block d-lg-none" [matMenuTriggerFor]="menuMore"
                role="button">{{'NAVIGATION.MORE'|translate}}</button>

            <mat-menu #menuMore="matMenu">
                <button data-ef-button="more-billing-reports" mat-menu-item [matMenuTriggerFor]="menuBillingReports"
                    *ngIf="isAnalyticsAndInsightsEnabled">
                    {{'CLAIMS.NAVIGATION.BILLING_REPORTS'|translate}}</button>
                <a data-ef-link="nav-patient-statements" mat-menu-item
                    [href]="'Billing/PatientStatements/PatientStatement'|link" *ngIf="isPatientStatementsEnabled">
                    {{'CLAIMS.NAVIGATION.PATIENT_STATEMENTS'|translate}}</a>
                <a data-ef-link="nav-billing-reports" mat-menu-item [href]="'Billing/Reporting/BillingReports'|link"
                    *ngIf="!isAnalyticsAndInsightsEnabled">
                    {{'CLAIMS.NAVIGATION.BILLING_REPORTS'|translate}}</a>
            </mat-menu>

        </div>
    </div>

    <!-- mobile navigation -->
    <div class="d-flex d-md-none flex-fill">
        <button data-ef-button="nav-mobile-menu" mat-icon-button [matMenuTriggerFor]="menuMobile" role="button">
            <mat-icon>menu</mat-icon>
        </button>
        <mat-menu #menuMobile="matMenu" class="mobile-nav-menu">
            <mat-accordion>

                <!-- process claims -->
                <mat-expansion-panel>
                    <mat-expansion-panel-header (click)="$event.stopPropagation()">
                        <mat-panel-title>{{'CLAIMS.NAVIGATION.CLAIM_SEARCH'|translate}}</mat-panel-title>
                    </mat-expansion-panel-header>
                    <a data-ef-link="nav-mobile-process-claims" mat-menu-item [href]="'Billing'|link">
                        {{'CLAIMS.NAVIGATION.CLAIM_SEARCH'|translate}}</a>
                </mat-expansion-panel>

                <!-- billing cliams -->
                <mat-expansion-panel>
                    <mat-expansion-panel-header (click)="$event.stopPropagation()">
                        <mat-panel-title>{{'CLAIMS.NAVIGATION.BILLING_CLAIMS'|translate}}</mat-panel-title>
                    </mat-expansion-panel-header>
                    <ng-container [ngTemplateOutlet]="billingClaims"></ng-container>
                </mat-expansion-panel>

                <!-- process payments -->
                <mat-expansion-panel>
                    <mat-expansion-panel-header (click)="$event.stopPropagation()">
                        <mat-panel-title>{{'CLAIMS.NAVIGATION.PROCESS_PAYMENTS'|translate}}</mat-panel-title>
                    </mat-expansion-panel-header>
                    <ng-container [ngTemplateOutlet]="processPayments"></ng-container>
                </mat-expansion-panel>

                <!-- billing reports -->
                <mat-expansion-panel>
                    <mat-expansion-panel-header (click)="$event.stopPropagation()">
                        <mat-panel-title>{{'CLAIMS.NAVIGATION.BILLING_REPORTS'|translate}}</mat-panel-title>
                    </mat-expansion-panel-header>
                    <a data-ef-link="nav-mobile-standard-reports" mat-menu-item
                        [href]="'Billing/Reporting/BillingReports'|link">
                        {{'CLAIMS.NAVIGATION.STANDARD_REPORTS'|translate}}</a>
                  <!-- commented out until analytics and insights billing dashboards are available
                    <a data-ef-link="nav-mobile-analytics-and-insights" mat-menu-item
                        [href]="'Billing/Reporting/AnalyticsAndInsights'|link" *ngIf="isAnalyticsAndInsightsEnabled">
                        {{'CLAIMS.NAVIGATION.ANALYTICS_AND_INSIGHTS'|translate}}</a>
                  -->
                </mat-expansion-panel>

                <!-- print statement -->
                <mat-expansion-panel *ngIf="isPatientStatementsEnabled">
                    <mat-expansion-panel-header (click)="$event.stopPropagation()">
                        <mat-panel-title>{{'CLAIMS.NAVIGATION.PATIENT_STATEMENTS'|translate}}</mat-panel-title>
                    </mat-expansion-panel-header>
                    <a data-ef-link="nav-mobile-patient-statements" mat-menu-item
                        [href]="'Billing/PatientStatements/PatientStatement'|link">
                        {{'CLAIMS.NAVIGATION.PATIENT_STATEMENTS'|translate}}</a>
                </mat-expansion-panel>

            </mat-accordion>
        </mat-menu>
    </div>

    <!-- quick list -->
    <div class="d-flex flex-fill justify-content-end">
        <button data-ef-button="nav-quick-list" mat-button [matMenuTriggerFor]="menuQuickList" role="button">
            {{'NAVIGATION.QUICK_LIST'|translate}}</button>
        <mat-menu #menuQuickList="matMenu" class="no-max-width" xPosition="before">
            <ng-template matMenuContent>
                <pm-quick-list></pm-quick-list>
            </ng-template>
        </mat-menu>
    </div>

</mat-toolbar>
