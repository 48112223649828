import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';

import { PatientLocation, PatientWithAppointment } from '@pm/core/patients';

/**
 * Component for the quick list report used by the print feature.
 */
@Component({
  selector: 'pm-quick-list-report',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule
  ],
  templateUrl: './quick-list-report.component.html',
  styleUrls: ['./quick-list-report.component.scss']
})
export class QuickListReportComponent {
  /** Today's date. */
  public today = new Date();

  /** Array of patients with appointment info. */
  @Input() patients: PatientWithAppointment[] = [];
  /** Array of patient locations. */
  @Input() locations: PatientLocation[] = [];

  /**
   * Gets the patient location description.
   * @param patient Patient details
   * @returns Description
   */
  patientLocation(patient: PatientWithAppointment): string {
    if (patient.selectedLocationOrStatus === 0) return '';
    return this.locations.find(x => x.locationId === patient.selectedLocationOrStatus)?.description ?? '';
  }
}
