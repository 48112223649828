import { DatePipe } from '@angular/common';
import { ApplicationRef, DoBootstrap, Injector, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { TitleStrategy } from '@angular/router';
import { lastValueFrom } from 'rxjs';

import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_ICON_DEFAULT_OPTIONS, MatIconRegistry } from '@angular/material/icon';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule } from '@angular/material/snack-bar';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';

import { ApiInterceptor } from '@pm/core/auth';
import { AppConfigurationService, CustomLoader } from '@pm/core/configuration';
import { CustomTitleStrategy } from '@pm/core/content';
import { CreateElements } from 'src/app/create-elements';
import { environment } from 'src/environments/environment';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

@NgModule({
  imports: [
    AppComponent,
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    MatDialogModule,
    MatSnackBarModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomLoader,
        deps: [HttpClient, AppConfigurationService]
      }
    })
  ],
  providers: [
    DatePipe,
    { provide: 'environment', useValue: environment },
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
    { provide: TitleStrategy, useClass: CustomTitleStrategy },
    {
      provide: MAT_DATE_FORMATS, useValue: {
        parse: {
          dateInput: 'LL',
        },
        display: {
          dateInput: 'LL',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY',
        },
      }
    },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline', subscriptSizing: 'dynamic' } },
    { provide: MAT_ICON_DEFAULT_OPTIONS, useValue: { fontSet: 'outlined' } },
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 2000, verticalPosition: 'bottom' } },
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: { position: 'above', touchendHideDelay: 1000, touchGestures: 'on', } }
  ],
})
export class AppModule implements DoBootstrap {
  /**
   * Initializes instance of the AppModule class.
   * @param iconRegistry Icon registry service
   * @param injector Injector used to create custome elements
   * @param translateService Translate service
   */
  constructor(
    private iconRegistry: MatIconRegistry,
    private injector: Injector,
    private translateService: TranslateService) {
    this.iconRegistry.registerFontClassAlias('outlined', 'material-symbols-outlined mat-ligature-font')
  }

  /**
   * Hook for manual bootstraping the application.
   * - Set translate service to use english.
   * - Bootstrap application or create custom elements.
   * @param appRef Reference to this application
   */
  ngDoBootstrap(appRef: ApplicationRef): void {
    // wait for translations to load (better UI experience)
    lastValueFrom(this.translateService.use('en')).then(() => {
      // if root element exists, bootstrap the AppComponent, otherwise create custom elements
      if (document.querySelector('pm-root')) {
        appRef.bootstrap(AppComponent);
      } else {
        CreateElements(this.injector);
      }
    }).catch(e => console.error('Bootstrap failed:', e));
  }
}
