import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

import { FooterCompactComponent } from './footer-compact/footer-compact.component';
import { FooterStandardComponent } from './footer-standard/footer-standard.component';

/**
 * Footer component.
 */
@Component({
  selector: 'pm-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FooterCompactComponent,
    FooterStandardComponent
  ]
})
export class FooterComponent {
  /** Footer mode. (compact or standard) */
  @Input() mode: 'compact' | 'standard' = 'standard';
}
