/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';

import { ISearchResults } from '@pm/core';
import { FailedFrameOrder } from '../classes/failed-frame-order';
import { FailedFrameOrderResultsMockData } from '../mock-data/failed-frame-order-results';
import { FulfillmentService } from './fulfillment.service';

/**
 * Service that makes requests to the hosting-fulfillment API.
 */
@Injectable({
  providedIn: 'root'
})
export class FulfillmentMockService extends FulfillmentService {
  /**
   * Acknowledge failed frame order mock.
   * @param orderInternalId Order internal id.
   * @returns Boolean indicating success.
   */
  override async acknowledgeFailedFrameOrder(orderInternalId: number): Promise<boolean> {
    return new Promise<boolean>(resolve => resolve(true));
  }

  /**
   * Load mock data for failed frame orders.
   * @param skip Number of iutems to skip
   * @param take Number of items to take
   * @param sort Sort state.
   * @returns Failed frame order find results
   */
  override async getFailedFrameOrders(skip = 0, take = 10, sort = this.defaultFailedFrameOrderSort): Promise<ISearchResults<FailedFrameOrder>> {
    return new Promise<ISearchResults<FailedFrameOrder>>(resolve => {
      const data = FailedFrameOrderResultsMockData();
      resolve({ Items: data.Items.map(x => new FailedFrameOrder(x)), TotalAvailable: data.TotalAvailable })
    });
  }
}