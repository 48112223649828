import { IAppConfig } from '../interfaces/app-config';

export function AppConfigTrainingPendingLiveMockData(): IAppConfig {
    return {
        baseUrl: '/EPM/',
        userId: '123456',
        userName: 'test',
        officeNumber: '936',
        companyId: '936',
        officeId: '184',
        timeOut: '180',
        dayCloseRole: 'True',
        isEhrEnabled: 'True',
        trainingMode: 'True',
        trainingPendingLive: 'True',
        trainingCompanyId: 'T936',
        frameDataAvailability: '',
        isPremier: '1'
    };
}