import { Injectable } from '@angular/core';
import { InsurancesService, IEligibilityResponse } from '@pm/core/insurances';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class PatientStateService {
  /** The patient whose report we will be displaying */
  private currentPatient = new BehaviorSubject<IEligibilityResponse | null | undefined> (undefined);
  /** The response we are getting back from the API */
  eligibilityResponse!: IEligibilityResponse | null;
  public eligibilityId?: number;
  private externalWindow = new BehaviorSubject<Window | null>(null);
  public isPatientSet = false;

  /**
   * @param insurancesService Insurances Service
   */
  constructor(private insurancesService: InsurancesService) {}

  /**
   * @returns The current patient
   */
  get currentPatient$(): Observable<IEligibilityResponse | null | undefined> {
    return this.currentPatient.asObservable();
  }

  /**
   * @param eligibilityId 
   * @param patientId 
   * Sets the current patient
   */
  async setCurrentPatient(eligibilityId: number, patientId: number) : Promise<void> {
    this.eligibilityId = eligibilityId;
    this.eligibilityResponse = await this.insurancesService.getEligibilityDocument(eligibilityId, patientId);
    this.currentPatient.next(this.eligibilityResponse);
  }

  setExternalWindow(window: Window | null) : void {
    this.externalWindow.next(window);
  }

  get externalWindow$(): Observable<Window | null> {
    return this.externalWindow.asObservable();
  }
}
