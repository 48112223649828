/**
 * Interface for phone number.
 */
export interface IPhone {
    /** Extension. */
    Extension?: string;
    /** Is primary phone number */
    IsPrimary: boolean;
    /** Phone id. */
    PhoneId: number;
    /** Phone number. */
    PhoneNumber: string;
    /** Phone number type. */
    PhoneType: PhoneType;
}

/**
 * Phone number type enum.
 */
export enum PhoneType {
    HOME = 304,
    WORK = 305,
    MOBILE = 306,
    FAX = 307
}