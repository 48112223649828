<!-- dialog title -->
<h1 mat-dialog-title>
    <span>{{ 'DIALOGS.TEMPORARY_OVERRIDE' | translate}}</span>
    <button data-ef-button="override-close" mat-icon-button [attr.aria-label]="'BUTTONS.CLOSE'" mat-dialog-close="close"
        [disabled]="isProcessing">
        <mat-icon>close</mat-icon>
    </button>
</h1>

<!-- dialog content -->
<div mat-dialog-content>
    <form id="temporaryOverride" autocomplete="off" [formGroup]="form" (keydown.enter)="$event.preventDefault()"
        (ngSubmit)="override()">
        <input autocomplete="false" name="hidden" type="text" class="d-none" />

        <!-- error message -->
        <pm-alert [options]="alert" class="d-block mb-3" *ngIf="message">
            <div data-ef="override-error" [innerHTML]="message"></div>
        </pm-alert>

        <!-- message -->
        <div class="d-flex gap-3">
            <div class="warning"><mat-icon>warning</mat-icon></div>
            <span>{{ 'MESSAGES.TEMPORARY_OVERRIDE' | translate }}</span>
        </div>

        <div class="d-flex flex-column gap-3 mt-4 mx-5">

            <!-- username -->
            <mat-form-field subscriptSizing="fixed" class="w-50">
                <mat-label data-ef-label="override-username">{{ 'LABELS.USERNAME' | translate }}</mat-label>
                <input #username data-ef-input="override-username" data-lpignore="true" matInput type="text"
                    formControlName="username" autocomplete="off" aria-autocomplete="list" required cdkFocusInitial maxlength="40" />
                <mat-error *ngIf="form.controls['username'].hasError('required')">
                    {{ 'VALIDATION.USERNAME_REQUIRED' | translate }}
                </mat-error>
            </mat-form-field>

            <!-- password -->
            <mat-form-field subscriptSizing="fixed" class="w-50">
                <mat-label data-ef-label="override-password">{{ 'LABELS.PASSWORD' | translate }}</mat-label>
                <input data-ef-input="override-password" data-lpignore="true" matInput type="password"
                    formControlName="password" autocomplete="new-password" aria-autocomplete="list" required />
                <mat-error *ngIf="form.controls['password'].hasError('required')">
                    {{ 'VALIDATION.PASSWORD_REQUIRED' | translate }}
                </mat-error>
            </mat-form-field>

        </div>
    </form>

    <!-- processing spinner -->
    <div class="overlay" *ngIf="isProcessing">
        <mat-spinner></mat-spinner>
    </div>
</div>

<!-- dialog actions -->
<div mat-dialog-actions class="justify-content-between">

    <!-- cancel -->
    <button data-ef-button="override-cancel" mat-stroked-button color="alt" mat-dialog-close="close"
        [disabled]="isProcessing">
        {{ 'BUTTONS.CANCEL' | translate }}
    </button>

    <!-- override -->
    <button data-ef-button="override-submit" mat-flat-button color="primary" form="temporaryOverride" type="submit"
        [disabled]="isProcessing">
        {{ 'BUTTONS.OVERRIDE' | translate }}
    </button>

</div>