import { ICatalogResults } from '../interfaces/catalog-results';
import { IFrame } from '../interfaces/frame';
import { FramesMockData } from './frames';

export function FrameResultsMockData(): ICatalogResults<IFrame> {
  return {
    recordsTotal: 5,
    recordsFiltered: 5,
    data: FramesMockData(),
  };
}
