/**
 * Date helper class.
 */
export class DateHelper {
    /**
     * Convert date to Date object.
     * @param value Date value
     * @returns Date object or null
     */
    static convertToDate(value: Date | string | null | undefined): Date | null {
        if (value instanceof Date) {
            return value;
        } else if (typeof value === 'string') {
            return new Date(value);
        } else {
            return null;
        }
    }    
}
