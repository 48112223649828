import { CanDeactivateFn } from '@angular/router';

import { ICanComponentDeactivate } from '../interfaces/can-deactivate';

/**
 * CanDeactivate route guard.
 * @param component Component to deactivate.
 * @returns 
 */
export const canDeactivateGuard: CanDeactivateFn<ICanComponentDeactivate> = (component: ICanComponentDeactivate) => {
  return component.canDeactivate();
};
