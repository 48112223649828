import { AppConfigurationService } from '@pm/core/configuration';
import { BenefitReportType } from '../enums/benefit-report-type';
import { EligibilitySource } from '../enums/eligibility-source';
import { IBenefitReportDetail } from '../interfaces/benefit-report-detail';

/**
 * Benefit report detail class.
 */
export class BenefitReportDetail {
    /** Benefit report type. */
    public benefitReportType: BenefitReportType;
    /** Carrier code. */
    public carrierCode: string;
    /** Insurance eligibility. */
    public eligibilityId: number;
    /** Eligibility source. */
    public eligibilitySource: EligibilitySource;
    /** Is medical coverage. */
    public isMedicalCoverage: boolean;
    /** Is VSP?. */
    public isVsp: boolean;
    /** Patient external id. */
    public patientInternalId: number;
    /** Used to determine which report type to display. */
    public showReportIcon: string;

    /** Report window options. */
    private reportWindowOpt = 'height=800,width=1024,toolbar=0,location=0,status=0,menubar=0,resizable=1,scrollbars=1';
    /** Crystal report window options. */
    private crystalReportWindowOpt = 'height=800,width=650,toolbar=0,location=0,status=0,menubar=0,resizable=1,scrollbars=1';

    /**
    * Initializes instance of the PatientInsurance class.
    * @param insurance Patient insurance data.
    */
    constructor(benefitReportDetail: IBenefitReportDetail, private config: AppConfigurationService) {
        this.benefitReportType = benefitReportDetail.BenefitReportType;
        this.carrierCode = benefitReportDetail.CarrierCode;
        this.eligibilityId = benefitReportDetail.EligibilityId;
        this.eligibilitySource = benefitReportDetail.EligibilitySource;
        this.isMedicalCoverage = benefitReportDetail.IsMedicalCoverage;
        this.isVsp = benefitReportDetail.IsVsp;
        this.patientInternalId = benefitReportDetail.PatientInternalId;
        this.showReportIcon = benefitReportDetail.ShowReportIcon;
    }

    /** Is the benefit report available? */
    get isAvailable(): boolean {
        return ['VSPVerbalization', 'NonVerbalization'].includes(this.showReportIcon) || (!this.isVsp && this.isMedicalCoverage);
    }

    /** Show benefit report. */
    showReport(): void {
        if (this.showReportIcon === 'VSPVerbalization') {
            const btn = document.querySelector('pm-eligibility-report-launcher [data-ef-button="show-report"]');
            btn && (btn as HTMLButtonElement).click();
        } else if (this.showReportIcon === 'NonVerbalization') {
            // Crystal report
            const carrierCode = this.carrierCode;
            //Rte Benefit Summary Report
            if (this.benefitReportType === BenefitReportType.RTE_BENEFIT_SUMMARY_REPORT) {
                const routePrefix = `api/office/${this.config.officeExternalId}`;
                const antiForgeryToken = this.config.antiForgeryToken;
                const url = this.config.getLinkPath(routePrefix + `/RetailPrrReport/GenerateReport?eligibilityId=${this.eligibilityId}&patientId=${this.patientInternalId}&uId=&carrierCode=${carrierCode}&requestVerificationToken=${antiForgeryToken}`);
                this.openWindow(url, this.crystalReportWindowOpt);
            }
            else if (this.benefitReportType === BenefitReportType.VSP_BENEFIT_SUMMARY_REPORT) {
                //VSP Non-Retail Benefit Summary Report
                const showBenefitStatementReport = this.eligibilitySource === EligibilitySource.RTE;
                const url = this.config.getLinkPath(`Patient/GetRecordReport?eligibilityId=${this.eligibilityId}&showBenefitStatementReport=${showBenefitStatementReport}`);
                this.openWindow(url, this.crystalReportWindowOpt);
            }
            else {
                //Default Html Generation Report Engine
                let benefitReportType: string | null = null;
                switch (this.benefitReportType) {
                    case BenefitReportType.VSP_RETAIL_PRR_REPORT_INN:
                        benefitReportType = 'VspRetailPrrReportInn';
                        break;
                    case BenefitReportType.VSP_RETAIL_PRR_REPORT_OON:
                        benefitReportType = 'VspRetailPrrReportOon';
                        break;
                }
                if (benefitReportType) {
                    const url = this.config.getLinkPath(`Patient/EligibilityPrrReport?reportType=${benefitReportType}&eligibilityId=${this.eligibilityId}&patientId=${this.patientInternalId}&uId=&carrierCode=${carrierCode}`);
                    this.openWindow(url, this.reportWindowOpt);
                }
            }
        } else if (!this.isVsp && this.isMedicalCoverage) {
            const url = this.config.getLinkPath(`Patient/GetPatientCoverageReport?eligibilityId=${this.eligibilityId}`);
            this.openWindow(url, this.reportWindowOpt);
        }
    }

    /** Open new report window. */
    private openWindow(url: string, options: string): void {
        window.open(url, '_blank', options);
    }
}
