import { Injectable } from '@angular/core';

import { AppContent } from '../classes/app-content';
import { AppContentMockData } from '../mock-data/app-content';
import { ContentService } from './content.service';

/**
 * Content service mock for testing.
 */
@Injectable()
export class ContentMockService extends ContentService {
  /**
   * Get app content mock data.
   * @returns Mock data
   */
  override get appContent(): Promise<AppContent | null> {
    return new Promise(resolve => resolve(new AppContent(AppContentMockData())));
  }
}