import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

import { AppConfigurationService } from '@pm/core/configuration';
import { IAdminOfficeContext } from '../interfaces/admin-office-context';


/**
 * Service for working with company data.
 */
@Injectable({
  providedIn: 'root'
})
export class CompaniesService {
  /** Admin office context. */
  private adminOfficeContext: BehaviorSubject<IAdminOfficeContext>;
  /** API controller. */
  private controller = 'CompanyInformation';
  /** Admin office context key used for session storage. */
  private adminOfficeContextKey = 'adminOfficeContext';

  /**
   * Initializes instance of the CompanyService class.
   * - Set default value for admin office context.
   * @param config Application configuration service
   * @param http HTTP client
   */
  constructor(
    private config: AppConfigurationService,
    private http: HttpClient
  ) {
    const officeExternalId = this.config.officeExternalId;
    this.adminOfficeContext = new BehaviorSubject<IAdminOfficeContext>({
      companyId: this.config.companyExternalId,
      officeNumber: officeExternalId || '',
      offices: officeExternalId ? [officeExternalId] : []
    });
  }

  /**
   * Load admin office context from API.
   */
  async loadAdminOfficeContext(): Promise<void> {
    return new Promise<void>(resolve => {
      const url = `${this.controller}/GetOffices?companyId=${encodeURIComponent(this.config.companyExternalId)}`;
      this.http.get<string[]>(url).subscribe({
        next: offices => {
          const adminOfficeContext = this.adminOfficeContext.getValue();
          adminOfficeContext.offices = offices;
          sessionStorage.setItem(this.adminOfficeContextKey, JSON.stringify(adminOfficeContext));
          resolve();
        }
      });
    });
  }
}
