/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';

import { IActionResponse } from '@pm/core';
import { PatientLocation } from '../classes/patient-location';
import { PatientWithAppointment } from '../classes/patient-with-appointment';
import { QuickListResponseMockData } from '../mock-data/quick-list-response';
import { QuickListService } from './quick-list.service';

/**
 * Content service mock for testing.
 */
@Injectable()
export class QuickListMockService extends QuickListService {
    /**
     * Get quick list mock data.
     * @returns Mock data
     */
    override async getQuickList(searchText = ''): Promise<[PatientWithAppointment[], PatientLocation[]]> {
        const data = QuickListResponseMockData();
        const locations = data.quicklistLocations.map(x => new PatientLocation(x));
        const patients = data.quicklistSummaries.map(x => new PatientWithAppointment(x))
        return [patients, locations];
    }

    /**
     * Mock save quick list location
     */
    override async saveLocation(patient: PatientWithAppointment): Promise<IActionResponse> {
        return { isSuccess: true };
    }
}