import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Sort } from '@angular/material/sort';

import { ISearchResults } from '@pm/core';
import { FailedFrameOrder } from '../classes/failed-frame-order';
import { IFailedFrameOrder } from '../interfaces/failed-frame-order';

/**
 * Service that makes requests to the hosting-fulfillment API.
 */
@Injectable({
  providedIn: 'root'
})
export class FulfillmentService {
  /** Default sort state for failed frame orders. */
  public defaultFailedFrameOrderSort: Sort = { active: 'OrderNum', direction: 'desc' };

  /** API controller. */
  private controller = 'EyeglassOrder';

  /**
   * Initializes instance of the FulfillmentService class.
   * @param http HTTP client
   */
  constructor(private http: HttpClient) { }

  /**
   * Acknowledge failed frame order.
   * @param orderInternalId Order internal id.
   * @returns Boolean indicating success.
   */
  async acknowledgeFailedFrameOrder(orderInternalId: number): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      const url = `${this.controller}/AcknowledgeFailedFrameOrder`;
      this.http.post<boolean>(url, orderInternalId).subscribe({
        next: success => resolve(success),
        error: () => resolve(false)
      });
    });
  }

  /**
   * Load failed frame orders from API.
   * @param skip Number of iutems to skip
   * @param take Number of items to take
   * @param sort Sort state.
   * @returns Failed frame order find results
   */
  async getFailedFrameOrders(skip = 0, take = 10, sort = this.defaultFailedFrameOrderSort): Promise<ISearchResults<FailedFrameOrder>> {
    if (sort.direction === '') sort = this.defaultFailedFrameOrderSort;
    return new Promise<ISearchResults<FailedFrameOrder>>(resolve => {
      const url = `${this.controller}/GetFailedFrameOrders?skip=${skip}&take=${take}&orderBy=${sort.active}&orderDir=${sort.direction}`;
      this.http.get<ISearchResults<IFailedFrameOrder> | null>(url).subscribe({
        next: data => resolve(data ? { Items: data.Items.map(x => new FailedFrameOrder(x)), TotalAvailable: data.TotalAvailable }
          : { Items: [], TotalAvailable: 0 }),
        error: () => resolve({ Items: [], TotalAvailable: 0 })
      });
    });
  }
}