import { IPatientPinnedNote } from '../interfaces/patient-pinned-note';

export function PatientPinnedNoteMockData(): IPatientPinnedNote {
    return {
        'Id': 63052,
        'PatientId': 12345,
        'Type': 1,
        'Note': 'This is a test note.',
        'DateCreated': '2020-06-29T21:57:11',
        'DateModified': '2024-02-01T17:15:46'
    };
}