/**
 * Relationship type enum;
 */
export enum RelationshipType {
    SELF = 301,
    SPOUSE = 302,
    CHILD = 303,
    OTHER = 310,
    STUDENT = 3004,
    DOMESTICPARTNER = 3009,
    DEPENDENTPARENT = 3010,
    UNKNOWN = 101
}