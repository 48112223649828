import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AppConfigurationService } from '@pm/core/configuration';
import { BenefitReportDetail } from '../classes/benefit-report-detail';
import { PatientInsurance } from '../classes/patient-insurance';
import { IBenefitReportDetail } from '../interfaces/benefit-report-detail';
import { IEligibilityResponse, IPrrReport } from '../interfaces/eligibility-payload.interface';
import { IOrderPatientInsurance } from '../interfaces/order-patient-insurance';
import { Eligibility } from '../classes/eligibility';

/**
 * Service for working with insurances.
 */
@Injectable({
  providedIn: 'root'
})
export class InsurancesService {
  /** API controller. */
  private controller = 'PatientInsurance';

  /**
   * Initializes instance of the InsurancesService class.
   * @param config Application configuration service
   * @param http HTTP client
   */
  constructor(
    protected config: AppConfigurationService,
    private http: HttpClient
  ) { }

  /**
   * Get benefit report type.
   * @param patientInternalId Patient internal id
   * @param insurancePlanId Insurance plan id
   * @returns Benefit report type
   */
  async getBenefitReportDetail(patientInternalId: number, eligibility: Eligibility): Promise<BenefitReportDetail | null> {
    return new Promise<BenefitReportDetail | null>(resolve => {
      const url = `${this.controller}/GetBenefitReportDetail?patientInternalId=${patientInternalId}&eligibilityId=${eligibility.insuranceEligibilityId}`;
      this.http.get<IBenefitReportDetail | null>(url).subscribe({
        next: data => resolve(data ? new BenefitReportDetail(data, this.config) : null),
        error: () => resolve(null)
      });
    });
  }

  /**
   * Get eligibility document by eligibilityId and patientId.
   * @param eligibilityId Eligibility id
   * @param patientId Patient Id
   * @returns Eligibility JSON
   */
  async getEligibilityDocument(eligibilityId: number, patientId: number): Promise<IEligibilityResponse | null> {
    return new Promise<IEligibilityResponse | null>(resolve => {
      const url = `${this.controller}/GetEligibilityDocument?eligibilityId=${eligibilityId}&patientId=${patientId}`;
      this.http.get<IPrrReport>(url).subscribe({
        next: data => {
          if (data?.EligibilityResponse) resolve(data.EligibilityResponse[0]);
          else resolve(null);
        },
        error: () => resolve(null)
      });
    });
  }

  /**
   * Get insurances with eligibilities by patient id.
   * @param patientInternalId Patient internal id
   * @returns Array of patient insurances
   */
  async getPatientInsurances(patientInternalId: number, orderInternalId: number): Promise<PatientInsurance[]> {
    return new Promise<PatientInsurance[]>(resolve => {
      const url = `${this.controller}/GetValidEligibilities?patientId=${patientInternalId}&orderId=${orderInternalId}`;
      this.http.get<IOrderPatientInsurance[]>(url).subscribe({
        next: data => {
          const insurances = data.map(x => new PatientInsurance(x));
          resolve(insurances);
        },
        error: () => resolve([])
      });
    });
  }
}
