import { IAddress } from '../interfaces/address';

export const ADDRESSES_MOCK_DATA: IAddress[] = [
    {
        AddressId: 1,
        Address1: '1115 Schroeder Points',
        City: 'Port Albert',
        State: 'IA',
        ZipCode: '96864',
        Country: null,
        CountryId: 0,
        IsPrimary: true
    },
    {
        AddressId: 2,
        Address1: '69216 Reinger Walk',
        Address2: 'Suite 263',
        City: 'Bergeborough',
        State: 'FL',
        ZipCode: '50321',
        Country: null,
        CountryId: 0,
        IsPrimary: false
    },
    {
        AddressId: 3,
        Address1: '692 Anderson Route',
        City: 'Lake Mervinmouth',
        State: 'IL',
        ZipCode: '54501',
        Country: null,
        CountryId: 0,
        IsPrimary: false
    }
];