import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { IActionResponse } from '@pm/core';
import { AppConfigurationService } from '@pm/core/configuration';
import { IQuickListResponse } from '../interfaces/quick-list-response';
import { PatientWithAppointment } from '../classes/patient-with-appointment';
import { PatientLocation } from '../classes/patient-location';
import { IQuickList } from '../interfaces/quick-list';

/**
 * Service used by the quicklist faeture.
 */
@Injectable({
  providedIn: 'root'
})
export class QuickListService {
  /** API controller. */
  private controller = 'QuickList';

  /**
   * Initializes instance of the QuicklistService class.
   * @param http Application configuration service
   * @param http HTTP client
   */
  constructor(
    private config: AppConfigurationService,
    private http: HttpClient
  ) { }

  /**
   * Get quicklist patient data.
   * @param searchText Search text
   * @returns Quicklist data
   */
  getQuickList(searchText = ''): Promise<[PatientWithAppointment[], PatientLocation[]]> {
    const officeNumber = this.config.officeExternalId;
    const userId = this.config.userId;
    if (officeNumber) {
      const url = `${this.controller}/GetTodayPatients?officeNumber=${encodeURIComponent(officeNumber)}&userId=${encodeURIComponent(userId)}&search=${encodeURIComponent(searchText)}`;
      return new Promise<[PatientWithAppointment[], PatientLocation[]]>(resolve => this.http.get<IQuickListResponse | null>(url).subscribe({
        next: data => {
          if (data?.quicklistSummaries && data.quicklistLocations) {
            const locations = data.quicklistLocations.filter((value, index, self) => index === self.findIndex(x => x.LocationId === value.LocationId)).map(x => new PatientLocation(x));
            const patients = data.quicklistSummaries.map(x => new PatientWithAppointment(x))
            resolve([patients, locations]);
          } else {
            resolve([[], []]);
          }
        },
        error: () => resolve([[], []])
      }));
    } else {
      return new Promise(resolve => resolve([[], []]));
    }
  }

  /**
   * Save patient location.
   * @param patient Quick list patient
   * @returns Action result
   */
  saveLocation(patient: PatientWithAppointment): Promise<IActionResponse> {
    return new Promise<IActionResponse>(resolve => {
      const officeNumber = this.config.officeExternalId;
      if (officeNumber) {
        const userId = this.config.userId;
        const url = `${this.controller}/SaveTodayApptPatient?officeNumber=${encodeURIComponent(officeNumber)}&userId=${encodeURIComponent(userId)}`;
        const data: IQuickList = {
          LastName: patient.lastName,
          FirstName: patient.firstName,
          PatientId: patient.patientInternalId,
          Phone: patient.phone,
          AppointmentTime: patient.appointmentTime,
          AppointmentId: patient.appointmentId,
          ServiceName: patient.serviceName,
          ProviderName: patient.providerName,
          SelectedLocationOrStatus: patient.selectedLocationOrStatus,
          IsScheduledPatient: patient.isScheduled,
          Time: patient.time,
        };
        this.http.put(url, data).subscribe({
          next: () => resolve({ isSuccess: true }),
          error: error => resolve({ isSuccess: false, error })
        });
      } else {
        resolve({ isSuccess: false });
      }
    });
  }

  async showAllPatientsInQuickList(): Promise<boolean> {
    const companyId = this.config.companyExternalId;
    if (!companyId) {
        return Promise.resolve(false);
    }

    const url = `${this.controller}/GetShowAllPatientsInQuickList?companyId=${encodeURIComponent(companyId)}`;

    return new Promise<boolean>((resolve) => {
        this.http.get<boolean>(url).subscribe({
            next: data => {
                resolve(data);
            },
            error: err => {
                console.error('Error fetching all patients in quick list:', err);
                resolve(false);
            }
        });
    });
}
}
