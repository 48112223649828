import { ICptModifierCode } from '../interfaces/cpt-modifier-code';

/**
 * Class for CPT Modifier Code.
 */
export class CptModifierCode {
    /** Code. */
    public code: string;
    /** CPT modifier code internal id. */
    public cptModifierCodeInternalId: number;
    /** Description. */
    public description: string;
    /** Is active? */
    public isActive: boolean;


    /**
     * Initializes instance of the CptModifierCode class.
     * @param cptModifierCode CPT modifier code details
     */
    constructor(cptModifierCode: ICptModifierCode) {
        this.code = cptModifierCode.ModifierCode;
        this.cptModifierCodeInternalId = cptModifierCode.CptModifierCodeInternalId;
        this.description = cptModifierCode.Description;
        this.isActive = cptModifierCode.IsActive;
    }

    /**
     * Get display text for select control.
     */
    get displayText(): string {
        return `${this.code} - ${this.description}`;
    }
}