import { IPatientReviewVm } from '../interfaces/patient-review-vm'; // Replace 'path/to/IPatientReviewVm' with the actual path to the IPatientReviewVm type definition file

/** Create a mock service for PatientsService */
export function PatientReviewMockData(): IPatientReviewVm[] {
    return [
      {
        PatientId: 1,
        FirstName: 'John',
        LastName: 'Doe',
        Dob: '01/01/2000',
        CreatedDate: '',
        NoteDetail: '',
        OfficeNumber: '',
        HasMergePatientPermission: false
      },
      {
        PatientId: 2,
        FirstName: 'Jane',
        LastName: 'Smith',
        Dob: '02/02/1990',
        CreatedDate: '',
        NoteDetail: '',
        OfficeNumber: '',
        HasMergePatientPermission: false
      },
      {
        PatientId: 3,
        FirstName: 'Alice',
        LastName: 'Johnson',
        Dob: '03/03/1985',
        CreatedDate: '',
        NoteDetail: '',
        OfficeNumber: '',
        HasMergePatientPermission: false
      }
      // Add more mock data as needed
    ];
  }