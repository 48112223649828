import { IEyeglassFrame } from '../interfaces/eyeglass-frame';

export function EyeglassFramesMockData(): IEyeglassFrame[] {
  return [
    {
      ItemInternalId: 2805298,
      Name: 'LO2621 MARBLE BROWN AZURE 53/14/140',
      Sku: '377365314251',
      Upc: '886895360500',
    },
    {
      ItemInternalId: 1390753,
      Name: 'Nike 8100/3 Satin Blue 55/14/140',
      Sku: '',
      Upc: '0883121947917',
    },
    {
      ItemInternalId: 686806,
      Name: 'A115 Bark 51/20/145',
      Sku: 'A1155120BARK',
      Upc: '788678992000',
    },
  ];
}
