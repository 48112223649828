import { IPatientRx } from '../interfaces/patient-rx';

/**
 * PatientRx class
 */
export class PatientRx {
    /** Prescription ID */
    id: number;
    /** Date of the exam */
    examDate: string;
    /** Expiration date of the prescription */
    expirationDate: string;
    /** Type of prescription */
    rxType: string;
    /** Name of the doctor */
    doctorName: string;
    /** Name of the employee */
    employeeName: string;
    /** Indicates if the prescription is from outside */
    outsideRx: boolean;
    /** Indicates if the prescription has been rechecked */
    hasBeenRechecked: boolean;
    /** Description of the source */
    sourceDescription: string;
    /** Indicates if there are orders associated with the prescription */
    hasOrders: boolean;

    constructor(data: IPatientRx) {
        this.id = data.Id;
        this.examDate = data.ExamDate;
        this.expirationDate = data.ExpirationDate;
        this.rxType = data.RxType;
        this.doctorName = data.DoctorName;
        this.employeeName = data.EmployeeName;
        this.outsideRx = data.OutsideRx;
        this.hasBeenRechecked = data.HasBeenRechecked;
        this.sourceDescription = data.SourceDescription;
        this.hasOrders = data.HasOrders;
    }
}