import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FooterComponent } from '@pm/layout/footer';
import { HeaderComponent } from '@pm/layout/header';

/**
 * Main application component.
 */
@Component({
  selector: 'pm-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [
    FooterComponent,
    HeaderComponent,
    RouterModule
  ]
})
export class AppComponent {}
