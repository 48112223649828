import { CommonModule, CurrencyPipe } from '@angular/common';
import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { LinkPipe } from '@pm/core';
import { PatientProfile, PatientsService } from '@pm/core/patients';

@Component({
  standalone: true,
  imports: [
    CommonModule, 
    TranslateModule, 
    CurrencyPipe, 
    MatTooltipModule, 
    MatMenuModule,
    MatIconModule,
    LinkPipe
  ],
  providers: [],
  selector: 'pm-patient-info',
  templateUrl: './patient-info.component.html',
  styleUrls: ['./patient-info.component.scss']
})
export class PatientInfoComponent implements OnInit {
  @Input() patientId: number | null = null;
  @ViewChild(MatMenuTrigger) menuTrigger?: MatMenuTrigger;

  patientProfile: PatientProfile = {
    patientId: 0,
    age: '',
    provider: '',
    insuranceOne: '',
    balance: '',
    credit: ''
  };

  constructor(private patientService: PatientsService) {}

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    if (this.menuTrigger?.menuOpen) {
      this.menuTrigger.closeMenu();
    }
  }

  ngOnInit(): void {
    this.getPatientHeaderInfo();
  }

  get patientIdValid(): boolean {
    return !isNaN(+('' + this.patientId)) && +('' + this.patientId) > 0;
  }

  get patientInsurance(): string {
    return this.trimString(this.patientProfile?.insuranceOne, 25);
  }

  get patientProvider(): string {
    return this.trimString(this.patientProfile?.provider, 25);
  }

  getPatientHeaderInfo(): void {
    // The value of Request.QueryString["id"] is a string.
    this.patientId = +('' + this.patientId);

    if (!this.patientIdValid) return;

    const serviceCall = this.patientService.getPatientProfile(this.patientId);

    serviceCall.then((data: PatientProfile | undefined) => {
      if (data) {
        this.patientProfile = data;
      }
    }).catch((err: unknown) => {
      console.error(`Error when getting patient data for top of patient page.  err: ${err}`);
    });
  }

  trimString(inputString: string, length: number): string {
    if (!inputString) {
      return '';
    }
    else if (inputString.length > length) {
      return inputString.substring(0, length) + '...';
    }
    else {
      return inputString;
    }
  }
}
