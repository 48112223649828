import { IPhone, PhoneType } from '../interfaces/phone';

/**
 * Phone number class.
 */
export class Phone {
    /** Extension. */
    public extension?: string;
    /** Is primary phone number */
    public isPrimary: boolean;
    /** Phone id. */
    public phoneId: number;
    /** Phone number. */
    public phoneNumber: string;
    /** Phone number type. */
    public phoneType: PhoneType;

    /**
     * Initializes instance of the Phone class.
     * @param phone Phone number data.
     */
    constructor(phone: IPhone) {
        this.extension = phone.Extension;
        this.isPrimary = phone.IsPrimary;
        this.phoneId = phone.PhoneId;
        this.phoneNumber = phone.PhoneNumber;
        this.phoneType = phone.PhoneType;
    }
}
