import { Injectable, OnDestroy, signal } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { filter, Subscription } from 'rxjs';

/**
 * Service used for global UI elements.
 */
@Injectable({ providedIn: 'root' })
export class UiService implements OnDestroy {
  /** Flag used to notify that some proccess is in progress. */
  public isProcessing = signal(false);

  /** Array of subscriptions to observables. */
  private subscriptions: Subscription[] = [];

  /**
   * Initializes a new instance of the UiService class.
   * @param route Router service
   */
  constructor(route: ActivatedRoute, router: Router) {
    this.subscriptions.push(router.events.pipe(filter(x => x instanceof NavigationStart)).subscribe(() => this.isProcessing.set(true)));
    this.subscriptions.push(router.events.pipe(filter(x => x instanceof NavigationEnd)).subscribe(() => {
      let childRoute = route;
      while (childRoute.firstChild) { childRoute = childRoute.firstChild; }
      this.isProcessing.set(Boolean(childRoute?.snapshot.data['isProcessing']));
    }));
  }

  /**
   * OnDestroy lifecycle hook.
   * - Unsubscribe from subscriptions.
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
