import { IAppInfo } from '../interfaces/app-info';

export function AppInfoCompleteMockData(): IAppInfo {
    return {
        antiForgeryToken: '0000',
        appVersion: '1.0',
        basePath: '/EPM/pmApp/',
        dailyRoles: {
            hasDayOpenRole: false,
            hasDayCloseRole: false,
            hasPaymentTypesRole: false
        },
        distPath: '/EPM/pmDist/',
        kpiView: null,
        supportKnowledgeBaseUrl: '/'
    };
}