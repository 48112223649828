import { ICatalogItem } from '../interfaces/catalog-item';

export function MiscellaneousFeesMockData(): ICatalogItem[] {
    return [
        {
            'ItemID': 2500017,
            'ItemName': 'Cancellation Fee ',
            'ItemNumber': 'M2500017',
            'Price': 50.0,
            'AllowZeroPrice': true
        },
        {
            'ItemID': 201055,
            'ItemName': 'EyePrintPro',
            'ItemNumber': 'SpcOrder',
            'Price': 25.0,
            'AllowZeroPrice': false
        },
        {
            'ItemID': 720253,
            'ItemName': 'Re-Stocking Fee',
            'ItemNumber': 'RestockFee',
            'Price': 67.23,
            'AllowZeroPrice': true
        },
        {
            'ItemID': 944390,
            'ItemName': 'Misc Item Fee',
            'ItemNumber': 'miscitem',
            'Price': 100.0,
            'AllowZeroPrice': true
        },
        {
            'ItemID': 945462,
            'ItemName': 'Insurance Rejection Payment',
            'ItemNumber': 'M745462',
            'Price': 0.0,
            'AllowZeroPrice': true
        },
        {
            'ItemID': 2862425,
            'ItemName': 'Lens+Frame Materials',
            'ItemNumber': 'M2862425',
            'Price': 0.0,
            'AllowZeroPrice': true
        },
        {
            'ItemID': 1127986,
            'ItemName': 'Insurance Co-Pay',
            'ItemNumber': 'M1127986',
            'Price': 0.0,
            'AllowZeroPrice': true
        },
        {
            'ItemID': 1155866,
            'ItemName': 'Cust Credit To Family Member',
            'ItemNumber': '8',
            'Price': 0.0,
            'AllowZeroPrice': true
        },
        {
            'ItemID': 1171889,
            'ItemName': 'Groupon',
            'ItemNumber': 'M1171889',
            'Price': 500.0,
            'AllowZeroPrice': true
        },
        {
            'ItemID': 1327542,
            'ItemName': 'Premium Polycabonate',
            'ItemNumber': 'PRMPOLY',
            'Price': 0.0,
            'AllowZeroPrice': true
        },
        {
            'ItemID': 1342172,
            'ItemName': 'Safety Disp. Fee',
            'ItemNumber': '55144',
            'Price': 0.0,
            'AllowZeroPrice': true
        },
        {
            'ItemID': 1379338,
            'ItemName': 'Sv Dispensing Fee',
            'ItemNumber': '92340',
            'Price': 25.0,
            'AllowZeroPrice': true
        },
        {
            'ItemID': 1379339,
            'ItemName': 'Bf Dispensing Fee',
            'ItemNumber': '92341',
            'Price': 0.0,
            'AllowZeroPrice': true
        },
        {
            'ItemID': 1415952,
            'ItemName': 'Plano Lens',
            'ItemNumber': 'Plano Lens',
            'Price': 50.0,
            'AllowZeroPrice': false
        },
        {
            'ItemID': 1502207,
            'ItemName': 'dry eye',
            'ItemNumber': 'M1502207',
            'Price': 0.0,
            'AllowZeroPrice': true
        },
        {
            'ItemID': 1502212,
            'ItemName': 'Lens Material Fee',
            'ItemNumber': 'LMF',
            'Price': 120.0,
            'AllowZeroPrice': false
        },
        {
            'ItemID': 1512274,
            'ItemName': 'Patients Own Frame',
            'ItemNumber': 'POF',
            'Price': 0.0,
            'AllowZeroPrice': true
        },
        {
            'ItemID': 1512317,
            'ItemName': 'ADD Over 3',
            'ItemNumber': 'ADD3',
            'Price': 20.0,
            'AllowZeroPrice': false
        },
        {
            'ItemID': 1512318,
            'ItemName': 'ADD Over 4',
            'ItemNumber': 'ADD4',
            'Price': 25.0,
            'AllowZeroPrice': false
        },
        {
            'ItemID': 1519452,
            'ItemName': 'Study/Meeting/Survey',
            'ItemNumber': 'M1519452',
            'Price': 0.0,
            'AllowZeroPrice': true
        },
        {
            'ItemID': 1519564,
            'ItemName': 'Previous Balance',
            'ItemNumber': 'M1519564',
            'Price': 0.0,
            'AllowZeroPrice': true
        },
        {
            'ItemID': 1521610,
            'ItemName': 'Patient Over Payment',
            'ItemNumber': 'Patient Over Pay',
            'Price': 0.0,
            'AllowZeroPrice': true
        },
        {
            'ItemID': 1522151,
            'ItemName': 'Mf Dispensing Fee',
            'ItemNumber': '92342',
            'Price': 25.0,
            'AllowZeroPrice': true
        },
        {
            'ItemID': 1537825,
            'ItemName': 'Lab Fee',
            'ItemNumber': 'LabFee',
            'Price': 10.0,
            'AllowZeroPrice': true
        },
        {
            'ItemID': 1542568,
            'ItemName': 'Dispensing Fee',
            'ItemNumber': 'DF',
            'Price': 10.0,
            'AllowZeroPrice': true
        },
        {
            'ItemID': 1553089,
            'ItemName': 'High Diopter',
            'ItemNumber': 'M1553089',
            'Price': 0.0,
            'AllowZeroPrice': true
        },
        {
            'ItemID': 1588463,
            'ItemName': 'Oversize >/= 59',
            'ItemNumber': 'OVERSIZE',
            'Price': 10.0,
            'AllowZeroPrice': false
        },
        {
            'ItemID': 1588464,
            'ItemName': 'PRISM (Under 5D) Per D\'',
            'ItemNumber': 'M1588464',
            'Price': 0.0,
            'AllowZeroPrice': true
        },
        {
            'ItemID': 1588465,
            'ItemName': 'PRISM (Over 5D) Per D\'',
            'ItemNumber': 'M1588465',
            'Price': 0.0,
            'AllowZeroPrice': true
        },
        {
            'ItemID': 1589648,
            'ItemName': 'Records Fee',
            'ItemNumber': 'M1589648',
            'Price': 20.0,
            'AllowZeroPrice': false
        },
        {
            'ItemID': 1589650,
            'ItemName': 'Copay Fee',
            'ItemNumber': 'M1589650',
            'Price': 50.0,
            'AllowZeroPrice': true
        },
        {
            'ItemID': 1606620,
            'ItemName': 'Insurance Balance Transferred',
            'ItemNumber': 'M1606620',
            'Price': 0.0,
            'AllowZeroPrice': true
        },
        {
            'ItemID': 1607073,
            'ItemName': 'Test 20/20 Discount Plan Enrollment',
            'ItemNumber': 'M1607073',
            'Price': 0.0,
            'AllowZeroPrice': true
        },
        {
            'ItemID': 1607074,
            'ItemName': 'oldsys',
            'ItemNumber': 'M1607074',
            'Price': 0.0,
            'AllowZeroPrice': true
        },
        {
            'ItemID': 1607142,
            'ItemName': '3m Safety Non-Benefit Dispensing Fee',
            'ItemNumber': 'M1607142',
            'Price': 150.0,
            'AllowZeroPrice': false
        },
        {
            'ItemID': 1607316,
            'ItemName': 'Menicon Enzyme Treatment',
            'ItemNumber': 'M1607316',
            'Price': 0.0,
            'AllowZeroPrice': true
        },
        {
            'ItemID': 1607335,
            'ItemName': 'Vision Therapy Sessions (92065- 45 Min)',
            'ItemNumber': 'S1607335',
            'Price': 45.0,
            'AllowZeroPrice': false
        },
        {
            'ItemID': 1607336,
            'ItemName': 'Vision Therapy Sessions 30',
            'ItemNumber': 'S1607336',
            'Price': 30.0,
            'AllowZeroPrice': false
        },
        {
            'ItemID': 1607340,
            'ItemName': 'VT Binocular Eval',
            'ItemNumber': 'S1607340',
            'Price': 15.0,
            'AllowZeroPrice': true
        },
        {
            'ItemID': 1607447,
            'ItemName': 'Custom Fee',
            'ItemNumber': 'Custom Fee',
            'Price': 0.0,
            'AllowZeroPrice': true
        },
        {
            'ItemID': 1607694,
            'ItemName': 'Co-Management Fees',
            'ItemNumber': 'CMF',
            'Price': 0.0,
            'AllowZeroPrice': true
        },
        {
            'ItemID': 1609210,
            'ItemName': 'Temple-Left',
            'ItemNumber': 'TempleL',
            'Price': 15.0,
            'AllowZeroPrice': false
        },
        {
            'ItemID': 1609211,
            'ItemName': 'Temple-Pair',
            'ItemNumber': 'TempleP',
            'Price': 0.0,
            'AllowZeroPrice': true
        },
        {
            'ItemID': 1611502,
            'ItemName': 'Collection Fee',
            'ItemNumber': 'CollFee',
            'Price': 0.0,
            'AllowZeroPrice': true
        },
        {
            'ItemID': 1611503,
            'ItemName': 'Return Check Fee',
            'ItemNumber': 'RCFee',
            'Price': 50.0,
            'AllowZeroPrice': true
        },
        {
            'ItemID': 1611504,
            'ItemName': 'Return Check Original Amount',
            'ItemNumber': 'RCOA',
            'Price': 0.0,
            'AllowZeroPrice': true
        },
        {
            'ItemID': 1614546,
            'ItemName': '1.5 And Below Center',
            'ItemNumber': 'M1614546',
            'Price': 0.0,
            'AllowZeroPrice': true
        },
        {
            'ItemID': 1615120,
            'ItemName': ' jb Chronos',
            'ItemNumber': 'Allow',
            'Price': 30.0,
            'AllowZeroPrice': true
        },
        {
            'ItemID': 1616680,
            'ItemName': 'Press On Prism',
            'ItemNumber': 'POPrism',
            'Price': 0.0,
            'AllowZeroPrice': true
        },
        {
            'ItemID': 1616757,
            'ItemName': 'Safety Fee',
            'ItemNumber': 'Safety Fee',
            'Price': 0.0,
            'AllowZeroPrice': true
        },
        {
            'ItemID': 1625030,
            'ItemName': 'Premium Warranty',
            'ItemNumber': 'Premium Warranty',
            'Price': 0.0,
            'AllowZeroPrice': true
        },
        {
            'ItemID': 1625809,
            'ItemName': 'Returned Check',
            'ItemNumber': 'Returned Check',
            'Price': 0.0,
            'AllowZeroPrice': true
        },
        {
            'ItemID': 1625932,
            'ItemName': 'Patient Payment On Future Order',
            'ItemNumber': 'Patient Payment ',
            'Price': 0.0,
            'AllowZeroPrice': true
        },
        {
            'ItemID': 1631667,
            'ItemName': 'Complete DMV Form',
            'ItemNumber': 'M1631667',
            'Price': 0.0,
            'AllowZeroPrice': true
        },
        {
            'ItemID': 1632461,
            'ItemName': 'Insurance amount ',
            'ItemNumber': 'M1632461',
            'Price': 0.0,
            'AllowZeroPrice': true
        },
        {
            'ItemID': 1632480,
            'ItemName': 'Late Fee',
            'ItemNumber': 'M1632480',
            'Price': 25.0,
            'AllowZeroPrice': false
        },
        {
            'ItemID': 1635523,
            'ItemName': 'Gift Card',
            'ItemNumber': 'M1635523',
            'Price': 20.0,
            'AllowZeroPrice': true
        },
        {
            'ItemID': 1635570,
            'ItemName': 'Old POS Balance',
            'ItemNumber': 'M1635570',
            'Price': 0.0,
            'AllowZeroPrice': true
        },
        {
            'ItemID': 1639058,
            'ItemName': 'Replacement Lens',
            'ItemNumber': 'M1639058',
            'Price': 120.0,
            'AllowZeroPrice': false
        },
        {
            'ItemID': 1639059,
            'ItemName': 'Misc Product',
            'ItemNumber': 'M1639059',
            'Price': 0.0,
            'AllowZeroPrice': true
        },
        {
            'ItemID': 1639786,
            'ItemName': 'Eyewear Package',
            'ItemNumber': 'M1639786',
            'Price': 0.0,
            'AllowZeroPrice': true
        },
        {
            'ItemID': 1642138,
            'ItemName': 'Outstanding(Previous) Balance',
            'ItemNumber': 'M1642138',
            'Price': 0.0,
            'AllowZeroPrice': true
        },
        {
            'ItemID': 1655474,
            'ItemName': 'CL Fee',
            'ItemNumber': 'M1655474',
            'Price': 0.0,
            'AllowZeroPrice': true
        },
        {
            'ItemID': 1874048,
            'ItemName': 'Missed Appointment Fee',
            'ItemNumber': 'M1874048',
            'Price': 20.0,
            'AllowZeroPrice': false
        },
        {
            'ItemID': 1878652,
            'ItemName': 'Remade Order',
            'ItemNumber': 'M1878652',
            'Price': 0.0,
            'AllowZeroPrice': true
        },
        {
            'ItemID': 2014109,
            'ItemName': 'Transitions',
            'ItemNumber': 'M2014109',
            'Price': 80.0,
            'AllowZeroPrice': false
        },
        {
            'ItemID': 2014113,
            'ItemName': 'Scratch Warranty',
            'ItemNumber': 'M2014113',
            'Price': 0.0,
            'AllowZeroPrice': true
        },
        {
            'ItemID': 2018763,
            'ItemName': 'Misc Frame Order',
            'ItemNumber': 'M2018763',
            'Price': 0.0,
            'AllowZeroPrice': true
        },
        {
            'ItemID': 2032851,
            'ItemName': 'Remove Patient Credit',
            'ItemNumber': 'M2032851',
            'Price': 0.0,
            'AllowZeroPrice': true
        },
        {
            'ItemID': 2141331,
            'ItemName': 'AR Coating Redo',
            'ItemNumber': 'M2141331',
            'Price': 0.0,
            'AllowZeroPrice': true
        },
        {
            'ItemID': 2141358,
            'ItemName': 'Tax',
            'ItemNumber': 'M2141358',
            'Price': 0.0,
            'AllowZeroPrice': true
        },
        {
            'ItemID': 2219519,
            'ItemName': 'hi I\'m testing this fee',
            'ItemNumber': 'M2219519',
            'Price': 480.82,
            'AllowZeroPrice': true
        },
        {
            'ItemID': 2622193,
            'ItemName': 'IOL Upgrade Fee - Presbyopia-Correcting IOL',
            'ItemNumber': 'M2622193',
            'Price': 0.0,
            'AllowZeroPrice': true
        },
        {
            'ItemID': 2943935,
            'ItemName': 'IOL Upgrade Fee - Astigmatism-Correcting (Toric) IOL',
            'ItemNumber': 'M2943935',
            'Price': 0.0,
            'AllowZeroPrice': true
        }
    ];
}