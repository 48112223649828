import { ICatalogItem } from '../interfaces/catalog-item';

export function PlansMockData(): ICatalogItem[] {
    return [
        {
            'ItemID': 2500099,
            'ItemName': 'New Plan',
            'ItemNumber': 'M2500099',
            'Price': 50.0,
            'AllowZeroPrice': true
        },
        {
            'ItemID': 201024,
            'ItemName': 'Old Plan',
            'ItemNumber': 'SpcOrder',
            'Price': 25.0,
            'AllowZeroPrice': false
        },
        {
            'ItemID': 72025434,
            'ItemName': 'Another Plan',
            'ItemNumber': 'RestockFee',
            'Price': 67.23,
            'AllowZeroPrice': true
        }
    ]
}