import { CommonModule } from '@angular/common';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';

import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';

import { AlertComponent, IAlert } from '@pm/core/alerts';
import { LoginService } from '../../services/login.service';

/**
 * Login dialog for temporary override
 */
@Component({
  selector: 'pm-temporary-override-dialog',
  standalone: true,
  imports: [
    AlertComponent,
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    TranslateModule
  ],
  templateUrl: './temporary-override.dialog.html',
  styleUrls: ['./temporary-override.dialog.scss'],
  providers: [LoginService]
})
export class TemporaryOverrideDialog {
  /** Alert options. */
  public alert: IAlert = {};
  /** Is waiting on API request? */
  public isProcessing = false;
  /** Alert message. */
  public message: string | null = null;

  /** Username input textbox. */
  @ViewChild('username') username!: ElementRef;

  /** Reactive form group. */
  public form = new FormGroup({
    username: new FormControl<string>('', Validators.required),
    password: new FormControl<string>('', Validators.required)
  });

  /**
   * Initializes instance of TemporaryOverrideDialog class.
   * @param dialogRef Reference to this dialog
   * @param pricingSvc Login service
   */
  constructor(
    private dialogRef: MatDialogRef<TemporaryOverrideDialog>,
    private loginSvc: LoginService
  ) { }

  /**
   * Handle the override.
   */
  async override(): Promise<void> {
    if (this.form.valid) {
      this.isProcessing = true;
      const { username, password } = this.form.controls
      const result = await this.loginSvc.processOverride(username.value as string, password.value as string)

      if (result.message?.message) {
        const { title, type, message } = result.message;
        this.alert = { title, type };
        this.message = message;
      } else {
        this.message = null;
      }

      if (result.isVerified) {
        this.dialogRef.close('override');
      } else {
        this.isProcessing = false;
        this.username.nativeElement.focus();
      }
    }
  }
}
