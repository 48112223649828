import { IBenefitReportDetail } from '../interfaces/benefit-report-detail';

export function BenefitReportDetailMockData(): IBenefitReportDetail {
    return {
        'BenefitReportType': 1,
        'CarrierCode': 'VSP',
        'EligibilityId': 6068395,
        'EligibilitySource': 2,
        'IsMedicalCoverage': false,
        'IsVsp': true,
        'PatientInternalId': 18772852,
        'ShowReportIcon': 'NonVerbalization'
    };
}