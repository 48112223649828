import { IPatientDocumentIntegration } from '../interfaces/patient-document-integration';

export class PatientDocumentIntegration {
    /**
     * Initialize instance of the PatientDocumentIntegration class.
     * @param data 
     */
    constructor(data: IPatientDocumentIntegration | null) {
        if (data) {
            this.patientDocumentIntegrationInternalId = data.PatientDocumentIntegrationInternalId;
            this.patientDocumentInternalId = data.PatientDocumentInternalId;
            this.patientInternalId = data.PatientInternalId;

            this.companyExternalId = data.CompanyExternalId;
            this.officeExternalId = data.OfficeExternalId;

            this.lastName = data.LastName;
            this.firstName = data.FirstName;
            this.dateOfBirth = data.DateOfBirth;
            this.phone = data.Phone;

            this.appointmentDate = data.AppointmentDate;
            this.examDate = data.ExamDate;

            this.domain = data.Domain;
            this.source = data.Source;
            this.currentStatus = data.CurrentStatus;
            this.currentStatusReason = data.CurrentStatusReason;
        } else {
            this.patientDocumentIntegrationInternalId = 0;
            this.patientDocumentInternalId = 0;
            this.patientInternalId = 0;

            this.companyExternalId = '';
            this.officeExternalId = '';

            this.lastName = '';
            this.firstName = '';
            this.dateOfBirth = null;
            this.phone = null;

            this.appointmentDate = null;
            this.examDate = null;

            this.domain = '';
            this.source = '';
            this.currentStatus = '';
            this.currentStatusReason = '';
        }

        this.nickName = null;
        this.service = null;
        this.resource = null;
    }

    /** Patient Document Integration Internal Id */
    patientDocumentIntegrationInternalId: number;
    /** Patient Document Internal Id */
    patientDocumentInternalId: number;
    /** Patient Document Internal Id */
    patientInternalId: number;
    /** Company External Id */
    companyExternalId: string;
    /** Office External Id */
    officeExternalId: string;
    /** Last Name */
    lastName: string;
    /** First Name */
    firstName: string;
    /** Date of Birth */
    dateOfBirth: Date | null;
    /** Phone */
    phone: string | null;
    /** Appointment Date */
    appointmentDate: Date | null;
    /** Exam Date */
    examDate: Date | null;
    /** Domain */
    domain: string;
    /** Source */
    source: string;
    /** Current Status */
    currentStatus: string;
    /** Current Status Reason */
    currentStatusReason: string;
    /** Nick Name */
    nickName: string | null;
    /** Service */
    service: string | null;
    /** Resource */
    resource: string | null;

    requireReview = false;
    
    isDuplicate = false;
}