import { IPhone } from '../interfaces/phone';

export const PHONES_MOCK_DATA: IPhone[] = [
    {
        PhoneId: 1,
        PhoneNumber: '202-918-2132',
        PhoneType: 304,
        IsPrimary: false
    },
    {
        PhoneId: 2,
        PhoneNumber: '505-934-8743',
        PhoneType: 305,
        IsPrimary: false
    },
    {
        PhoneId: 3,
        PhoneNumber: '505-240-7069',
        PhoneType: 306,
        IsPrimary: true
    },
    {
        PhoneId: 4,
        PhoneNumber: '472-271-2507',
        PhoneType: 307,
        IsPrimary: false
    }
];