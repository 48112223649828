<ng-template #template>
    <pm-alert data-ef="patient-review-alert" [options]="alert" *ngIf="patientCount > 0">
        {{ message | translate:{patientCount} }}
        <ng-template [ngIf]="isFeatureEnabled" [ngIfElse]="patients_review">
            <a pmRoute="Patient/ReviewPatientDuplicates">
                {{ 'ALERTS.PATIENTS_REVIEW.LINK' | translate }}</a>
        </ng-template>
        <ng-template #patients_review>
            <a href="#" (click)="review($event)">{{ 'ALERTS.PATIENTS_REVIEW.LINK' | translate }}</a>
        </ng-template>
    </pm-alert>
</ng-template>

<ng-content *ngTemplateOutlet="alert.isGrouped ? null : template"></ng-content>