import {Component, OnInit, Input} from '@angular/core';
import {IEligibilityReportGroup} from '../model/eligibility-report-group.interface';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'pm-app-report-group',
  templateUrl: './report-group.component.html',
  standalone: true,
  imports: [
    CommonModule
  ]
})
export class EligibilityReportGroupComponent implements OnInit {
  @Input()
  /** The model that will be used in the template */
  public model!: IEligibilityReportGroup | undefined;
  
  /** Getting our html from the model ready to be displayed in the template */
  ngOnInit() : void {
    if (this.model) {
      for (let i = 0; i < this.model.Lines.length; i ++) {
        for (let j = 0; j < this.model.Lines[i].Items.length; j ++) {
          if (this.model.Lines[i].Items[j].Value) this.model.Lines[i].Items[j].Value = this.decodeHtml(this.model.Lines[i].Items[j].Value as string);
          if (this.model.Lines[i].Items[j].Value) this.model.Lines[i].Items[j].Value = this.sanitizeHtml(this.model.Lines[i].Items[j].Value as string);
        }
      }
    }
  }

  /**
   * 
   * @param html 
   * @returns a string of html that is ready to be displayed in the template
   */
  decodeHtml(html: string) : string {
    const txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
  }

  /**
   * 
   * @param html 
   * @returns a sanitized string of html that can be used in the template
   */
  sanitizeHtml(html: string): string {
    let sanitizedHtml = html.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '');
    sanitizedHtml = sanitizedHtml.replace(/ on\w+="[^"]*"/gmi, '');
    return sanitizedHtml;
  }
}
