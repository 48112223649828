import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { LinkPipe } from '@pm/core';
import { AppConfigurationService, AppFeatureService } from '@pm/core/configuration';
import { OperationsService } from '@pm/core/operations';

/**
 * Component for the quicklinks section of the footer.
 */
@Component({
  selector: 'pm-footer-quicklinks',
  templateUrl: './footer-quicklinks.component.html',
  styleUrls: ['./footer-quicklinks.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    LinkPipe,
    TranslateModule
  ]
})
export class FooterQuicklinksComponent {
  /** Is admin link enabled? */
  public isAdminLinkDisabled$ = this.operations.isAdminLinkDisabled$;
  /** Is the current page part of claims mangement? */
  public isClaimsManagement = this.config.isClaimsManagement;
  /** Show admin link? */
  public showAdminLink = !this.feature.isShowFeatureConfigurationInALAdminEnabled;

  /**
   * Initializes instance of the FooterQuicklinksComponent class.
   * @param config Application configuration service
   * @param feature Application feature service
   * @param operations Operations service
   */
  constructor(
    private config: AppConfigurationService,
    private feature: AppFeatureService,
    private operations: OperationsService
  ) { }

  /**
   * Navigate to admin dashboard.
   * @param event DOM event.
   */
  goToAdmin(event: Event): void {
    event.preventDefault();
    this.operations.goToAdmin();
  }
}
