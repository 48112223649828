import { FRAME_IMAGE_NOT_AVAILABLE } from '../frame-image-not-available';
import { IFrameDetail } from '../interfaces/frame-detail';
import { Frame } from './frame';

/**
 * Frame detail class.
 */
export class FrameDetail extends Frame {
    /** A measurement. */
    public aMeasure?: number | null;
    /** B measurement. */
    public bMeasure?: number | null;
    /** Category */
    public category: string | null;
    /** Color family. */
    public colorFamily: string | null;
    /** DB measurement. */
    public dbMeasure?: number | null;
    /** Edge type. */
    public edgeType: string | null;
    /** ED measurement. */
    public edMeasure?: number | null;
    /** Gender. */
    public gender: string | null;
    /** Image. */
    public image: string;
    /** Frame note. */
    public note: string | null;
    /** Manufacturer. */
    public manufacturer: string | null;
    /** Shape. */
    public shape: string | null;
    /** Item status. */
    public status: string | null;
    /** Vendor. */
    public vendor: string | null;

    /**
     * Initializes instance of the Frame class.
     * @param frame Frame data.
     */
    constructor(frame: IFrameDetail) {
        super(frame);
        this.name = frame.CompanyItemName;

        this.aMeasure = frame.AMeasure;
        this.bMeasure = frame.BMeasure;
        this.category = frame.Category;
        this.colorFamily = frame.ColorFamily;
        this.dbMeasure = frame.DBMeasure;
        this.edgeType = frame.EdgeType;
        this.edMeasure = frame.EDMeasure;
        this.gender = frame.Gender;
        this.image = frame.Image ? `data:image/png;base64,${frame.Image}` : FRAME_IMAGE_NOT_AVAILABLE;
        this.note = frame.FrameNote;
        this.manufacturer = frame.Manufacturer;
        this.shape = frame.Shape;
        this.status = frame.Status;
        this.vendor = frame.Vendor;
    }
}
