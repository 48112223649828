import { ViewContainerRef } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';

/**
 * Class for predefined dialog configurations.
 */
export class DialogHelper {
    /**
     * Initializes instance of the DialogHelper class.
     * @param viewContainerRef Reference to the container.
     */
    constructor(private viewContainerRef?: ViewContainerRef) { }

    /**
     * Large dialog configuration.
     * @param data Data to inject into dialog
     * @returns Material dialog configuration
     */
    large<T>(data?: T, disableClose = true): MatDialogConfig {
        return { panelClass: 'pm-dialog-lg', data, disableClose, viewContainerRef: this.viewContainerRef };
    }

    /**
     * Medium dialog configuration.
     * @param data Data to inject into dialog
     * @returns Material dialog configuration
     */
    medium<T>(data?: T, disableClose = true): MatDialogConfig {
        return { panelClass: 'pm-dialog-md', data, disableClose, viewContainerRef: this.viewContainerRef };
    }

    /**
     * Small dialog configuration.
     * @param data Data to inject into dialog
     * @returns Material dialog configuration
     */
    small<T>(data?: T, disableClose = true): MatDialogConfig {
        return { panelClass: 'pm-dialog-sm', data, disableClose, viewContainerRef: this.viewContainerRef };
    }

    /**
     * Wide dialog configuration.
     * @param data Data to inject into dialog
     * @returns Material dialog configuration
     */
    extraLarge<T>(data?: T, disableClose = true): MatDialogConfig {
        return { panelClass: 'pm-dialog-xl', data, disableClose, viewContainerRef: this.viewContainerRef };
    }
}
