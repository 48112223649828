import { CommonModule } from '@angular/common';
import { AfterContentChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostBinding, ViewChild } from '@angular/core';

import { MatCardModule } from '@angular/material/card';
import { TranslateModule } from '@ngx-translate/core';

import {
  FrameBoardManagementAlertComponent,
  FrameDataAvailabilityAlertComponent,
  ReviewPatientsAlertComponent,
  StoreOperationsAlertComponent
} from '@pm/shared/alerts';

/**
 * Notifications card.
 */
@Component({
  selector: 'pm-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    FrameBoardManagementAlertComponent,
    FrameDataAvailabilityAlertComponent,
    MatCardModule,
    ReviewPatientsAlertComponent,
    StoreOperationsAlertComponent,
    TranslateModule
  ]
})
export class NotificationsComponent implements AfterContentChecked {
  /** Is the component hidden? */
  @HostBinding('hidden') isHidden = true;

  /** Reference to material card content element. */
  @ViewChild('content') content!: ElementRef;

  /**
   * Initializes instance of NotificationsComponent class.
   * @param changeDetectorRef Change detector
   */
  constructor(
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  /**
   * AfterContentChecked lifecycle hook.
   * - Unhide the component if there are alerts.
   */
  ngAfterContentChecked(): void {
    const alertCount = this.content?.nativeElement.querySelectorAll('pm-alert').length || 0;
    this.isHidden = alertCount === 0;
    this.changeDetectorRef.detectChanges();
  }
}
