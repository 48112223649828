
import { Component, ViewChild, ComponentFactoryResolver, ApplicationRef, Injector, OnDestroy, AfterViewInit, Input, EventEmitter, Output, HostListener } from '@angular/core';
import { CdkPortal, DomPortalOutlet, PortalModule } from '@angular/cdk/portal';
import { AppConfigurationService } from '@pm/core/configuration';

/**
 * This components is used to display content inside an external
 * window. The stylesheet is not included, so a separate css file
 * may be required.
 */

@Component({
  selector: 'pm-window',
  templateUrl: './window.component.html',
  styleUrls: ['./window.component.scss'],
  standalone: true,
  imports: [PortalModule]
})
export class WindowComponent implements AfterViewInit, OnDestroy {
  /** Timer used to check if external window is closed. */
  private timer?: number;

  /** Window height. */
  @Input() height?: number;
  /** Window width. */
  @Input() width?: number;
  /** Window name */
  @Input({ required: true }) windowName!: string;

  /** Closed event emitter. */
  @Output() closed = new EventEmitter();
  /** Opened event emitter. */
  @Output() opened = new EventEmitter<Window>();

  /** Window before unload event handler. */
  @HostListener('window:beforeunload', ['$event'])
  closeWindow(): void {
    this.externalWindow?.close()
    window.clearInterval(this.timer);
  }

  /** CDK portal. */
  @ViewChild(CdkPortal) portal!: CdkPortal;

  /** Reference to external window. */
  private externalWindow: Window | null = null;

  /**
   * Initializes the WindowComponent class.
   * @param componentFactoryResolver Component factory resolver
   * @param applicationRef Reference to this application
   * @param injector Injector
   * @param config Application configuration service
   */
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private applicationRef: ApplicationRef,
    private injector: Injector,
    private config: AppConfigurationService) { }

  /**
   * AfterViewInit lifecycle hook.
   * - Open external window and attach content.
   */
  ngAfterViewInit(): void {
    if (this.width && this.height) {
      this.externalWindow = window.open('', this.windowName, `width=${this.width},height=${this.height}`);
    } else {
      this.externalWindow = window.open('', this.windowName);
    }
    this.externalWindow?.focus();

    this.timer = window.setInterval(() => {
      if (this.externalWindow?.closed) {
        window.clearInterval(this.timer);
        this.closed.emit();
      }
    }, 200);

    if (this.externalWindow) {
      const outlet = new DomPortalOutlet(
        this.externalWindow.document.body,
        this.componentFactoryResolver,
        this.applicationRef,
        this.injector
      );
      outlet.attach(this.portal);
      this.opened.emit(this.externalWindow);

    }
  }

  /**
   * OnDestroy lifecycle hook.
   */
  ngOnDestroy(): void {
    this.closeWindow();
  }
}