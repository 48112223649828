/**
 * Line type enum.
 */
export enum CatalogItemType {
    EYEGLASS_FRAME = 1,
    ACCESSORY = 2,
    CONTACT_LENS_STOCK = 3,
    EXAM = 6,
    PLAN = 7,
    EYEGLASS_LENS = 8,
    LENS_BASE_TYPE = 9,
    MATERIAL_ADDON = 10,
    STYLE_ADDON = 11,
    COLOR_COAT_ADDON = 12,
    TINT = 13,
    EDGING = 14,
    COATING = 15,
    MISC_EXTRA = 16,
    CONTACT_LENS = 17,
    GIFT_CARD = 20,
    SPECIAL_OPTION = 21,
    REPAIR = 25,
    SHIPPING = 26,
    MISC_FEE = 32
}