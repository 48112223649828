import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

import { TranslateModule } from '@ngx-translate/core';

import { AlertComponent, IAlert } from '@pm/core/alerts';
import { AppConfigurationService } from '@pm/core/configuration';
import { OperationsService } from '@pm/core/operations';

/**
 * Component for store operations alert message.
 */
@Component({
  selector: 'pm-store-operations-alert',
  templateUrl: './store-operations-alert.component.html',
  styleUrls: ['./store-operations-alert.component.scss'],
  standalone: true,
  imports: [
    AlertComponent,
    CommonModule,
    TranslateModule
  ]
})
export class StoreOperationsAlertComponent implements OnDestroy, OnInit {
  /** Daily close alert with default values. */
  public alert: IAlert = { type: 'Error' };
  /** Has day close role? */
  public hasDayCloseRole = this.config?.dailyRoles?.hasDayCloseRole ?? false;

  /** Array of subscriptions to observables. */
  private subscriptions: Subscription[] = [];

  /** Alert options. */
  @Input() set options(alert: IAlert) {
    Object.assign(this.alert, alert);
  }

  /** Template used to render content outside of selector. */
  @ViewChild('template') template!: TemplateRef<never>;

  /**
   * Initializes instance of the StoreOperationsAlertComponent class.
   * @param changeDetectorRef Change detector
   * @param config Application configuration service
   * @param operations Operaions service
   */
  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private config: AppConfigurationService,
    private operations: OperationsService,
  ) { }

  /**
   * OnInit lifecycle hook.
   * -- Subscribe to daily close alert.
   */
  ngOnInit(): void {
    this.subscriptions.push(this.operations.storeOperationsAlert$.subscribe(alert => {
      Object.assign(this.alert, alert);
      this.alert.style = this.hasDayCloseRole ? 'Clickable' : 'Basic';
      this.changeDetectorRef.markForCheck();
      this.changeDetectorRef.detectChanges();
    }));
  }

  /**
   * OnDestroy lifecycle hook.
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  /**
   * Navigate to daily closing.
   */
  gotoDailyClosing(event: Event): void {
    event.preventDefault();
    if (this.alert.link) {
      window.location.href = this.config.getLinkPath(this.alert.link);
    }
  }
}
