import { IPatientAddress } from '../../interfaces/patient-pm';
import { Address } from './address';

export class PatientAddress {
    public patientId: number;
    public addressId: number;
    public address: Address;

    constructor(patientAddress: IPatientAddress) {
        this.patientId = patientAddress.PatientId;
        this.addressId = patientAddress.AddressId;
        this.address = new Address(patientAddress.Address);
    }
}