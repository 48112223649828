import { IAlert } from '@pm/core/alerts';

/**
 * Permissions override result.
 */
export class OverrideResult {
    /** Is override permission verified? */
    public isVerified = false;
    /** Alert message. */
    public message: IAlert | null;
    /** Override user id. */
    public overrideUserId: number | null;

    /**
     * Initialize instance of the OverrideResult class.
     * @param isVerified  Is override permission verified?
     * @param message  Alert message
     */
    constructor(isVerified: boolean, message: IAlert | null = null, userId: number | null = null) {
        this.isVerified = isVerified;
        this.message = message;
        this.overrideUserId = userId;
    }
}
