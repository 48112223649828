import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { AppConfigurationService } from '@pm/core/configuration';
import { FooterQuicklinksComponent } from '../footer-quicklinks/footer-quicklinks.component';

/**
 * Component for the standard footer.
 */
@Component({
  selector: 'pm-footer-compact',
  templateUrl: './footer-compact.component.html',
  styleUrls: ['./footer-compact.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FooterQuicklinksComponent,
    TranslateModule
  ]
})
export class FooterCompactComponent {
  /** Is navigation enabled? */
  public isNavigationEnabled = this.config.isNavigationEnabled;
  /** Application version. */
  public version = this.config.appVersion;
  /** Current year to display with the copyright. */
  public year = new Date().getFullYear();

  /**
   * Initializes instance of the FooterStandardComponent class.
   * @param config Application configuration service
   */
  constructor(
    private config: AppConfigurationService
  ) { }
}
