import { IPatientLocation } from '../interfaces/patient-location';

/**
 * Patient location.
 */
export class PatientLocation {
    /** Location id. */
    locationId: number;
    /** Location description. */
    description: string;
    /** External office id. */
    officeExternalId: string;
    /** Is location in use? */
    isLocationInUse: boolean;

    /**
     * Initialize instance of the PatientLocation class.
     * @param location patient location data.
     */
    constructor(location: IPatientLocation) {
        this.locationId = location.LocationId;
        this.description = location.Description;
        this.officeExternalId = location.OfficeNum;
        this.isLocationInUse = location.IsLocationInUse;
    }
}
