import { Pipe, PipeTransform } from '@angular/core';

import { AppConfigurationService } from '@pm/core/configuration';

/**
 * Pipe used for generating the full path to a link.
 */
@Pipe({
  name: 'link',
  standalone: true
})
export class LinkPipe implements PipeTransform {

  /**
   * Initializes an instance of the LinkPipe class.
   * @param config Application configuration service
   */
  constructor(private config: AppConfigurationService) { }

  /** 
   * Generate the full link path. 
   * @param relativePath Relative path for the link.
   */
  transform(relativePath: string): string {
    return this.config.getLinkPath(relativePath);
  }
}
