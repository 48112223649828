<div class="container-xxl d-flex flex-column mat-elevation-z4">
    <pm-header></pm-header>
    <div class="flex-fill position-relative">
        <router-outlet></router-outlet>
        <!-- loading spinner -->
        @if(ui.isProcessing()) {
        <div class="overlay processing-spinner">
            <mat-spinner></mat-spinner>
        </div>
        }
    </div>
    <pm-footer></pm-footer>
</div>