
import { IAccessory } from '../interfaces/accessory';
import { ICatalogResults } from '../interfaces/catalog-results';
import { AccessoriesMockData } from './accessories';

export function AccessoryResultsMockData(): ICatalogResults<IAccessory>  {
    return { 
        'recordsTotal': 33,
        'recordsFiltered': 33,
        'data': AccessoriesMockData().slice(0, 10)
    }
}