import { Injectable } from '@angular/core';
import { BehaviorSubject, filter, take } from 'rxjs';

import { AppConfigurationService } from '@pm/core/configuration';
import { AppContent } from '../classes/app-content';
import { HttpClient } from '@angular/common/http';
import { IAppContent } from '../interfaces/app-content';


/**
 * Service for providing external content.
 */
@Injectable({
  providedIn: 'root'
})
export class ContentService {
  /** Behavior subject for app content */
  private contentSubject = new BehaviorSubject<AppContent | null | undefined>(undefined);
  private controller = 'Home';

  /**
   * Initializes instance of the ContentService class.
   * @param config Application configuration service.
   */
  constructor(private config: AppConfigurationService, private http: HttpClient) {
    this.getAppContent();
  }

  get appContent(): Promise<AppContent | null> {
    return new Promise(resolve =>
      this.contentSubject.pipe(filter(value => value !== undefined), take(1)).subscribe(value => resolve(value as (AppContent | null))));
  }

  /**
   * Gets URLs and other details for app related content. 
   */
  private async getAppContent(): Promise<void> {

    let appContent = new AppContent({
      AppNameLong: 'Eyefinity Practice Management',
      AppNameShort: 'Eyefinity Practice Management',
      AppLogoSvgForDarkBackground: this.config.getAssetPath('images/eyefinity-logo-for-dark-bg.svg'),
      AppLogoSvgForLightBackground: this.config.getAssetPath('images/eyefinity-logo-for-light-bg.svg'),
      AppLogoWithPremierSvgForDarkBackground: this.config.getAssetPath('images/eyefinity-premier-logo-for-dark-bg.svg'),
      AppLogoWithPremierSvgForLightBackground: this.config.getAssetPath('images/eyefinity-premier-logo-for-light-bg.svg'),
      PremierLogoSvgForDarkBackground: this.config.getAssetPath('images/premier-logo-for-dark-bg.svg'),
      PremierLogoSvgForLightBackground: this.config.getAssetPath('images/premier-logo-for-light-bg.svg')
    });
    
    // Get the urls for the logos if they are configured
    let theme = null;
    if (!window.sessionStorage.getItem('theme')) {
      theme = await this.getTheme();
      window.sessionStorage.setItem('theme', JSON.stringify(theme));
    }

    theme = JSON.parse(window.sessionStorage.getItem('theme') as string) as ITheme;

    if(theme?.AppContent != null) {
      // file deepcode ignore AttrAccessOnNull: Already did a null check
      appContent = new AppContent(theme.AppContent);
    }
    
    this.contentSubject.next(appContent);
  }

  async getTheme() : Promise<ITheme | null> {
    return new Promise<ITheme | null>(resolve => {
            const url = `${this.controller}/GetTheme?companyId=${this.config.companyExternalId}&officeNumber=${this.config.officeExternalId}`;

            this.http.get<ITheme | null>(url)
            .subscribe({
              next: data => {
                resolve(data);
              },
              error: () => {
                resolve(null);
              }
            })
        });
  }
}

/** 
 * Interface for theme including image URLs
 */
export interface ITheme {
  /** Contains app content like names and image URLs **/
  AppContent: IAppContent;
}