<div class="d-flex gap-3" [ngClass]="cssClass">
    <!-- Icon -->
    <div [ngSwitch]="options.type">
        <mat-icon *ngSwitchCase="'Error'" aria-label="Error" class="d-block">error</mat-icon>
        <mat-icon *ngSwitchCase="'Success'" aria-label="Success" class="d-block">check</mat-icon>
        <mat-icon *ngSwitchCase="'Warning'" aria-label="Warning" class="d-block">warning</mat-icon>
        <mat-icon *ngSwitchDefault aria-label="Info" class="d-block">info</mat-icon>
    </div>

    <div class="justify-content-center d-flex flex-column w-100">

        <!-- Title -->
        <div class="fw-bold" *ngIf="title?.length || options.title?.length">
            {{ title?.length ? title : options.title }}
        </div>

        <!-- Message body from options -->
        <div *ngIf="options.message; else content">{{ options.message }}</div>

        <!-- Message body from content -->
        <ng-template #content>
            <div><ng-content></ng-content></div>
        </ng-template>

    </div>
</div>