import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { ContentService } from '@pm/core/content';
import { BLANK_IMAGE } from '@pm/layout';

/**
 * Component for the premier logo.
 */
@Component({
  selector: 'pm-premier-logo',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule
  ],
  templateUrl: './premier-logo.component.html',
  styleUrls: ['./premier-logo.component.scss']
})
export class PremierLogoComponent implements OnInit {
  /** Image source for logo. */
  public imageSrc = BLANK_IMAGE;

  /** Is the logo on a dark background? */
  @Input() isOnDarkBackground = false;

  /** Event fired after image is loaded. */
  @Output() loaded = new EventEmitter();

  /**
   * Initializes an instance of the LogoComponent class.
   * @param config Application configuration service
   * @param content External content service
   * @param translate Translation service
   */
  constructor(private content: ContentService) { }

  /**
   * OnInit lifecycle hook.
   */
  ngOnInit(): void {
    this.setImageSrc();
  }

  /**
   * Set image source.
   */
  async setImageSrc(): Promise<void> {
    const content = await this.content.appContent;
    if (content) {
      this.imageSrc = this.isOnDarkBackground ? content.premierLogoSvgForDarkBackground : content.premierLogoSvgForLightBackground;
    }
  }
}
