import { Component, ElementRef, Input, ViewChild, ViewContainerRef } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatIconModule } from '@angular/material/icon';

import { AppConfigurationService } from '@pm/core/configuration';
import { WindowComponent } from '@pm/core';
import { EligibilityComponent } from '../eligibility/eligibility.component';
import { PatientStateService } from '../services/patient-state.service';
import { MatButtonModule } from '@angular/material/button';
import { filter, take } from 'rxjs';

/**
 * Angular element used to lauch the eligibility report.
 */
@Component({
    selector: 'pm-eligibility-report-launcher',
    templateUrl: './eligibility-report-launcher.component.html',
    styleUrls: ['./eligibility-report-launcher.component.scss'],
    standalone: true,
    providers: [
      PatientStateService
    ],
    imports: [
      EligibilityComponent,
      WindowComponent,
      MatIconModule,
      CommonModule,
      MatButtonModule
    ]
})

export class EligibilityReportLauncherComponent {
  /** Show report? */
  public showReport = false;
  public externalWindow: Window | null = null;

  /** Container to lazy load report component. */
  @ViewChild('lazyContainer', { read: ViewContainerRef }) container!: ViewContainerRef;
  @ViewChild('eligibilityReport') eligibilityReport!: ElementRef;
  
  @Input() set dataparam(value: string) {
    const params = JSON.parse(value) as {eligibilityId: number, patientId: number};
    this.stateService.setCurrentPatient(params.eligibilityId, params.patientId);
  }
  /**
   * Initializes instance of the EligibilityReportLauncherComponent class.
   * @param componentFactoryResolver Component factory resolver
   * @param config Application configuration service
   */
  constructor(
    private config: AppConfigurationService,
    private stateService: PatientStateService
  ) 
  {}

  /**
   * Add stylesheet and lazy load component.
   * @param externalWindow Reference to external window
   */
  async loadReport(externalWindow: Window): Promise<void> {
    const styleSheetElement = document.createElement('link',);
    styleSheetElement.rel = 'stylesheet';
    styleSheetElement.href = this.config.getAssetPath('css/reports.css');
    externalWindow?.document.head.appendChild(styleSheetElement);
    this.stateService.setExternalWindow(externalWindow);
    this.stateService.isPatientSet = true;
  }

  toggleReport(): void {
    this.showReport = true;
    this.stateService.externalWindow$.pipe(filter((value) => value !== null),take(1)).subscribe(value => { value?.focus() });
  }

  closeReport(): void {
    this.showReport = false;
    this.stateService.setExternalWindow(null);
  }
}
